import React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import {useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';

import clsx from 'clsx'

import { useQuery } from '@apollo/client';
import { userMeBasic } from 'app/apollo/user/queries';

import { Avatar } from 'components'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1.15em 0 0.8em 0',
        height: '5.5em',
        color: '#F2F2F2',
        fontSize: 12,
        '& span' : {
            opacity: 0.8
        },
        '& div' : {
            opacity: 0.8
        },
        '&:hover' : {
            filter: 'grayscale(100%)'
        },
        cursor: 'pointer'
    },
    containerActive: {
        filter: 'hue-rotate(45deg)',
        // '& span' : {
        //     color: 'white',
        //     opacity: 1,
        // },
        // '& div' : {
        //     color: 'white',
        //     opacity: 1,
        //     fontWeight: 700
        // }
    },
    header                   : {
        position: 'relative',
        height         : 72,
        minHeight      : 72,
        display        : 'flex',
        // background     : 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.dark1 + ' 100%)',
        color          : theme.palette.primary.contrastTextBlackLess,
        backgroundSize : 'cover',
        // backgroundColor: theme.palette.primary.dark,
        background: 'white',
        alignItems: 'center'
    },
}))

export default function FusePageSimpleHeader(props)
{
    const classes = useStyles(props);
    const location = useLocation()
    
    const mainThemeDark = useSelector(({fuse}) => fuse.settings.mainThemeDark);
    let history = useHistory();

    const { data } = useQuery(userMeBasic);

    let user = data && data.me;

    let isActive = location.pathname.indexOf('/profile/') > -1

    return (
        <div className={classes.header}>
            <img src="assets/images/logos/logo_dark.svg" height="40" alt="logo"
                style={{ maxWidth: '150px', marginLeft: '1.5em', cursor: 'pointer' }}
                onClick={() => {
                    history.push(`/`)
                }} />
            
            {props.header && (
                <ThemeProvider theme={mainThemeDark}>
                    {props.header}
                </ThemeProvider>
            )}
            
            <div className={clsx(classes.container, isActive && classes.containerActive)} style={{ padding: "0 1.105em", height: 'auto' }}
                onClick={() => {
                    if (user.id) {
                        history.push(`/profile/${user.id}`)
                    }
                }}>
                <Avatar>
                    {user && (user.firstName + ' ' + user.lastName)}
                </Avatar>
            </div>
        </div>
    );
}
