import React, { useContext } from 'react';
import { DefaultLabelModel } from './DefaultLabelModel';
import styled from '@emotion/styled';
import AppContext from 'app/AppContext';
import { useTheme } from '@material-ui/styles';

export interface DefaultLabelWidgetProps {
	model: DefaultLabelModel;
}

export const Label = styled.div<{ isHover?: boolean; isHoverOn?: Boolean, background?: string }>`
		background: ${(p) => (p.background || "rgba(0, 0, 0, 0.8)")};
		opacity: ${(p) => (p.isHoverOn && !p.isHover ? 0.3 : 1)};
		border-radius: 5px;
		color: white;
		font-size: 12px;
		padding: 4px 8px;
		font-family: sans-serif;
		user-select: none;
`;

export function DefaultLabelWidget(props) {
	const { dispatch } = useContext(AppContext);
    const theme = useTheme();

	const options = props.model.getOptions()
	// const questionHubId = options.questionHub && options.questionHub.id
	const isCTA = options.color === "cta"

	if(!options.label || options.label.length === 0) {
		return null
	}

	return <Label
		background={isCTA && theme["palette"].secondary.main}
		// height: isCTA && "1.2em", fontSize: isCTA && '1.2em', 
		style={{cursor: isCTA ? "pointer" : undefined}}
		onClick={() => {
			debugger
			if(isCTA) {
				dispatch({ type: 'SHOW_QUESTION_COMPOSE'})
			}
		}}
	// isHover={questionHubId != null && state.diagramHoverId == questionHubId}
	// isHoverOn={state.diagramHoverId}
	>
		{options.label}
	</Label>
}
