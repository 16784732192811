import gql from 'graphql-tag'


export const labels = gql`
  query labels($id: ID, $type: String) {
    labelQ {
      labels(id: $id, type: $type) {
        id
        title
        description
        color
      }
    }
  }
`
  
export const companyDialogLabelsSelected = gql`
  query companyDialogLabelsSelected($id: ID) {
    labelQ {
      companyDialogLabelsSelected(id: $id) {
        id
        title
        description
        color
      }
    }
  }
`

export const companyDialogLabels = gql`
  query companyDialogLabels($id: ID) {
    labelQ {
      companyDialogLabels(id: $id) {
        id
        title
        description
        color
      }
    }
  }
`

export const labelsExtended = gql`
  query labelsExtended {
    labelQ {
      labels {
        id
        title
        description
        color
        questionCount
        creator {
          id
          firstName
          lastName
        }
        dateCreated
      }
    }
  }
`

export const labelBasic = gql`
  query labelBasic($id: ID!){
    labelQ {
      label(id:$id){
        id
        title
        description
        color
      }
    }
  }
  `

export const label = gql`
  query label($id: ID!){
    labelQ {
      label(id:$id){
        id
        title
        description
        color
        questions {
          id
          title
          dataType
          dateCreated
          answers {
            id
            companyDialog {
              id
              title
            }
            dateCreated
            valueText
            valueNumber
            valueNumberFrom
            valueNumberTo
            valueYesNo
            valueDecimal
            valueDate
            valueOptions {
              id
              title
            }
            scribble {
              id
              content
              imageContent
            }
          }
        }
        creator {
          id
          firstName
          lastName
        }
        dateCreated
      }
    }
  }
`