import React, {useRef, useState} from 'react';
import {Divider, Icon, IconButton, Input, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from '@material-ui/core';
import {amber} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/styles';
import * as UserActions from 'app/auth/store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {FuseAnimateGroup} from '@fuse';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import { UPDATE_SETTINGS } from '../../../app/apollo/settings/mutations'

const useStyles = makeStyles({
    root   : {
        '&.horizontal': {},
        '&.vertical'  : {
            flexDirection: 'column'
        }
    },
    item   : {
        textDecoration: 'none!important',
        color         : 'inherit'
    },
    addIcon: {
        color: amber[600]
    }
});

function FuseShortcuts(props)
{
    const dispatch = useDispatch();
    const shortcuts = useSelector(({auth}) => auth.user.data.shortcuts);
    // const [state, dispatch] = useReducer(user, initialState);
    const classes = useStyles(props);
    const searchInputRef = useRef(null);
    const [addMenu, setAddMenu] = useState(null);
    const [searchText, setSearchText] = useState('');
    // const [searchResults, setSearchResults] = useState(null);
    // const [navigation, setNavigation] = useState(null);

    // const shortcutItems = shortcuts ? shortcuts.map(id => FuseUtils.findById(navigationData, id)) : [];
    const shortcutItems = shortcuts ? shortcuts.map(short => short) : [];
    debugger
    // const { data: contactsData } = useQuery(contactsLight)
    // const { data: questionsData } = useQuery(questionsLight)
    // const { data: playbooksData } = useQuery(playbooks)
    // const { data: companyDialogsData } = useQuery(companyDialogs)

    const [updateSettings] = useMutation(UPDATE_SETTINGS)

    debugger

    // const { data: userData, error, loading } = useQuery(userMe);

//     useEffect(
//         () => {
//             if(userData && userData.me && !dataLoaded && userData.me.settings){
//                 const userSettings = userData.me.settings.find( setting => setting.key === "menu_shortcuts")
//                 if(userSettings && userSettings.value) {
//                     const valueArray = JSON.parse(userSettings.value)
//                     const settingsIds = valueArray && valueArray.map( val => val.id)
    
//                     // const settingsIds = userSettings && userSettings.value.split(',')
//                     const shortcutsData =[]
    
//                     settingsIds && settingsIds.map(uS => {
//                         contactsData && contactsData.contacts && contactsData.contacts.find( cD => cD.id === uS && shortcutsData.push(cD))
//                         questionsData && questionsData.questions && questionsData.questions.find( cD => cD.id === uS && shortcutsData.push(cD))
//                         playbooksData && playbooksData.playbooks && playbooksData.playbooks.find( cD => cD.id === uS && shortcutsData.push(cD))
//                         companyDialogsData && companyDialogsData.companyDialogs && companyDialogsData.companyDialogs.find( cD => cD.id === uS && shortcutsData.push(cD))
//                     })
                    
//                     if(
//                         questionsData && questionsData.questions &&
//                             playbooksData && playbooksData.playbooks &&
//                             companyDialogsData && companyDialogsData.companyDialogs &&
//                             contactsData && contactsData.contacts 
//                      ){
//                         dispatch({type:"[USER] SET DATA", payload: {
//                             role: [],//guest
//                             data: {
//                                 'displayName': 'Thomas Neirynck',
//                                 'photoURL'   : 'assets/images/avatars/Thomas.jpg',
//                                 'email'      : 'thomas@playbook.eu',
//                                 shortcuts    : shortcutsData
//                             }
//                         }})
            
//                         setDataLoaded(true)
//                     }
//                 }
//             }
//         }, [userData, contactsData, questionsData, playbooksData , companyDialogsData]
//     )

    //  useEffect(() => {
    //     function flattenNavigation()
    //     {

    //         // setNavigation(FuseUtils.getFlatNavigation(navigationData));

    //         const navigationArray = (
    //             questionsData && questionsData.questions &&
    //             playbooksData && playbooksData.playbooks &&
    //             companyDialogsData && companyDialogsData.companyDialogs &&
    //             contactsData && contactsData.contacts
    //         ) ? questionsData.questions.concat(playbooksData.playbooks, companyDialogsData.companyDialogs, contactsData.contacts) : []

    //         navigationArray && setNavigation(navigationArray)
    //     }

    //     flattenNavigation();
    // }, [ questionsData, playbooksData, companyDialogsData, contactsData ]);


    function addMenuClick(event)
    {
        setAddMenu(event.currentTarget);
    }

    function addMenuClose()
    {
        setAddMenu(null);
    }

    function search(ev)
    {
        const searchText = ev.target.value;
        setSearchText(searchText);

        // if ( searchText.length !== 0 && navigation )
        // {
        //     setSearchResults(navigation.filter(item => item.title ? item.title.toLowerCase().includes(searchText.toLowerCase()) : item.firstName && item.firstName.toLowerCase().includes(searchText.toLowerCase()) ));
        //     return;
        // }
        // setSearchResults(null);
    }

    async function toggleInShortcuts(item)
    {
        let newShortcuts = [...shortcuts];
        newShortcuts = newShortcuts.includes(item) ? newShortcuts.filter(_id => item.id !== _id.id) : [...newShortcuts, item];
        // await setDataLoaded(true)
        dispatch(UserActions.updateUserShortcuts(newShortcuts));
       
        const valueJson = []
        
         newShortcuts.map(nsh => valueJson.push({
             id: nsh.id,
             type: nsh.__typename
         })) 

        updateSettings({ variables: {
            key:"menu_shortcuts",
            value: JSON.stringify(valueJson)
        }}).then( res => {
            console.log('updated shortcuts')
        })
    }
    function ShortcutMenuItem({item, onToggle})
    {
        const itemUrl = item.__typename === "CompanyDialogGraph" ?  `/companyDialog/${item.id}` : item.__typename === "PlaybookGraph" ? `/playbook/${item.id}` : item.__typename === "QuestionGraph" ? `/questions/all/${item.id}` : item.__typename === "ContactGraph" && `/contacts/all/${item.id}`

        const itemIcon = item.__typename === "CompanyDialogGraph" ?  'question_answer' : item.__typename === "PlaybookGraph" ? 'format_list_numbered' : item.__typename === "QuestionGraph" ? 'filter_1' : item.__typename === "ContactGraph" && 'perm_contact_calendar'

        return (
            <Link to={itemUrl} className={classes.item}>
                <MenuItem key={item.id}>
                    <ListItemIcon className="min-w-40">
                        {/* {item.icon ?
                            (
                                <Icon>{item.icon}</Icon>
                            ) :
                            (
                                <span className="text-20 font-bold uppercase text-center">{item.title ? item.title[0] : item.firstName[0]}</span>
                            )
                        } */}
                        <Icon>{itemIcon}</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary={item.title || item.firstName}/>
                    <IconButton
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onToggle(item);
                        }}
                    >
                        <Icon color="action">{shortcuts.includes(item) ? 'star' : 'star_border'}</Icon>
                    </IconButton>
                </MenuItem>
            </Link>
        );
    }
// debugger
    return (
        <div className={clsx(classes.root, props.variant, "flex flex-1", props.variant === "vertical" && "flex-grow-0 flex-shrink", props.className)}>

            <FuseAnimateGroup
                enter={{
                    animation: "transition.expandIn"
                }}
                className={clsx("flex flex-1", props.variant === "vertical" && "flex-col")}
            >
                {shortcutItems.map((item, i) => (
                    <Link 
                        to= {
                            item.__typename === "CompanyDialogGraph" ?  `/companyDialog/${item.id}` : 
                            item.__typename === "PlaybookGraph" ? `/playbook/${item.id}` : 
                            item.__typename === "QuestionGraph" ? `/questions/all/${item.id}` : 
                            item.__typename === "ContactGraph" && `/contacts/all/${item.id}`
                        } 
                        key={item.id} 
                        className={classes.item}
                    >
                        <Tooltip title={item.title || item.firstName} placement={props.variant === "horizontal" ? "bottom" : "left"}>
                            <IconButton className="w-40 h-40 p-0">
                                {/* {item.icon ?
                                    (
                                        <Icon>{item.icon}</Icon>
                                    ) :
                                    (
                                        <span className="text-20 font-bold uppercase">{item.title[0] || item.firstName[0]} </span>
                                    )
                                } */}

                                {/* <span className="text-20 font-bold uppercase">{item.title[0] || item.firstName[0]} </span> */}

                                { item.__typename === 'QuestionGraph' && <Icon>filter_1</Icon> }
                                { item.__typename === 'CompanyDialogGraph' && <Icon>question_answer</Icon> }
                                { item.__typename === 'PlaybookGraph' && <Icon>format_list_numbered</Icon> }
                                { item.__typename === 'ContactGraph' && <Icon>perm_contact_calendar</Icon> }
                            </IconButton>
                        </Tooltip>
                    </Link>
                ))}

                <Tooltip title="Click to add/remove shortcut" placement={props.variant === "horizontal" ? "bottom" : "left"}>
                    <IconButton
                        className="w-40 h-40 p-0"
                        aria-owns={addMenu ? 'add-menu' : null}
                        aria-haspopup="true"
                        onClick={addMenuClick}
                    >
                        <Icon className={classes.addIcon}>star</Icon>
                    </IconButton>
                </Tooltip>
            </FuseAnimateGroup>

            <Menu
                id="add-menu"
                anchorEl={addMenu}
                open={Boolean(addMenu)}
                onClose={addMenuClose}
                classes={{
                    paper: 'mt-48'
                }}
                onEntered={() => {
                    searchInputRef.current.focus();
                }}
                onExited={() => {
                    setSearchText('');
                }}>
                <div className="p-16 pt-8">
                    <Input
                        inputRef={searchInputRef}
                        value={searchText}
                        onChange={search}
                        placeholder="Search for an companyDialog or playbook"
                        className=""
                        fullWidth
                        inputProps={{
                            'aria-label': 'Search'
                        }}
                    />
                </div>

                <Divider/>

                {/* {searchText.length !== 0 && searchResults && searchResults.map(item => (
                    <ShortcutMenuItem
                        key={item.id}
                        item={item}
                        onToggle={() => toggleInShortcuts(item)}
                    />
                ))}

                {searchText.length !== 0 && searchResults.length === 0 && (
                    <Typography color="textSecondary" className="p-16 pb-8">No results..</Typography>
                )} */}

                {searchText.length === 0 && shortcutItems.map(item => item && (
                    <ShortcutMenuItem
                        key={item.id}
                        item={item}
                        onToggle={() => toggleInShortcuts(item)}
                    />
                ))}
            </Menu>
        </div>
    );
}

FuseShortcuts.propTypes = {};
FuseShortcuts.defaultProps = {
    variant: "horizontal"
};

export default React.memo(FuseShortcuts);
