import React, { useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import "chartjs-chart-matrix";
import { useTheme } from '@material-ui/styles';
import moment from 'moment'

export default function DateTimeChartByMonth(props) {
    const { analytics } = props

    const [data, setData] = useState(undefined);
    const [byMonth, setByMonth] = useState(true);
    // const [anchorEl, setAnchorEl] = useState(null);

    const dateChartData = analytics.dateChartData
    const theme = useTheme();

    useEffect(() => {
        if (dateChartData) {
            const data = {
                labels: dateChartData.countPerMonth.map((label, index) => {
                    const d = moment(index + 1, 'M').format('MMMM');
                    return d
                }),
                datasets: [
                    {
                        backgroundColor: theme.palette.primary.light,
                        borderColor: theme.palette.primary.main,
                        borderWidth: 1,
                        hoverBackgroundColor: theme.palette.primary.light,
                        hoverBorderColor: theme.palette.primary.main,
                        data: dateChartData.countPerMonth,
                    },
                ],
            };
            setData(data)
        }
    }, [dateChartData, byMonth])

    return <Bar
        data={data}
        // width={200}
        // height={210}
        width="100%"
        height="100%"
        options={{
            tooltip: {
                enabled: false
            },
            legend: {
                display: false
            },
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value.toString().indexOf(".") > -1) {
                                    return ""
                                }
                                return value;
                            }
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            stepSize: 1
                        },
                    },
                ],
            },
        }}
    />
}