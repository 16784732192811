import React from 'react';
import { useHistory } from 'react-router-dom';
import TheAnswer from 'app/main/shared/TheAnswer'
import { Paper } from '@material-ui/core';
import { useQuery } from '@apollo/client'
import { answers as answersQuery } from 'app/apollo/answers/queries';
import { Spinner } from 'components'
import moment from 'moment'
import _ from '@lodash'

export default function QuestionHubAnswers(props) {
    const history = useHistory()

    const { questionHubId, questionId, title, answerClicked } = props

    const { data: dataAnswers, loading } = useQuery(answersQuery, { variables: { questionHubId: questionHubId, questionId: questionId }, fetchPolicy: 'cache-and-network' });
    // const [getCompanyFromCompanyDialog, { data: dataCompanyFromCompanyDialog }] = useLazyQuery(companyFromCompanyDialog, { fetchPolicy: 'cache-and-network' })

    const answers = dataAnswers ? dataAnswers.answerQ.answers : []

    const groupsByCompanyDialogs = _.groupBy(answers.sort((a, b) => {
        if (Date.parse(a.dateUpdated) > Date.parse(b.dateUpdated)) {
            return -1
        } else if (Date.parse(a.dateUpdated) < Date.parse(b.dateUpdated)) {
            return 1
        }
        return 0
    }), el => el.companyDialog.id);
    // const onlyOne = groupsByCompanyDialogs.length < 2

    return <div>
        
        <h6>{title}</h6>

        {loading && <Spinner text="Loading answers" style={{marginTop: '2em'}} />}

        {answers && answers.length === 0 && <h5 style={{width: '100%', textAlign: 'center', marginTop: '4em'}}>There are no answers yet</h5>}

        {answers && answers.length > 0 && <React.Fragment>
            {Object.keys(groupsByCompanyDialogs).map((companyDialogId, index) => {
                const group = groupsByCompanyDialogs[companyDialogId]
                const companyDialog = group[0].companyDialog

                // const maxDate = new Date(Math.max.apply(null,group.map(g => g.dateUpdated))); 
                const maxDate = group.map(g => g.dateUpdated).reduce(function (a, b) { return a > b ? a : b; });

                return <div style={{marginTop: '2em'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 1em', marginTop: index > 0 && '2em' }}>
                        {/* <div style={{ color: 'grey', fontSize: '0.8em' }}>{`${moment(companyDialog.startDate).fromNow()}`}</div> */}
                        <div style={{ color: 'grey', fontSize: '0.8em' }}>{companyDialog.company && companyDialog.company.name}</div>
                        <div style={{ color: 'grey', fontSize: '0.8em' }}>{`${moment(maxDate).fromNow()}`}</div>
                        {/* <h6 style={{ marginTop: '0.5em' }}>{companyDialog.title}</h6> */}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {group.sort((a, b) => {
                        if (Date.parse(a.dateUpdated) > Date.parse(b.dateUpdated)) {
                            return -1
                        } else if (Date.parse(a.dateUpdated) < Date.parse(b.dateUpdated)) {
                            return 1
                        }
                        return 0
                    }).map((answer, index) => {
                        return <Paper key={index}
                            style={{ cursor: 'pointer', margin: '0.5em 1em 1em 1em', flexGrow: 1, padding: '1em' }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (answer.companyDialog && answer.companyDialog.company) {
                                        history.push(`/account/${answer.companyDialog.company.id}`)
                                        answerClicked && answerClicked()
                                        //     getCompanyFromCompanyDialog({variables: {companyDialogId: answer.companyDialog.id}})
                                        // .then((res) => {
                                        //     debugger
                                        //     history.push(`/account/${res.accountQ.companyFromCompanyDialog.id}`)
                                        // })
                                }
                            }}>

                            {/* <div style={{ color: 'grey', fontSize: '0.75em' }}>
                                {`${moment(answer.dateUpdated).fromNow()}`}
                            </div>
                            <h5>
                                {answer.companyDialog && answer.companyDialog.title}
                            </h5> */}

                            <div style={{ marginTop: '1em' }}>
                                <TheAnswer answer={answer} question={answer.question} returnText={true} />
                            </div>

                            {/* {answer.scribble && answer.scribble.imageContent &&
                            <img src={answer.scribble.imageContent} style={{ width: "auto", height: 50 }} />
                            } */}
                        </Paper>
                    })}
                    </div>
                </div>
              })}
        </React.Fragment>}
    </div>
}