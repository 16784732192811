import { combineReducers } from 'redux'
import fuse from './fuse'
import auth from 'app/auth/store/reducers'
import diagram from './diagram.reducer'
import diagramLink from './diagramLink.reducer'
import quickPanel from 'app/fuse-layouts/shared-components/quickPanel/store/reducers'
import sourceNode from './linkSourceNode.reducer'
import selected from './selected.reducer'
import menuOpen from './menuState.reducer'

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        diagram,
        fuse,
        quickPanel,
        diagramLink,
        sourceNode,
        selected,
        menuOpen,
        ...asyncReducers,
    })

export default createReducer
