import React from 'react';
// import { FusePageSimple } from '@fuse';
import { useQuery } from '@apollo/client';
import { labelBasic } from 'app/apollo/labels/queries';

function LabelChip(props) {
    const {label} = props;

    if(label) {
        return <LabelChipNoFetch {...props} />
    }

    return <LabelChipFetch {...props} />
}

function LabelChipFetch(props) {
    const { data } = useQuery(labelBasic, {variables: {id: props.id}});
    var label = data && data.labelQ.label;

    return <LabelChipNoFetch {...props} label={label}/>
}

function LabelChipNoFetch(props) {
 
    const {onClick, active, left, right, isLast, isFirst, label, showLabel} = props;

    const navigate = () => {
        console.log("navigate");
        // history.push("/labels/all/" + props.id);
    }

    const center = !right && !left && 'center'

    return (
        <div onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
                onClick(label)
            }
            else {
                navigate()
            }
        }}
            style={{
                backgroundColor: label ? (active ? label.color : label.color + '30') : 'transparent',
                color: active ? 'white' : 'black',
                cursor: 'pointer',
                // borderWidth: "0.5px",
                // borderColor: !active ? 'lightgray' : label && label.color,
                borderRadius: '5px',
                padding: '0.2em 0.5em',
                marginTop: "0.2em",
                marginBottom: "0.2em",
                marginLeft: (!center && left && isFirst) ? 0 : '0.2em',
                marginRight: (!center && right && isLast) ? 0 : '0.2em',
                width: 'auto',
                display: 'inline-block',
                minWidth: '4em'
            }}>
            {label && showLabel && label.title}
        </div>
    );
}

export default LabelChip