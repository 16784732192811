import React, { useState, useEffect } from 'react';
// import { FusePageSimple } from '@fuse';
import { ClickAwayListener } from '@material-ui/core';
import { labels as labelsQ } from 'app/apollo/labels/queries';
import { useLazyQuery } from '@apollo/client';
import LabelChip from '../LabelChip/LabelChip';
import LabelPopUpContainer from '../LabelPopUp/LabelPopUpContainer';
import { labelColors } from 'app/tools/config'
import _ from '@lodash'
import ErrorBoundary from 'app/tools/ErrorBoundary'
import { Icon } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { Spinner, Tooltip } from 'components'

function LabelChipContainer(props) {

    const { left, right, id, type, hideAddToButton, hideLabels, labels, refetchLabels,
            rounded, small, style, afterMutation, noMutation, closeOnSelect, light } = props

    const theme = useTheme();
    const addButtonRef = React.useRef(null);

    const [getLabels, { data: dataLabels }] = useLazyQuery(labelsQ, { variables: { id: id, type: type } });
    
    // const [getLabelsAll] = useLazyQuery(labelsQ, {variables: {type: type} } );

    const [labelPopUp, setLabelPopUp] = useState(undefined);
    const [mouseEntered, setMouseEntered] = useState(false);

    const [labelsFinal, setLabelsFinal] = useState([])
    const [labelsFinalSorted, setLabelsFinalSorted] = useState([])

    const [addButtonPosition, setAddButtonPosition] = useState(undefined);

    // const c = addButtonRef && addButtonRef.current

    // useEffect(() => {
    //     if (!hideAddToButton && addButtonRef && addButtonRef.current) {
    //         setTimeout(() => {
    //             if (addButtonRef.current) {
    //                 const pos = addButtonRef.current.getBoundingClientRect()
    //                 setAddButtonPosition(pos)
    //             }
    //         }, 2000);
    //     }
    //     // eslint-disable-next-line
    // }, [addButtonRef, c])

    useEffect(() => {
        if (!labels) {
            getLabels()
        } else {
            setLabelsFinal(labels)
        }
        // eslint-disable-next-line
    }, [labels])

    useEffect(() => {
        if (dataLabels) {
            setLabelsFinal(dataLabels.labelQ.labels)
        }
    }, [dataLabels])

    useEffect(() => {
        if (labelsFinal) {
            let labelsFinalSortedNew = [...labelsFinal]
            setLabelsFinalSorted(_.sortBy(labelsFinalSortedNew, o => labelColors.indexOf(o.color)))
        }
    }, [labelsFinal])

    if (!dataLabels && !labels) {
        return <Spinner text="Loading labels.." />
    }
    // const center = !right && !left && 'center'

    // const cBack = rounded ? theme.palette.primary.dark1 : theme.palette.grey.light

    const AddButton = <Tooltip title="Tags" placement="top">
        <div
            ref={addButtonRef}
            style={{
                // backgroundColor: cBack, // old color #d3d3d352
                color: (rounded || light) ? 'white' : 'black',
                cursor: 'pointer',
                // borderWidth: "0.5px",
                // borderColor: 'transparent',
                // borderRadius: rounded ? 0 : '5px',
                padding: (!rounded && !hideAddToButton) ? '0.2em 0.5em' : '0px',
                marginTop: small && "0.2em",
                marginBottom: small && '0.2em',
                marginLeft: hideAddToButton ? '0px' : rounded ? -15 : right ? '0.4em' : '0.2em',
                marginRight: hideAddToButton ? '0px' : rounded ? 0 : left ? '0.4em' : '0.2em',
                width: !hideAddToButton ? (small ? 26 : 45) : 0,
                height: !hideAddToButton ? (small ? 26 : 45) : 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                border: `1px dashed ${light ? theme.palette.text.light : theme.palette.grey.main}`,
            }}
            onClick={(e) => {
                e.stopPropagation()
                setLabelPopUp(true);
            }}
        >
            <Icon style={{ textAlign: 'center', fill: rounded && 'white', color: light ? theme.palette.text.light : theme.palette.grey.main, fontSize: small ? 16 : 24}}>local_offer</Icon>
        </div>
    </Tooltip>

    if (addButtonPosition) {
        console.log(addButtonPosition)
    }

    // const showLeft = () => {
    //     const width = document.body.clientWidth

    //     if (right && (width - addButtonPosition.left) < 250) {
    //         return true
    //     }

    //     return false
    // }

    const getLabelsAllExecute = () => {
        if (refetchLabels) {
            //getLabelsAll()
        }
    }

    return <ErrorBoundary>
        <div id="labelList" style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: right && 'flex-end',
            flexWrap: 'wrap', ...style
        }}
            onMouseEnter={() => { console.log("entered"); setMouseEntered(true) }}
            onMouseLeave={() => { console.log("leaved"); setMouseEntered(false) }}
        >

            {/* { add button } */}
            {left && !hideAddToButton && AddButton}
            {/* addButtonPosition */}
            {labelPopUp  && addButtonRef.current && <ClickAwayListener onClickAway={() => {
                setLabelPopUp(false);
            }}>
                {/* <Portal container={container && container.current} disablePortal={container == null}> */}
                <div style={{
                    // position: 'absolute',
                    position: 'fixed',
                    // top: addButtonPosition.top + 33,
                    // top: 10,
                    // left: !right && 10,
                    // right: right && 10,
                    // marginLeft: showLeft() && '-251px', 
                    // left: !showLeft() && addButtonPosition.left - 282, 
                    // right: showLeft() && (addButtonPosition.right + 282),
                    zIndex: 1000000000000000
                }}>
                    <LabelPopUpContainer type={type} id={id} getLabels={getLabels} getLabelsAll={getLabelsAllExecute}
                        afterMutation={afterMutation} labelsFilter={labels} noMutation={noMutation} closeOnSelect={closeOnSelect} setLabelPopUp={setLabelPopUp}/>
                </div>
                {/* </Portal> */}
            </ClickAwayListener>}

            {!hideLabels &&
                <div  style={{display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }} >            
                {labelsFinalSorted.map((label, index) => {
                    return <LabelChip
                        id={label.id} key={label.id}
                        label={label}
                        showLabel={!hideAddToButton || mouseEntered}
                        isFirst={index === 0} isLast={index === labelsFinal.length - 1}
                        left={left} right={right}
                        active={true} />
                })}
            </div>}

            {right && !hideAddToButton && AddButton}

        </div>
    </ErrorBoundary>
}

export default LabelChipContainer