import React from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, Icon, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
    root: ({cta, theme, background, onlyIcon, disabled, stretch}) => ({
      display: 'flex',
      color: cta ? "white !important" : 'black', //secondary ? theme.palette.primary.main : theme.palette.secondary.main,
      backgroundColor: background ? background : cta ? theme.palette.secondary.main : theme.palette.primary.light,
      '&:hover': {
        backgroundColor: cta ? theme.palette.secondary.dark :theme.palette.primary.dark,
        color: 'white !important',
      },
      opacity: disabled && '0.6',
      minWidth: onlyIcon && 0,
      padding: onlyIcon && "0.5em",
      marginLeft: 0,
      width: stretch && '100%'
    }),
  });

function SNButton(props) {
    const { children, className, onClick, icon, realIcon, isDelete, background, style, tooltip, iconPosition, disabled } = props;
    const theme = useTheme()

    const typeChildren = children && typeof children != 'string'
    const childrenNew = typeChildren && React.cloneElement(children, {
        style: children.props.style ? {...children.props.style, lineHeight: '1.2em'} : {lineHeight: '1.2em'}
    });

    const noChildren = !(childrenNew || children)
    const onlyIcon = noChildren && icon

    const classes = useStyles({...props, theme, background: background || (isDelete && theme.palette.disabledSecondary.main)
        , onlyIcon});

    const ButtonCustom = <Button disabled={disabled} className={clsx(classes.root, className)} style={style && {...style}} onClick={(ev) => onClick && onClick(ev)}>
        {icon && !iconPosition && <Icon style={{marginRight: children && '0.2em', fontSize: !children && "20px"}}>{icon}</Icon>}
        {(childrenNew || children) && <div style={{display: 'inline-block', marginLeft: icon && '0.5em'}}>
          {childrenNew  || children}
        </div>}
        {icon && iconPosition && <Icon style={{marginLeft: children && '0.2em', fontSize: !children && "20px"}}>{icon}</Icon>}
        {realIcon && iconPosition && realIcon}
    </Button>

    if(tooltip) {
      return <Tooltip title={tooltip} placement="bottom" style={{zIndex: 10000}}>
        {ButtonCustom}
      </Tooltip>
    }

    return <React.Fragment>{ButtonCustom}</React.Fragment>
}

export default SNButton