import React from 'react';
// import { FusePageSimple } from '@fuse';
import { withStyles } from '@material-ui/styles';
import { Badge } from '@material-ui/core';

const StyledBadge = withStyles((theme) => ({
    badge: {
      padding: '0 4px',
      fontSize: "0.8em",
      backgroundColor:  theme.palette.secondary.main,
      color:  theme.palette.primary.main,
    },
}))(Badge);

function CustomChip(props) {
    return <StyledBadge badgeContent={4} >
        {props.children}
    </StyledBadge>
}

export default CustomChip