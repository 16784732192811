import React from 'react';
import { useTheme } from '@material-ui/styles';
import { ContextMenu } from 'components'

// const useStyles = makeStyles((theme) => ({
//     container: {
//     },
// }));

export default function Prio(props) {
    const { prio = 1, setPrio, style, type = "normal", showLabel, noClear, light } = props
    const theme = useTheme()

    const cNothing = light ? theme.palette.prioLight.nothing : theme.palette.prio.nothing
    const cNormal = light ? theme.palette.prioLight.normal : theme.palette.prio.normal
    const cImportant = light ? theme.palette.prioLight.important : theme.palette.prio.important
    const cVeryImportant = light ? theme.palette.prioLight.veryImportant : theme.palette.prio.veryImportant

    const list1 = [{ title: 'Very important', prio: 3, iconFill: cVeryImportant }, { title: 'Important', prio: 2, iconFill: cImportant },
    { title: 'Normal', prio: 1, iconFill: cNormal }, { title: 'Nice to know', prio: 0, iconFill: cNothing }]

    const list2 = [{ title: 'Urgent', prio: 3, iconFill: cVeryImportant }, { title: 'High', prio: 2, iconFill: cImportant },
    { title: 'Normal', prio: 1, iconFill: cNormal }, { title: 'Low', prio: 0, iconFill: cNothing }]

    const listToUse = type === "normal" ? list2 : list1
    const clearPrio = { title: 'Clear', prio: -1, iconFill: cVeryImportant, icon: 'close' }
    if(!noClear) {
        listToUse.push(clearPrio)
    }

    const items = listToUse.map(p => {
        return {
            title: p.title,
            icon: p.icon || 'flag',
            iconFill: p.iconFill,
            onClick: (ev) => {
                setPrio(p.prio)
            }
        }
    })

    const currentPrio = listToUse.find(p => p.prio === prio)
    if(!currentPrio) {
        // debugger
    }

    const hasPrio = currentPrio && currentPrio.prio > -1
    const tooltip = hasPrio ? currentPrio.title : (type === "normal" ? 'Set priority' : 'Set importance')
    const iconFill = hasPrio ? currentPrio.iconFill : light ?  theme.palette.text.light : theme.palette.grey.main

    const C = <ContextMenu menu={items} style={!showLabel && style}
        tooltip={tooltip}
        icon={currentPrio ? "flag" : 'flag_outlined'}
        borderColor={iconFill}
        dashed={!hasPrio}
        iconFill={iconFill}
    // title={currentPrio ? currentPrio.title : tooltip}
    />

    if (showLabel) {
        return <div style={{display: 'flex', alignItems: 'center', ...style}}>
            {C}
            {currentPrio && <div style={{marginLeft: '1.5em', color: light && theme.palette.text.light}}>{currentPrio.title !== 'Clear' && currentPrio.title}</div>}
        </div>
    } else {
        return C
    }
}