import React from 'react';
import { makeStyles } from '@material-ui/styles';
import InputSingleBox from "./InputSingleBox"
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    paperBody: {
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        fontSize: 16,
        color: 'white',
        fontFamily: 'Andale Mono,AndaleMono,monospace',
        justifyContent: 'center'
    },
    changed: {
        color: theme.palette.extra.changed,
    }
 }));
 function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
   }

function InputMultiBox(props) {
    const classes = useStyles(props);
    const { value, setValue, beforeDecimalPlaces, decimalPlaces, changed, min, max, style,  unit } = props;

    const decimalPlacesLOC = decimalPlaces || 0
    const digitItems = []
    let totalDigits = beforeDecimalPlaces + decimalPlacesLOC
    let quantifier = beforeDecimalPlaces
    const maxValue = max || Math.pow(10, quantifier) - (decimalPlacesLOC ? Math.pow(10, decimalPlacesLOC * -1) : 1)
    const minValue = min || 0

    if(value && !value.toFixed) {
        debugger
    }
    const valueFixed = value ? value.toFixed(decimalPlacesLOC) : 0
    if(!isFunction(valueFixed.padStart)){
        // debugger
    }
    const valueString = valueFixed.toString().padStart(totalDigits + (decimalPlacesLOC && decimalPlacesLOC > 0 ? 1 : 0), '0')

    for (let i = 0; i < totalDigits; ++i) {
        if (i === (beforeDecimalPlaces)) {
            digitItems.push(<span style={{width: '0.6em', textAlign: 'center'}} key={i + '1'} className={clsx(changed && classes.changed)}>.</span>)
        }
        quantifier--;

        const digitValue = valueString[quantifier < 0 ? (i + 1) : i];

        digitItems.push(<InputSingleBox small key={i} value={value} digitValue={digitValue} setValue={setValue} quantifier={quantifier} maxValue={maxValue} minValue={minValue} changed={changed}/>)
    }

    return <div className={classes.paperBody} style={style}>
        {digitItems}
        {unit && <span style={{marginLeft: '0.8em'}}>{unit}</span>}
    </div>
}

// function InputUpDown({ title, value, setValue }) {
//     return <InputUpDownComp title={title} value={value} setValue={setValue} />
// }

export default InputMultiBox