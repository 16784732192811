import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/styles';
import clsx from 'clsx'
import AppbarSimple from 'app/main/shared/AppbarSimple'
import { TextField, Spinner } from 'components'
// import { truncate } from 'fs';

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.325em',
    minWidth: '10em',
    padding: '0 1.5em',
    marginTop: '0.33em',
    fontSize: '1.2em',
    color: 'white',
    whiteSpace: 'nowrap'
  },
  cancelButton: {
      background: props => props.cancelColor || theme.palette.primary.lightest,
      border: props => '1px solid ' + theme.palette.primary.lighter3,
      '&:hover': {
          backgroundColor: props => props.cancelColor || theme.palette.primary.lightest2
      },
      color: 'black',
      width: props => props.cancelWidth
  },
  acceptButton: {
      background: props => props.okColor || theme.palette.extra.buttonAccept,
      '&:hover': {
          backgroundColor: props => props.okColor || theme.palette.extra.buttonAcceptHover
      },
      width: props => props.okWidth
  },
  extraButton: {
    background: props => (props.extraButton && props.extraButton.background) || theme.palette.extra.buttonNormal,
    '&:hover': {
        backgroundColor: props => (props.extraButton && props.extraButton.hover) || theme.palette.extra.buttonNormalHover
    },
    width: props => props.extraButton && props.extraButton.width
  },
  inputRoot : {
    width: '100%',
    // color: 'white',
    // border: '1px solid white'
  },
  dialogContainer: {
    backgroundColor:  theme.palette.background.paper,
    margin: '1em',
    overflow: 'hidden'
  }
}));

const CustomDialog = (props) =>  {

  const {
    cancelClick, showDialog, setShowDialog, okClick, 
    description, title, okTitle, cancelTitle, inputWidth,
    inputPlaceholder1, inputPlaceholder2, inputChanged1, inputChanged2, inputValue1, inputValue2, inputRows1, inputRows2,
    center, descBottom, noCancel, noClose, maxWidth = 'xl',
    content, children, extraButton, loaderText, lottie, headerBackground, headerColor
  } = props

  const [valueInput, setValueInput] = useState(inputValue1);
  const [valueInput2, setValueInput2] = useState(inputValue2);

  useEffect(() => {
    setValueInput(inputValue1)
  }, [inputValue1])

  useEffect(() => {
    setValueInput2(inputValue2)
  }, [inputValue2])

  const theme = useTheme()

  const classes = useStyles(props);
  const noContent = !description && !inputPlaceholder1 && !inputPlaceholder2 && !content && !children
  // const hasChildren = content || children

    return <Dialog
      aria-labelledby="settings-panel"
      aria-describedby="settings"
      maxWidth={maxWidth}
      fullWidth={true}
      open={showDialog}
      onClose={() => {
        if(!noClose) {
          setShowDialog(false)
        }
      }}
      classes={{
        paper: classes.dialogContainer
        // paper: position == "MANUAL" ? classes.layoutRootManual : classes.layoutRoot
      }}
    >
      <AppbarSimple background={headerBackground} color={headerColor} title={title} onHandleClose={() =>  setShowDialog(false)}/>
  
  {/* margTop (!title || !hasChildren) &&  */}
      {!noContent && <DialogContent style={{ display: "flex", flexDirection: 'column', marginTop: '2em', overflowX: 'hidden', textAlign: center && 'center', color: 'black'}}>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column'}}>
          {description && !descBottom && <div style={{ width: '100%', color: theme.palette.primary.contrastTextBlack, marginLeft: '0.5em' }}>
            {description}
          </div>}

          {inputPlaceholder1 &&
             <TextField
              style={{width: inputWidth}}
              value={valueInput}
              white={true}
              multiline={!!inputRows1}
              minRows={inputRows1}
              setValue={(ev) => {
                setValueInput(ev)
                inputChanged1 && inputChanged1(ev)
              }}
              label={inputPlaceholder1}
            />}

          {inputPlaceholder2 &&
             <TextField
              style={{width: inputWidth, marginTop: '1em'}}
              white={true}
              multiline={!!inputRows2}
              rows={inputRows2}
              value={valueInput2}
              setValue={(ev) => {
                setValueInput2(ev)
                inputChanged2 && inputChanged2(ev)
              }}
              label={inputPlaceholder2}
            />}

          {description && descBottom && <div style={{ width: '100%', color: 'red', marginTop: '2em'}}>
            {description}
          </div>}

          {content || children}

        </div>
      </DialogContent>}

      <DialogActions style={{ display: "flex", justifyContent: 'flex-end', padding: 0, margin: '2em 2em 2.5em 2em' }}>
       
          {!noCancel && <Button
          className={clsx(classes.button, classes.cancelButton)} style={{marginRight: 0}}
            onClick={() => {
              cancelClick && cancelClick()
              setShowDialog(false)
            }}>
            {cancelTitle || 'CANCEL'}
          </Button>}
          {extraButton && <Button
            className={clsx(classes.button, classes.extraButton)} style={{marginRight: 0}}
              onClick={() => {
                extraButton.onClick()
              }}>
              {extraButton.title}
          </Button>}
          <Button
          className={clsx(classes.button, classes.acceptButton)}
            onClick={() => {
              okClick && okClick(valueInput, valueInput2)
              if(!noClose) {
                setShowDialog(false)
              }
            }}>
            {okTitle || 'OK'}
          </Button>
      </DialogActions>

      {loaderText && <Spinner text={loaderText || 'Loading'} absolute lottie={lottie} />}
    </Dialog>
}

export default function useDialog(props) {
  const [showDialog, setShowDialog] = React.useState(false);

  const CD = <CustomDialog setShowDialog={setShowDialog} showDialog={showDialog}
      {...props}/>

  return { setShowDialog, showDialog, CD }

  // return (title, type) => {
  //   dispatch({ type: 'SHOW_TOAST', data: { title: title, type: type || "success" } });
  // }
}