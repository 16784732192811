import React from 'react';
import { Container } from '@material-ui/core';
import { useHistory} from 'react-router-dom';

export default function HeaderMenu(props) {
    const { page } = props
    let history = useHistory();
    
    return <Container style={{paddingLeft: 7}}>
        <div style={{display: 'flex', cursor: 'pointer'}}>
            {/* <div style={{fontWeight: page == "dashboard" ? 800 : 600}}
                onClick={() => {
                    history.push('dashboard')
                }}>Dashboard</div> */}
            <div style={{fontWeight: page == "campaigns" ? 800 : 600, marginLeft: 15}}
                onClick={() => {
                    history.push('campaigns')
                }}>Campaigns</div>
            <div style={{fontWeight: page === "playbooks" ? 800 : 600, marginLeft: 15}}
                onClick={() => {
                    history.push('playbooks')
                }}>Playbooks</div>
        </div>
  </Container>
}

