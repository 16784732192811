export const DataColors = [
    { backgroundColor: '#f44336', hoverBackgroundColor:''},
    { backgroundColor: '#9c27b0', hoverBackgroundColor:''},
    { backgroundColor: '#03a9f4', hoverBackgroundColor:''},
    { backgroundColor: '#00005f', hoverBackgroundColor:''},
    { backgroundColor: '#ffff00', hoverBackgroundColor:''},
    { backgroundColor: '#005f00', hoverBackgroundColor:''},
    { backgroundColor: '#ffd700', hoverBackgroundColor:''},
    { backgroundColor: '#008700', hoverBackgroundColor:''},
    { backgroundColor: '#ffaf00', hoverBackgroundColor:''},
    { backgroundColor: '#00af00', hoverBackgroundColor:''},
    { backgroundColor: '#ff8700', hoverBackgroundColor:''},
    { backgroundColor: '#00d700', hoverBackgroundColor:''},
    { backgroundColor: '#ff5f00', hoverBackgroundColor:''},
    { backgroundColor: '#00ff00', hoverBackgroundColor:''},
    { backgroundColor: '#ff0000', hoverBackgroundColor:''},
    { backgroundColor: '#5f0000', hoverBackgroundColor:''},
    { backgroundColor: '#d7ff00', hoverBackgroundColor:''},
    { backgroundColor: '#5f5f00', hoverBackgroundColor:''},
    { backgroundColor: '#000087', hoverBackgroundColor:''},
    { backgroundColor: '#ffff5f', hoverBackgroundColor:''},
    { backgroundColor: '#005f5f', hoverBackgroundColor:''},
    { backgroundColor: '#ffd75f', hoverBackgroundColor:''},
    { backgroundColor: '#00875f', hoverBackgroundColor:''},
    { backgroundColor: '#ffaf5f', hoverBackgroundColor:''},
    { backgroundColor: '#00af5f', hoverBackgroundColor:''},
    { backgroundColor: '#ff875f', hoverBackgroundColor:''},
    { backgroundColor: '#00d75f', hoverBackgroundColor:''},
    { backgroundColor: '#ff5f5f', hoverBackgroundColor:''},
    { backgroundColor: '#00ff5f', hoverBackgroundColor:''},
    { backgroundColor: '#ff005f', hoverBackgroundColor:''},
    { backgroundColor: '#5f005f', hoverBackgroundColor:''},
    { backgroundColor: '#d7ff5f', hoverBackgroundColor:''},
    { backgroundColor: '#5f5f5f', hoverBackgroundColor:''},
    { backgroundColor: '#0000af', hoverBackgroundColor:''},
    { backgroundColor: '#ffff87', hoverBackgroundColor:''},
    { backgroundColor: '#005f87', hoverBackgroundColor:''},
    { backgroundColor: '#ffd787', hoverBackgroundColor:''},
    { backgroundColor: '#008787', hoverBackgroundColor:''},
    { backgroundColor: '#ffaf87', hoverBackgroundColor:''},
    { backgroundColor: '#00af87', hoverBackgroundColor:''},
    { backgroundColor: '#ff8787', hoverBackgroundColor:''},
    { backgroundColor: '#00d787', hoverBackgroundColor:''},
    { backgroundColor: '#ff5f87', hoverBackgroundColor:''},
    { backgroundColor: '#00ff87', hoverBackgroundColor:''},
    { backgroundColor: '#ff0087', hoverBackgroundColor:''},
    { backgroundColor: '#5f0087', hoverBackgroundColor:''},
    { backgroundColor: '#d7ff87', hoverBackgroundColor:''},
    { backgroundColor: '#5f5f87', hoverBackgroundColor:''},
    { backgroundColor: '#0000d7', hoverBackgroundColor:''},
    { backgroundColor: '#ffffaf', hoverBackgroundColor:''},
    { backgroundColor: '#005faf', hoverBackgroundColor:''},
    { backgroundColor: '#ffd7af', hoverBackgroundColor:''},
    { backgroundColor: '#0087af', hoverBackgroundColor:''},
    { backgroundColor: '#ffafaf', hoverBackgroundColor:''},
    { backgroundColor: '#00afaf', hoverBackgroundColor:''},
    { backgroundColor: '#ff87af', hoverBackgroundColor:''},
    { backgroundColor: '#00d7af', hoverBackgroundColor:''},
    { backgroundColor: '#ff5faf', hoverBackgroundColor:''},
    { backgroundColor: '#00ffaf', hoverBackgroundColor:''},
    { backgroundColor: '#ff00af', hoverBackgroundColor:''},
    { backgroundColor: '#5f00af', hoverBackgroundColor:''},
    { backgroundColor: '#d7ffaf', hoverBackgroundColor:''},
    { backgroundColor: '#5f5faf', hoverBackgroundColor:''},
    { backgroundColor: '#0000ff', hoverBackgroundColor:''},
    { backgroundColor: '#ffffd7', hoverBackgroundColor:''},
    { backgroundColor: '#005fd7', hoverBackgroundColor:''},
    { backgroundColor: '#ffd7d7', hoverBackgroundColor:''},
    { backgroundColor: '#0087d7', hoverBackgroundColor:''},
    { backgroundColor: '#ffafd7', hoverBackgroundColor:''},
    { backgroundColor: '#00afd7', hoverBackgroundColor:''},
    { backgroundColor: '#ff87d7', hoverBackgroundColor:''},
    { backgroundColor: '#00d7d7', hoverBackgroundColor:''},
    { backgroundColor: '#ff5fd7', hoverBackgroundColor:''},
    { backgroundColor: '#00ffd7', hoverBackgroundColor:''},
    { backgroundColor: '#ff00d7', hoverBackgroundColor:''},
    { backgroundColor: '#5f00d7', hoverBackgroundColor:''},
    { backgroundColor: '#d7ffd7', hoverBackgroundColor:''},
    { backgroundColor: '#5f5fd7', hoverBackgroundColor:''},
    { backgroundColor: '#005fff', hoverBackgroundColor:''},
    { backgroundColor: '#ffd7ff', hoverBackgroundColor:''},
    { backgroundColor: '#0087ff', hoverBackgroundColor:''},
    { backgroundColor: '#ffafff', hoverBackgroundColor:''},
    { backgroundColor: '#00afff', hoverBackgroundColor:''},
    { backgroundColor: '#ff87ff', hoverBackgroundColor:''},
    { backgroundColor: '#00d7ff', hoverBackgroundColor:''},
    { backgroundColor: '#ff5fff', hoverBackgroundColor:''},
    { backgroundColor: '#00ffff', hoverBackgroundColor:''},
    { backgroundColor: '#ff00ff', hoverBackgroundColor:''},
    { backgroundColor: '#5f00ff', hoverBackgroundColor:''},
    { backgroundColor: '#d7ffff', hoverBackgroundColor:''},
    { backgroundColor: '#5f5fff', hoverBackgroundColor:''},
] 
