import React from 'react';
import { Bubble } from "react-chartjs-2";
import NoAnswers from "./NoAnswers"

export default function NumberRangeCharts(props) {
    const { analytics } = props

    if(analytics.answerCount === 0) {
        return <NoAnswers text="No mumber answers given yet" />
    }

    const dataBubble = {
        labels: ["January"],
        datasets: [
          {
            label: "My First dataset",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [],
          },
        ],
      };

    return  <Bubble data={dataBubble} 
      // getElementAtEvent= {(ev) => {
      //   debugger
      //   const index = ev && ev[0] && ev[0]._index

      //   if(index >= 0) {
      //     const d = barChartData.zoomedInData[index]
      //     addFilter({value: d.startNumber + '@' + d.endNumber, label: d.label, count: d.count})
      //   }
      // }}
       />
}