export default {
  contactinformation:{
    en: 'Contact information',
    fr: 'Informations de contact',
    nl: 'Contactgegevens',
  },
  company:{
    en: 'Company',
    fr: 'Entreprise',
    nl: 'Bedrijf',
  },
  noemails: {
    en: 'no emails',
    fr: "pas d'e-mails",
    nl: 'geen e-mails'
  },
  nostreet: {
    en: 'no street',
    fr: 'pas de rue',
    nl: 'geen straat'
  },
  nocity: {
    en: 'no city',
    fr: 'pas de ville',
    nl: 'geen stad'
  },
  nocountry: {
    en: 'no country',
    fr: 'aucun pays',
    nl: 'geen land'
  },
  website:{
    en: 'Website',
    fr: 'Website',
    nl: 'Website',
  },  
  noCompanyDialogs:{
    en: 'No meetings',
    fr: "Pas d'entretiens",
    nl: 'Geen afspraken',
  },
  noPains:{
    en: 'No Pains',
    fr: 'Pas de douleurs',
    nl: 'Geen pijnpunten',
  },
  notes:{
    en: 'Notes',
    fr: 'Remarques',
    nl: 'Notities',
  },
  size:{
    en: 'Size',
    fr: 'Grandeur',
    nl: 'Grootte',
  },
  vat:{
    en: 'VAT',
    fr: 'TVA',
    nl: 'BTW',
  },
  industry:{
    en: 'Industry',
    fr: 'Industrie',
    nl: 'Industrie',
  },
  contacts:{
    en: 'Contacts',
    fr: 'Contacts',
    nl: 'Contacten',
  },
  notesaboutthiscontact: {
    en: 'Notes about this contact',
    fr: 'Notes sur ce contact',
    nl: 'Opmerkingen over dit contact'
  },
  notesaboutthiscompany: {
    en: 'Notes about this company',
    fr: 'Notes sur ce entreprise',
    nl: 'Opmerkingen over dit bedrijf'
  },
  removeContact: {
    en: 'Do you really want to remove this contact?',
    fr: 'Voulez-vous supprimer ce contact?',
    nl: 'Wil je echt dit contact verwijderen?'
  },
  removeAccount: {
    en: 'Do you really want to remove this account?',
    fr: 'Voulez-vous supprimer ce entreprise?',
    nl: 'Wil je echt dit account verwijderen?'
  },
  selectContact: {
    en: 'Select a contact òr company to start a conversation.',
    fr: 'Sélectionnez un contact ou une entreprise pour démarrer une conversation',
    nl: 'Selecteer een contact of bedrijf om een ​​gesprek te beginnen'
  },
  dmu: {
    en: 'Desicion Maker',
    fr: 'Desicion Maker',
    nl: 'Beslisser',
  }
}