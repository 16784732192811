import React, {useState} from 'react';
import {Typography, LinearProgress} from '@material-ui/core';
import {useTimeout} from '@fuse/hooks';
import PropTypes from 'prop-types';
import animData from 'components/Controls/SplashScreen/loadinganimation.json';
import { SplashScreen } from 'components'
import Lottie from "lottie-react";

function FuseLoading(props)
{
    const [showLoading, setShowLoading] = useState(!props.delay);

    useTimeout(() => {
        setShowLoading(true);
    }, props.delay);

    if (!showLoading)
    {
        return null;
    }

    return <SplashScreen content="Loading..." />

    // return (
    //     <div className="flex flex-1 flex-col items-center justify-center"
    //         style={{position: 'fixed', top: 0, left: 0, margin: props.margin ? props.margin: 'inherit', 
    //             background: '#ffffffdd', height: '100%', width: '100%'}}>
    //         {!props.hideTitle && <Typography className="text-20 mb-16" color="textSecondary">Loading...</Typography>}
            
    //         <div style={{width: '8em'}}>
    //             <Lottie animationData={animData} />
    //         </div>
    //         {/* <LinearProgress className="w-xs" color="secondary"/> */}
    //     </div>
    // );
}

FuseLoading.propTypes = {
    delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FuseLoading.defaultProps = {
    delay: false
};

export default FuseLoading;
