import gql from 'graphql-tag'

export const cannyJWT = gql`
query cannyJWT {
  cannyJWT
}
`

export const userMe = gql`
query user {
  me {
    id
    color
    isImpersonated
    hasIntegration
    firstName
    lastName
    email
    currentOrganisationUserId
    currentOrganisation{
      id
      title
    }
    recentlyCreatedCompanyDialogs{
      id
      title
    }
    recentlyVisitedCompanyDialogs {
      id
      value
    }
    settings{
      key
      value
    }
  }
}
`
// recentlyCreatedPlaybooks {
//   id
//   title
// }
// recentlyVisitedPlaybooks {
//   id
//   value
// }

export const userMeWithLicenses = gql`
query user {
  me {
    id
    firstName
    lastName
    email
    language
    currentOrganisationUserId
    currentOrganisation {
      id
      title
      organisationLicense{
        id
        amount
        emailsActiveAmount
        monthsValid
        emailLicenses{
          id
          dateClaimed
          dateCreated
          email
          userClaimed{
            firstName
            lastName
            organisationUser {
              id
              role
            }
          }
          active
        }
      }
    }
  }
}
`

export const userMeBasic = gql`
query user {
  me {
    id
    isImpersonated
    onboardingStep
    onboardingData
    isBlocked
    firstName
    lastName
    language
    email
    currentOrganisationUserId
    currentOrganisationId
    color
    role
  }
}
`

// export const userMeBasicWithSettings = gql`
// query user {
//   me {
//     id
//     isImpersonated
//     firstName
//     lastName
//     language
//     email
//     currentOrganisationUserId
//     currentOrganisationId
//     settings{
//       key
//       value
//     }
//   }
// }
// `

export const user = gql`
  query user($id: ID!) {
    user(id: $id){
      id
      firstName
      lastName
      email
      currentOrganisation {
        id
        title
      }
      recentlyCreatedCompanyDialogs{
        id
      }
      recentlyVisitedCompanyDialogs{
        id
      }
    }
  }
`
// recentlyCreatedPlaybooks{
//   id
// }
// recentlyVisitedPlaybooks{
//   id
// }
export const allUsers = gql`
query users {
  users {
    id
    firstName
    lastName
    currentOrganisation {
      id
      title
    }
  }
}
`