import gql from 'graphql-tag'

export const CREATE_OPPORTUNITY = gql`
  mutation CREATE_OPPORTUNITY(
    $title: String!,
    $stageId: ID!,
    $companyId: ID!,
    $dateExpectedClose: DateTime,
    $price: Int
  ){
    opportunityM {
      createOpportunity(
        title: $title,
        stageId: $stageId,
        companyId: $companyId,
        dateExpectedClose: $dateExpectedClose,
        price: $price
      ){
        id
      }
    }
  }
`

export const SET_OPPORTUNITY_STAGE = gql`
  mutation SET_OPPORTUNITY_STAGE(
    $opportunityId: ID!,
    $stageId: ID!
  ){
    opportunityM {
      setOpportunityStage(
        opportunityId: $opportunityId,
        stageId: $stageId
      ){
        id
      }
    }
  }
`