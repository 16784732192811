export const GetTypeShort = (type) => {
    switch (type) {
        case 'TEXTAREA':
            return 'Text'
        case 'INPUT':
            return '1 line'
        case 'INT':
        case 'NUMBER_INT':
            return 'Number'
        case 'NUMBER_FLOAT':
        case 'FLOAT':
            return 'Decimal'
        case 'NUMBER_SLIDER':
        case 'NUMBER_FLOAT_SLIDER':
            return 'Slider'
        case 'NUMBER_RANGE_SLIDER':
        case 'NUMBER_RANGE':
            return 'Range Slider'
        case 'DATETIME':
            return 'Date'
        case 'DATE':
            return 'Day'
        case 'TIME':
            return 'Hour'
        case 'OPTIONS_RADIO':
            return 'Multiple choice - 1 option'
        case 'OPTIONS_COMBO':
            return 'Multiple choice - 1 option'
        case 'OPTIONS_CHECKBOX':
            return 'Multiple choice - multiple options'
        case 'BOOL_SWITCH':
        case 'BOOL':
            return 'Yes - No'
        default:
            return "No type"
    }
}

export const GetTypeIcon = (type) => {
    switch (type) {
        case "TEXTAREA":
            return "wrap_text"
        case "INPUT":
            return "short_text"
        case 'OPTIONS_RADIO':
        case 'OPTIONS_COMBO':
        case 'OPTIONS_CHECKBOX':
            return "check_box";
        case 'NUMBER_INT':
        case 'INT':
        case 'NUMBER_RANGE':
        case "NUMBER_FLOAT":
        case "FLOAT":
            return "functions"
        case 'NUMBER_SLIDER':  
        case 'NUMBER_RANGE_SLIDER':
        case 'NUMBER_FLOAT_SLIDER':
            return 'tune'
        case 'DATETIME':
        case 'DATE':
            return "date_range"
        case "TIME":
            return "schedule"
        case "BOOL_SWITCH":
            return "done_outline"
        default:
            return undefined
    }
}