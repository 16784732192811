import gql from 'graphql-tag'

export const optionItems = gql`
query optionItems($questionId: ID!) {
      optionItems(questionId: $questionId) {
        id
        title
      }
  }
`

export const questionsBasic = gql`
  query questions {
    questionQ {
      questions {
        id
        icon
        placeholder
        dataType
      }
    }
  }
`

export const questionsLight = gql`
  query questions {
    questionQ {
      questions {
        id
        placeholder
        icon
        hasPreviousQuestion
      }
    }
  }
`

export const questionsIds = gql`
    query questionsIds {
      questionQ {
        questions {
              id
              dateUpdated
          }
        }
    }
`

export const questionCount = gql`
  query questionCount {
    questionQ {
      questionCount
    }
  }
`

export const questionForList = gql`
  query questions {
    questionQ {
      questions {
        id
        placeholder
        dataType
        icon
        answerCount
        playbookCount
        companyDialogCount
        creator{
          id
          firstName
          lastName
        }
        labels {
          id
          title
          color
          description
        }
        dateCreated
        dateUpdated
      }
    }
  }
`

export const questions = gql`
  query questions {
    questionQ {
      questions {
        id
        placeholder
        icon
        options {
          id
          title
        }
        dataType
        answers {
          id
        }
        defaultValue
        defaultStartValue
        defaultEndValue
        minValue
        maxValue
        playbooks {
          id
          title
        }
        companyDialogs {
          id
          title
          companyDialogState {
            companyDialogStatus
            message
          }
        }
        answerRangeQuestion {
          id
          valueBool
          valueOptions{
            id
          }
          valueDateFrom
          valueDateTo
          valueNumberFrom
          valueNumberTo
          Question{
            id
            dataType
            answers{
              id
              valueYesNo
              valueOptions{
                id
              }
              valueDate
              valueNumber
              valueNumberFrom
              valueNumberTo
              companyDialog {
                id
              }
            }
          }
        }
        answerChildQuestions{
          id
          valueBool
          valueOptions{
            id
          }
          valueDateFrom
          valueDateTo
          valueNumberFrom
          valueNumberTo
          QuestionHub {
            id
            title
            description
            dataType
            placeholder
            options {
              id
              title
            }
            defaultStartValue
            defaultEndValue
            answers{
              id
              valueYesNo
              valueOptions{
                id
                title
              }
              valueText
              valueDate
              valueNumber
              valueNumberFrom
              valueNumberTo
              companyDialog{
                  id
              }
            }
          }
        }
        labels {
          id
          title
          color
        }
        creator{
          id
          firstName
          lastName
        }
        dateCreated
        dateUpdated
      }
    }
  }
`

export const questionWithAnswer = gql`
  query question($id: ID!) {
    questionQ {
      question(id: $id) {
        id
        title
        icon
        options {
          id
          title
        }
        dataType
        answers {
          id
        }
        defaultValue
        minValue
        maxValue
        labels {
          id
          title
        }
        playbooks {
          id
          title
        }
        # scribbles {
        #   id
        # }
        dateCreated
      }
    }
  }
`
export const questionComments = gql`
  query questionComments($id: ID!, $skip: Boolean!){
    questionQ {
      question(id:$id) @skip(if: $skip){
        id
        comments{
          id
          content
          dateCreated
          creator {
            id
            firstName
            lastName
            color
          }
          dateCreated
        }
      }
    }
  }
`

export const questionWithInsightsOLD = gql`
  query question($id: ID!) {
    questionQ {
      question (id: $id) {
        id
        icon
        placeholder
        description
        answerCount
        dataType
      }
    }
  }
  `

export const questionAnalytics = gql`
  query questionAnalytics($questionId: ID!, $dataType: String!, $filterCompanyDialogs: [ID!]) {
    questionQ {
      questionAnalytics (questionId: $questionId, dataType: $dataType, filterCompanyDialogs: $filterCompanyDialogs) {
        typeOfAnalytics
        answerCount

        yesNoData {
          answeredYes
          answeredNo
          answeredEmpty
          answerRatio
        }

        barChartData {
          zoomedInData {
            startNumber
            endNumber
            count
          }
          zoomedOutData {
            startNumber
            endNumber
            count
          }
        }
        
        doughnutChartData{
          optionId
          count
          label
        }

        timeChartData{
          count
          hour
          startMinute
        }

        dateChartData{
          countPerMonth
          countPerWeek
        }
      }
    }
  }
  `

export const question = gql`
  query question($id: ID!, $skip:Boolean!) {
    questionQ {
      question (id: $id) @skip(if: $skip){
        id
        icon
        answerChildQuestions{
          id
          valueNumberFrom
          valueNumberTo
          valueDateFrom
          valueDateTo
          valueBool
          valueOptions{
            id
            title
          }
          QuestionHub{
            id
            title
            dataType
            options {
              id
              title
              mediaItemId
              mediaItem{
                url
              }
            }
            defaultValueBool
            defaultValue
            defaultStartValue
            defaultEndValue
            minValue
            maxValue
          }
        }
        answerRangeQuestion{
          id
          valueNumberFrom
          valueNumberTo
          valueDateFrom
          valueDateTo
          valueBool
          valueOptions{
            id
            title
          }
          Question{
            id
            title
            dataType
            options {
              id
              title
            }
            defaultValueBool
            defaultValue
            defaultStartValue
            defaultEndValue
            minValue
            maxValue
          }
        }
        title
        placeholder
        options {
          id
          title
          mediaItemId
          mediaItem{
            url
            type
          }
        }
        dataType
        description
        defaultValueBool
        answers {
          id
          dateCreated
          dateUpdated
          valueText
          valueNumber
          valueNumberFrom
          valueNumberTo
          valueDate
          valueYesNo
          valueOptions {
            id
            title
          }
          companyDialog {
            id
            title
            startDate
          }
        }
        defaultValue
        defaultStartValue
        defaultEndValue
        minValue
        maxValue
        isInternal
        dateCreated
      }
    }
  }
`
// answer:
// scribble {
//   id
//   content
//   imageContent
// }

export const companyDialogQuestion = gql`
  query question($id: ID!, $companyDialogId: ID!) {
    questionQ {
      question(id: $id, companyDialogId: $companyDialogId) {
        id
        
        title
        icon
        description
        dataType
        placeholder

        answerChildQuestions(companyDialogId: $companyDialogId){
          id
          questionHubId
          questionId
        }

        options {
          id
          title
          mediaItem{
            url
            type
          }
        }

        defaultValue
        defaultEndValue
        defaultStartValue
        maxValue
        minValue
        defaultValueBool

        answer(companyDialogId: $companyDialogId){
          id

          companyDialogId
          questionId

          dateUpdated
          valueText
          valueNumber
          valueYesNo
          valueDecimal
          valueNumberTo
          valueNumberFrom
          valueDate
          valueOptions{
            id
            title
          }
          scribble{
            id
            content
            imageContent
          }
        }

      }
    }
  }
`

export const questionHubQuestions = gql`
  query questions($questionHubId: ID!) {
    questionQ {
      questions(questionHubId: $questionHubId) {
        id
        placeholder
        dataType

        customFieldId
        customFieldContext
        customFieldLabel
        customFieldType
        
        options {
          id
          title
          mediaItem{
            url
            type
          }
        }
  
        defaultValue
        defaultEndValue
        defaultStartValue
        maxValue
        minValue
        defaultValueBool
      }
    }
  }
`

   // answerChildQuestions{
        //   id
        //   QuestionHubId
        // }
// export const questionForCompose = gql`
//   query question($id: ID!) {
//     questionQ {
//       question(id: $id) {
//         id
  
//         placeholder
//         dataType
//         icon

//         options {
//           id
//           title
//           mediaItem{
//             url
//             type
//           }
//         }
  
//         defaultValue
//         defaultEndValue
//         defaultStartValue
//         maxValue
//         minValue
//         defaultValueBool
  
//       }
//     }
//   }
// `