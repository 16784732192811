import * as UserActions from './user.actions';
import * as Actions from 'app/store/actions';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export function submitRegister({displayName, password, email})
{
    return (dispatch) => {}
}

export function registerWithFirebase(model)
{
    const {email, password, displayName} = model;
    return (dispatch) => {}
}
