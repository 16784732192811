import React, {useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Dialog, DialogActions, Button, DialogContent, Input } from '@material-ui/core';
import clsx from 'clsx';
import DialogInputMultiBox  from 'components/Controls/SliderCustom/DialogInputMultiBox/DialogInputMultiBox'
import TextField from 'components/Controls/TextField/TextField'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex-inline',
        fontFamily: 'Andale Mono,AndaleMono,monospace',
        width: 'auto',
    },
    containerTitleTop: {
        flexDirection: 'column',
    },
    containerTitleLeft: {
        flexDirection: 'row',
        lineHeight: '1.5em',
    },
    valueUnit: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignContent: 'flex-end',
        width: 'auto',
        fontFamily: 'Andale Mono,AndaleMono,monospace',
    },
    value : {
        color: theme.palette.secondary.lighter,
        fontSize: "1.8em",
        paddingBottom: 0,
        lineHeight: '1.3em'
    },
    unit: {
        color: theme.palette.primary.contrastTextGrey,
        lineHeight: '2.6em',
        marginLeft: '0.3em'
    },
    title: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.15em',
        marginBottom: '0.3em',
        wordSpacing: '-0.3em',
    },
    titleLeft: {
        marginRight: '1.2em',
        width: 'auto',
        display: 'inline-block'
    },
    dialogPaper          : {
        backgroundColor: theme.palette.dialog.background,
        boxShadow      : theme.shadows[5],
    },
    dialogButtons: {
        color: theme.palette.dialog.buttonColor
    }
}));

function TextAmount(props) {
    const classes = useStyles(props);
    const theme = useTheme();

    const {
        title,
        unit,
        value, setValue,
        valueInitial,
        titlePlacement = 'left',
        style,
        beforeDecimalPlaces,
        decimalPlaces = 0,
        changed,
        clickUnit,
        titleRight, titleWidth,
        amountRight, amountWidth,
        unsubscribe, resubscribe,
        min, max,
        sliderlabel
    } = props;

    const [showEditDialog, setShowEditDialog] = useState(undefined)
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(undefined)

    useEffect(() => {
        if(showEditDialog == true && unsubscribe) {
             unsubscribe()
        } else if(showEditDialog == false && resubscribe && !changed && !showConfirmCancelDialog) {
            resubscribe()
        }
    }, [showEditDialog, showConfirmCancelDialog])

    useEffect(() => {
        if(value) {
            if(value == valueInitial) {
                return;
            }
            if(value > max) {
                setValue(max)
            } else if(value < min) {
                setValue(min)
            }
        }
    }, [value])

    // const [valueLength] = useState(value && value.toString().length)

    const styleN = style ? style : {}

    const rounded = value ? (Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces).toFixed(decimalPlaces)).toFixed(decimalPlaces) : 0
    // new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)

    if(isNaN(rounded)) {
        debugger
    }

    // const borderBottom = '1.5px solid' + theme.palette.secondary.lighter

    return <div className={clsx(classes.container, titlePlacement === 'left' ? classes.containerTitleLeft : classes.containerTitleTop)} style={{...styleN}} >
        <div className={classes.valueUnit} style={{width: amountWidth, justifyContent: amountRight && 'flex-end'}}>
            <TextField sliderlabel={sliderlabel} changed={changed} label={title} clickUnit={clickUnit}
                value={rounded} setValue={setValue}
                endLabel={unit} startButton="edit"
                startButtonClick={() => {
                    if(setValue) {
                        setShowEditDialog(true)
                    }
                }}
                type="number"
                right />
        </div>

        {showEditDialog && <DialogInputMultiBox
            value={value}
            setValue={setValue}
            showEditDialog={showEditDialog}
            setShowEditDialog={setShowEditDialog}
            setShowConfirmCancelDialog={setShowConfirmCancelDialog}
            {...props}
        />}

        {showConfirmCancelDialog && <Dialog
            // TransitionComponent={Transition}
            aria-labelledby="settings-panel"
            aria-describedby="settings"
            open={showConfirmCancelDialog}
            // keepMounted
            onClose={() => {
                // setShowConfirmCancelDialog(false)
            }}
            // BackdropProps={{invisible: true}}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <div id="form-dialog-title" style={{display: "flex", justifyContent: 'center', margin: '1em 0', fontSize: '1.5em', color: 'white'}}>Attention</div>
            
            <DialogContent style={{display: "flex", color: 'white'}}>
                <h4>Are you sure you want to cancel this change?</h4>
            </DialogContent>

            <DialogActions style={{display: "flex", justifyContent: 'center'}}>
                <Button style={{fontSize: '0.85em', marginTop: '1em'}}
                onClick={() => {
                    setShowConfirmCancelDialog(false)
                    setShowEditDialog(false)
                }} className={classes.dialogButtons}>
                    YES
                </Button>
                <Button style={{fontSize: '0.85em', marginTop: '1em'}}
                onClick={() => {
                    setShowConfirmCancelDialog(false)
                    setShowEditDialog(true)
                }} className={classes.dialogButtons}>
                    NO
                </Button>
            </DialogActions>
        </Dialog>}
    </div>
}

export default TextAmount