import React, {useState} from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import { TextField } from 'components'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionContainer: {
    display: 'flex',
    // justifyContent: 'center',
    border: '1px solid lightgrey',
    padding: 3,
    flexWrap: 'wrap'
  },
    option: {
      // border: '1px solid lightgrey',
      borderRadius: 3,
      padding: '2px 5px',
      margin: '5px 5px',
      background: 'lightgrey',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      fontSize: '1.3em !important',
      color: 'grey',
      marginLeft: 5,
      marginTop: 2,
    },
    addicon: {
      fontSize: '1.8em !important',
      color: theme.palette.primary.main,
      right: 8,
      top: 9,
      position: 'absolute',
      cursor: 'pointer'
    }
  }));

export default function CreateOptionsList(props) {
    const { options, setOptions, style } = props;
    const classes = useStyles()

   const [addOptionText, setAddOptionText] = useState(undefined);
   const foundDup = addOptionText && options && options.find(op => op.title === addOptionText)

   const addOptionAction = () => {
    if(addOptionText !== "") {
      let optionsNew = options ? [...options] : []
      optionsNew.push({title: addOptionText})
      setOptions(optionsNew)
      setAddOptionText(undefined)
    }
   }

    return <div className={classes.container} style={style}>
      <div style={{position: 'relative'}}>
        <TextField height="3em" style={{marginBottom: '1em'}} placeholder="New option"
          onEnter={addOptionAction}
          value={addOptionText}
          setValue={(value) => {
            setAddOptionText(value)
        }}/>
        {addOptionText&& !foundDup && <Icon classes={{root: classes.addicon}}
            onClick={addOptionAction}>add</Icon>}
      </div>

     <div className={classes.optionContainer}>
        {options && options.map((option, index) => {
          return <div key={index} className={classes.option}>
            <div>{option.title}</div>
            <Icon classes={{root: classes.icon}}
            onClick={() => {
              let optionsNew = [...options]
              const index = optionsNew.indexOf(option)
              optionsNew.splice(index, 1)
              console.log({option})
              setOptions(optionsNew)
            }}>close</Icon>
          </div>
        })}
      </div>
    </div>
}
