import React, { useState, useEffect, useContext } from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import AppContext from 'app/AppContext';
import FuseThemesConfig from 'app/fuse-configs/themesConfig';

export default function SnackbarCustom() {
    const [open, setOpen] = useState(false);

    const { state } = useContext(AppContext);
    const { title, type } = state

    useEffect(() => {
        if(title) {
            setOpen(true)
        }
    }, [title])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{ zIndex: 999999 }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <MySnackbarContentWrapper
                onClose={handleClose}
                variant={type}
                message={title && title.title}
            />
        </Snackbar>
}


const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: FuseThemesConfig.playbook.palette.extra.red,
    },
    info: {
        backgroundColor: FuseThemesConfig.playbook.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 10 //theme && theme.spacing && theme.spacing(5),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.3em'
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
    console.logF("MySnackbarContentWrapper")

    let classes = useStyles(props);
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant || "info"];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}
