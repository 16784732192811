import { useContext } from 'react';
import playbooksln from 'app/languages/playbooksln'
import contactln from 'app/languages/contactln'
import AppContext from 'app/AppContext'

export default function useTranslate() {
  const { language } = useContext(AppContext);

  return (title, key) => {
    let o = undefined
    switch (key) {
      case "sn":
        o = playbooksln[title]
        break;
      case "contact":
        o = contactln[title]
        break;
      default:
        break;
    }

    if(!o) {
      return "translation not found "
    }

    return o[language]
  }
}