import {lightBlue, red} from '@material-ui/core/colors';
import {fuseDark} from '@fuse/fuse-colors';

const themesConfig = {
    // playbook     : {
    //     palette: {
    //         type     : 'light',
    //         primary  : {
    //             lightest: '#9964EB10',
    //             light: '#9964EB',
    //             main : '#5D1EC2',
    //             dark1 : '#44168D',
    //             dark : '#18003D',
    //             grey : '#afacb5',
    //             darkGrey: '#333333'
    //         },
    //         grey: {
    //             lightest : '#F4F4F4',
    //             light : '#EEEEEE',
    //             dark: '#333333'
    //         },
    //         secondary: {
    //             light       : '#FFCD70',
    //             main        : '#FFB01F',
    //             dark        : '#f87700', //#ffa500
    //             darkest     : '#3D2900',
    //             contrastText: '#FFF'
    //         },
    //         disabledPrimary: {
    //             main        : '#9f8fb9',
    //             color        : '#DDDDDD',
    //         },
    //         disabledSecondary: {
    //             main        : '#AA0000',
    //             color        : '#AAAAAA',
    //         },
    //         extra: {
    //             menu : '#303030',
    //             remove: '#ec0000'
    //         },
    //         error    : red
    //     },
    //     status : {
    //         danger: 'orange'
    //     }
    // },
    playbook     : {
        palette: {
            type     : 'light',
            text: {
                white: '#FaFaFa',

                light: '#dfdfdf',
                grey: '#afacb5',
                darkgrey: '#777777',
                dark: '#333333',

                black: '#111111',
            },
            primary  : {
                lightest: '#8BDCCE10',      //#9964EB10
                lighter1: '#8BDCCE20',    
                lighter2: '#8BDCCE30',    
                lighter3: '#8BDCCE70',    
                lighter: '#B4F2E7',           
                light: '#8BDCCE',           //#9964EB
                mainButton : '#8CC1B7',           //#5D1EC2
                main : '#69A79C',           //#5D1EC2
                dark1 : '#217566',  //#44168D
                dark : '#004438',           //#18003D
                darkest : '#003430',           //#18003D
                darkGrey: '#333333',
                contrastText: '#FFF',
                contrastTextBlack: '#111111',
                contrastTextBlackLess: '#222222',
            },
            grey: {
                lightest : '#FaFaFa',
                lighter : '#F4F4F4',
                light : '#EEEEEE',
                normal : '#dddddd',
                main : '#afacb5',
                dark: '#333333'
            },
            background: {
                papergrey: '#fcfcfc',
                default: '#217566'
            },
            dialog: {
                background: "#424242",
                buttonColor: '#90caf9'
            },
            stageColors: ["#eb5a46", "#ff9f1a", "#f2d600", "#00c2e0", "#51e898", "#b3bac5"],
            secondary: {
                lightest       : '#BADFE766',    //#FFCD70
                light       : '#BADFE7',    //#FFCD70
                main        : '#6FB3B8',    //#FFB01F
                dark        : '#388087',    //#f87700
                darkest     : '#063F44',    //#3D2900
                contrastText: '#FFF'
            },
            disabledPrimary: {
                main        : '#9f8fb9',
                color        : '#DDDDDD',
            },
            disabledSecondary: {
                main        : '#AA0000',
                color        : '#AAAAAA',
            },
            extra: {
                menu : '#303030',
                remove: '#ec0000',
                red: '#ff1100',
                changed: '#FF2FC3',
                changedLight: '#ff90df',
                changedHover: '#9e1c79',
                buttonAccept: '#009F82',
                buttonAcceptHover: '#006b44',
                buttonNormal: '#006F82',
                buttonNormalNotActive: '#033E4C',
                buttonNormalActive: '#00FF00',
                buttonNormalHover: '#034A54',
                cbPopped: '#ff9900'
            },
            prio: {
                nothing: 'rgb(216, 216, 216)',
                normal: 'rgb(55, 111, 255)',
                important: '#ff9800',
                veryImportant: '#ff655a'
            },
            prioLight: {
                nothing: '#eeeeee',
                normal: '#ccccff',
                important: '#ffcc00',
                veryImportant: '#ff9999'
            },
            error :  red
        },
        status : {
            danger: 'orange'
        }
    },
    playbookLIGHT     : {
        palette: {
            type     : 'light',
            primary  : {
                lightest: '#8BDCCE10',      //#9964EB10
                lighter1: '#8BDCCE20',    
                lighter2: '#8BDCCE30',    
                lighter3: '#8BDCCE70',    
                lighter: '#B4F2E7',           
                light: '#8BDCCE',           //#9964EB
                mainButton : '#8CC1B7',           //#5D1EC2
                main : '#69A79C',           //#5D1EC2
                dark1 : '#217566',  //#44168D
                dark : '#004438',           //#18003D
                darkest : '#003430',           //#18003D
                darkGrey: '#333333',
                contrastText: '#FFF',
                contrastTextBlack: '#111111',
                contrastTextBlackLess: '#222222',
            },
            grey: {
                lightest : '#FaFaFa',
                lighter : '#F4F4F4',
                light : '#EEEEEE',
                main : '#afacb5',
                dark: '#333333'
            },
            background: {
                papergrey: '#fcfcfc',
                default: '#fafafa'
            },
            dialog: {
                background: "#424242",
                buttonColor: '#90caf9'
            },
            stageColors: ["#eb5a46", "#ff9f1a", "#f2d600", "#00c2e0", "#51e898", "#b3bac5"],

            // light       : '#C2EDCE',    //#FFCD70
            // main        : '#83EEA1',    //#FFB01F
            // dark        : '#58CC79',    //#f87700
            // darkest     : '#0C9D33',    //#3D2900

            //  lightest: '#BADFE710',      //#9964EB10
            //     light: '#BADFE7',           //#9964EB
            //     main : '#6FB3B8',           //#5D1EC2
            //     dark1 : '#388087',  //#44168D
            //     dark : '#063F44',         
                
            secondary: {
                lightest       : '#BADFE766',    //#FFCD70
                light       : '#BADFE7',    //#FFCD70
                main        : '#6FB3B8',    //#FFB01F
                dark        : '#388087',    //#f87700
                darkest     : '#063F44',    //#3D2900
                contrastText: '#FFF'
            },
            disabledPrimary: {
                main        : '#9f8fb9',
                color        : '#DDDDDD',
            },
            disabledSecondary: {
                main        : '#AA0000',
                color        : '#AAAAAA',
            },
            extra: {
                menu : '#303030',
                remove: '#ec0000',
                red: '#ff1100',
                changed: '#FF2FC3',
                changedLight: '#ff90df',
                changedHover: '#9e1c79',
                buttonAccept: '#009F82',
                buttonAcceptHover: '#006b44',
                buttonNormal: '#006F82',
                buttonNormalNotActive: '#033E4C',
                buttonNormalActive: '#00FF00',
                buttonNormalHover: '#034A54',
                cbPopped: '#ff9900'
            },
            prio: {
                nothing: 'rgb(216, 216, 216)',
                normal: 'rgb(55, 111, 255)',
                important: '#ff9800',
                veryImportant: '#ff655a'
            },
            error :  red
        },
        status : {
            danger: 'orange'
        }
    },
    playbookOLD2     : {
        palette: {
            type     : 'light',
            primary  : {
                light: '#2CDEE1',
                main : '#18A5A7',
                dark : '#0C5556',
            },
            secondary: {
                // light       : '#BFFFC7',
                light       : '#00E71F',
                main        : '#009B15',
                dark        : '#003A08',
                darkest     : '#001202',
              
                contrastText: '#FFF'
            },
            extra: {
                menu : '#303030',
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    playbookNative     : {
        palette: {
            type     : 'light',
            primary  : {
                lightest: '#9964EB10',
                light: '#9964EB',
                main : '#5D1EC2',
                dark1 : '#44168D',
                dark : '#18003D',
                grey : '#afacb5',
                darkGrey: '#333333'
            },
            grey: {
                lightest : '#F4F4F4',
                light : '#EEEEEE',
                dark: '#333333'
            },
            secondary: {
                light       : '#FFCD70',
                main        : '#FFB01F',
                dark        : '#f87700', //#ffa500
                darkest     : '#3D2900',
                contrastText: '#FFF'
            },
            disabledPrimary: {
                main        : '#9f8fb9',
                color        : '#DDDDDD',
            },
            disabledSecondary: {
                main        : '#AA0000',
                color        : '#AAAAAA',
            },
            extra: {
                menu : '#303030',
                remove: '#ec0000'
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    playbookOld     : {
        palette: {
            type     : 'light',
            primary  : {
                light: '#6CBFC3',
                main : '#5D9A9D',
                dark : '#3E5151',
            },
            secondary: {
                light       : '#97db4f',
                main        : '#63843d',
                dark        : '#495834',
                contrastText: '#FFF'
            },
            extra: {
                menu : '#303030'
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    default    : {
        palette: {
            type     : 'light',
            primary  : fuseDark,
            secondary: {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700]
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    sunset     : {
        palette: {
            type     : 'light',
            primary  : {
                light: '#FF908B',
                main : '#D0605E',
                dark : '#9B3134'
            },
            secondary: {
                light       : '#C76A1D',
                main        : '#FF994C',
                dark        : '#FFCA7B',
                contrastText: '#FFF'
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    greeny     : {
        palette: {
            type     : 'light',
            primary  : {
                light: '#6CABD4',
                main : '#387CA3',
                dark : '#005074'
            },
            secondary: {
                light       : '#89F6CF',
                main        : '#55C39E',
                dark        : '#159270',
                contrastText: '#FFF'
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    beach      : {
        palette: {
            type     : 'light',
            primary  : {
                light       : '#C4D8DD',
                main        : '#93A7AB',
                dark        : '#65787C',
                contrastText: '#FFF'
            },
            secondary: {
                light       : '#FFB281',
                main        : '#F18153',
                dark        : '#BA5228',
                contrastText: '#FFF'
            }
        }
    },
    tech       : {
        palette: {
            type     : 'light',
            primary  : {
                light       : '#87EFFF',
                main        : '#4DBCE9',
                dark        : '#008CB7',
                contrastText: '#FFF'
            },
            secondary: {
                light: '#FFFF83',
                main : '#D1E751',
                dark : '#9DB516'
            }
        }
    },
    sweetHues  : {
        palette: {
            type     : 'light',
            primary  : {
                light       : '#D5C1EB',
                main        : '#A391B9',
                dark        : '#746389',
                contrastText: '#FFF'
            },
            secondary: {
                light: '#90AFD4',
                main : '#6080A3',
                dark : '#325474'
            }
        }
    },
    defaultDark: {
        palette: {
            type     : 'dark',
            primary  : fuseDark,
            secondary: {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700]
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    deepOcean  : {
        palette: {
            type     : 'dark',
            primary  : {
                light: '#8F53E7',
                main : '#5A24B4',
                dark : '#1E0083'
            },
            secondary: {
                light       : '#FF61FF',
                main        : '#FE00E9',
                dark        : '#C600B6',
                contrastText: '#FFF'
            }
        }
    },
    slate      : {
        palette: {
            type     : 'dark',
            primary  : {
                light: '#86FFF7',
                main : '#4ECDC4',
                dark : '#009B94'
            },
            secondary: {
                light       : '#FF9D99',
                main        : '#FF6B6B',
                dark        : '#C73840',
                contrastText: '#FFF'
            }
        }
    }
};

export default themesConfig;
