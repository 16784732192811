import * as React from 'react'
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core'
import { PortModelAlignment } from '@projectstorm/react-diagrams'
import { DefaultPortModel } from './DefaultPortModel'
import styled from '@emotion/styled'
import { Icon, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FuseThemesConfig from 'app/fuse-configs/themesConfig'
import clsx from 'clsx'
import { FormatAlignCenterRounded } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { isNullableType } from 'graphql'

// var tinycolor = require("tinycolor2");

export interface DefaultPortLabelProps {
    port: DefaultPortModel
    engine: DiagramEngine
}

const useStyles = makeStyles((theme: any) => ({
    portLabelContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        position: 'absolute',
        bottom: -8,
        width: '100%',
        justifyContent: 'center',
        left: '0px !important',
    },
    bottomLabelContainer: {
        opacity: 1
    },
    portLabel: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        '&:hover' : {
            background: theme.palette.primary.dark,
            color: 'white'
        },
        '&:hover span' : {
            color: 'white !important'
        },
        cursor: 'pointer'
    },
    bottomOutContainer: {
        display: 'flex',
        position: 'absolute',
        // bottom: -8,
        // left: '0px !important'
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    left: {
        left: -4,
        top: '50%',
    },
    right: {
        right: -4,
        top: '50%',
    },
    top: {
        top: -4,
        left: '50%',
    },
    bottom: {
        bottom: -4,
        left: '50%',
    },
    middle: {
        // bottom: "50%",
        // left: "50%"
    },
    portSize: {
        width: "100%",
        height: 15,
    },
    portBottom: {
        
        borderRadius: '50%',
        border: '1px solid green',
        backgroundColor: 'white',
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    port: {
        position: 'absolute',
        right: 0,
        bottom: 1,
        width: 20,
        height: 17,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        cursor: 'grab',
    },
    portBottomOUT: {
        background: 'green',
        width: 8,
        height: 8,
        borderRadius: 10,
        position: 'absolute',
    },
    absolute: {
        position: 'absolute',
    },
    hover: {
        background: theme.palette.primary.lighter3,
    },
    hoverAlways: {
        display: 'block',
        background: theme.palette.primary.lighter,
    },
}))

const Arrow = ({angle, translate}) =>
        <svg height="5" width="10">
            <polygon style={{ translate: translate, transformOrigin: '0% -45%', transform: 'rotate(' + angle + 'deg) translate('+ translate +')', fill: "grey" }}
            points="0,0 7,-7 -7,-7"
            //   onMouseLeave={() => {
            //     this.setState({ selected: false });
            //   }}
            //   onMouseEnter={() => {
            //     this.setState({ selected: true });
            //   }}
            //   data-id={point.getID()}
            //   data-linkid={point.getLink().getID()}
        ></polygon></svg>

export const Label2 = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    ${({ alignment }:any) => alignment == "right" && `
    justify-content: flex-end;
  `}
`

export function DefaultPortLabel(props) {
    const dispatch = useDispatch()
    const { port, engine } = props

    const options = port.getOptions()
    const { questionIcon, align, name, direction } = options

    const classes = useStyles({...props, ...options})

    const node = port.getNode()
    if(!node)
    {
        return null
    }

    // var color = tinycolor(nodeBackground);
    const isSTART = port?.parent?.portsIn?.length == 0 ? true : false
    const isBottomOut = name == 'bottomOUT'
    const showBottom = isBottomOut || isSTART

    const styleIcon = {
        color: 'black',
        fontSize: 14,
        marginLeft: 5,
        marginRight: 2,
        marginBottom: -3.5,
        cursor: 'grab',
    }

    // switch (questionIcon) {
    // 	case "yesno":
    // 		questionIcon = "person"
    // 		break;
    // 	default:
    // 		break;
    // }

    const handleClick = (label) => {
        dispatch({
            type: 'SELECTED',
            payload: { selected: label },
        })
    } 

    const portWidget = (
        <PortWidget engine={engine} port={port} style={{width:"15px", position:"absolute", cursor:'grab', left: !isBottomOut && !options.in && !isSTART && options.alignment == "left" ? 0 :!isBottomOut && !options.in && !isSTART && options.alignment == "right" ? "calc(100% - 15px)" : null, height: "15px"}}>
                {showBottom && <div className={clsx(showBottom && classes.portBottom, classes.portSize, !showBottom && classes.port, options.hover && isBottomOut && classes.hoverAlways)}>
                    {showBottom && <Icon style={{ fontSize: 13 }}>arrow_downward</Icon>}
                </div>}
                {!showBottom && <div className={classes.portSize} style={{position: 'absolute', top: 0, right: 0}}></div>}
            {/* <Icon style={{ marginLeft: -15, color: 'black'}}>person</Icon> */}
            {/* </Port> */}
        </PortWidget>
    )

    const label = (
        <Label2 alignment={options.alignment}>
            {!options.in && options.alignment == "left" &&(
                <div
                    // border: '0.5px solid white',
                    style={{
                        borderRadius: '0 10px 10px 0px',
                        // border: '1px solid ' + FuseThemesConfig.playbook.palette.primary.mainForHeader,
                        // backgroundColor: color.brighten(35)
                        margin: '0px 2px -2px 0',
                        marginRight: 5,
                        cursor: 'grab',
                    }}
                    className={options.hover ? clsx(classes.hover) : undefined}
                >
                    {/* backgroundColor: '#D0B7F6', margin: '0px -2px 2px 0', marginLeft: 5}}> */}
                    {/* backgroundColor: isRoot ? '#D0B7F6' : '#FFCD70', margin: '0px -2px 2px 0', marginLeft: 5}}> */}
                    <Icon style={styleIcon}>{questionIcon}</Icon>
                </div>
            )}
            <div
                style={{ whiteSpace: 'nowrap', marginLeft: options.alignment == "left" ? 0 : 20, marginRight: options.alignment == "right" ? 0 : 20 }}
            >
                {options.label}
            </div>
            {/*  #043c01  #afafaf */}
            {!options.in && options.alignment == "right" &&(
                <div
                    // border: '0.5px solid white',
                    style={{
                        borderRadius: '10px 0 0 10px',
                        // border: '1px solid ' + FuseThemesConfig.playbook.palette.primary.mainForHeader,
                        // backgroundColor: color.brighten(35)
                        margin: '0px -2px 2px 0',
                        marginLeft: 5,
                        cursor: 'grab',
                    }}
                    className={options.hover ? clsx(classes.hover) : undefined}
                >
                    {/* backgroundColor: '#D0B7F6', margin: '0px -2px 2px 0', marginLeft: 5}}> */}
                    {/* backgroundColor: isRoot ? '#D0B7F6' : '#FFCD70', margin: '0px -2px 2px 0', marginLeft: 5}}> */}
                    <Icon style={styleIcon}>{questionIcon}</Icon>
                </div>
            )}
        </Label2>
    )
    const alignClass = 
        align === 'left'
            ? classes.left
            : align === 'right'
            ? classes.right
            : align === 'top'
            ? classes.top
            : align === 'middle'
            ? classes.middle
            : classes.bottom

    // if(hide) {
    // 	return <PortLabel></PortLabel>
    // }
    if (options.in) {
        const linksUsed = Object.keys(port.links)
        const isUsed = linksUsed.length > 0

        let angle = 0
        let translate = '0px, 0px'
        switch (align) {
            case 'top':
                angle = 0
                translate = '7px, -4px'
                break;
            case 'left':
                angle = -90
                translate = '-2px, 0px'
                break;
            case 'bottom':
                angle = 180
                translate = '-7px, 0px'
                break;
            case 'right':
                angle = 90
                translate = '2px, -4px'
                break;
            default:
                break;
        }

        // IN
        return (
            <div className={clsx(classes.absolute, alignClass)} style={{width: 5, height: 5 }}>
                {isUsed && <div style={{position: 'absolute'}}><Arrow angle={angle} translate={translate} /></div>}
                {portWidget}
            </div>
        )
    } else {
        if (showBottom) {
            return <div className={clsx(classes.portLabelContainer, classes.bottomLabelContainer, 'bottomPortVisible')}>{portWidget}</div>
        }

        // OUT
        return (
            <div
                className={clsx(
                    classes.portLabelContainer,
                    name == 'bottomOUT' && classes.bottomOutContainer,
                )}
                style={{
                    position: 'relative',
                    bottom: 0,
                    width: 'auto',
                }}
            >
                    <div className={classes.portLabel} style={{display: 'flex', width: '100%'}} onClick={() => {
                        handleClick(options.label)
                    }}>
                        {/* {direction == 'left' && <PortWidget engine={engine} port={port}></PortWidget>} */}
                        {portWidget}
                        {!isSTART ? label : null}
            
                    </div>
            </div>
        )
    }
}
