import React from 'react';
// import { makeStyles } from '@material-ui/styles';
import { Avatar as AvatarMaterial } from '@material-ui/core';
import { Tooltip } from 'components'

// const useStyles = makeStyles(theme => ({
//     avatar: {
//         width: 72,
//         height: 72,
//         position: 'relative',
//         // padding   : 8,
//         background: theme.palette.background.default,
//         color: 'white',
//         boxSizing: 'content-box',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         transition: theme.transitions.create('all', {
//             duration: theme.transitions.duration.shortest,
//             easing: theme.transitions.easing.easeInOut,
//         }),
//         '& > img': {
//             borderRadius: '50%'
//         }
//     },
// }));

// a string (str): we will use it to get the hue parameter of the returned color;
// the saturation (s) of returned color: a number between 0 and 100;
// the lightness (l) of returned color: a number between 0 and 100.
function strToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    var h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export default function Avatar(props) {
    const { children, small, verysmall, className, notooltip, tooltipText, image, square, large, width: widthOutside } = props
    const width = widthOutside || (large ? 60 : verysmall ? 25 : small ? 35 : 45)

    if(image) {
        return <AvatarMaterial alt={children} src={image} className={className}/>
    }

    if(!children) {
        return null
    }

    let letters = children.charAt(0).toUpperCase()
    const indexFirstSpace = children.trim().indexOf(" ")
    if(indexFirstSpace > -1) {
        const part1 = children.substr(0, indexFirstSpace)
        const part2 = children.substr(indexFirstSpace + 1)
        letters = part1.charAt(0).toUpperCase() + part2.charAt(0).toUpperCase()
    }

    const AV = <AvatarMaterial className={className}
        style={{ backgroundColor: strToHslColor(children, 30, 50), borderRadius: square && 6, color: 'white',
            fontSize: large ? '4rem' : verysmall ? '1.8rem' : small ? '2.1rem': '2.6rem', width: width, height: width}}
    >
        {letters}
    </AvatarMaterial>
    if(notooltip){
        return <React.Fragment>{AV}</React.Fragment>
    }
    return <Tooltip title={tooltipText || children} placement="top" className={className}>
        {AV}
    </Tooltip>
}