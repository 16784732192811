import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Dialog, DialogActions, Button, DialogContent } from '@material-ui/core';
import InputMultiBox  from '../InputMultiBox/InputMultiBox'

const useStyles = makeStyles(theme => ({
    unit: {
        color: theme.palette.primary.contrastTextGrey,
        lineHeight: '2.6em',
        marginLeft: '0.3em'
    },
    dialogPaper          : {
        backgroundColor: theme.palette.dialog.background,
        boxShadow      : theme.shadows[5],
    },
    dialogButtons: {
        color: theme.palette.dialog.buttonColor
    }
}));

export default function DialogInputMultiBox(props) {
    const {
        showEditDialog,
        value,
        setValue,
        beforeDecimalPlaces,
        setShowConfirmCancelDialog,
        setShowEditDialog,
        title,
        max,
        min,
        valueLength,
        decimalPlaces,
        unit,
    } = props

    const classes = useStyles(props);
    const theme = useTheme();

    const [valueDialog, setValueDialog] = useState(value)

    return <Dialog
        aria-labelledby="settings-panel"
        aria-describedby="settings"
        open={showEditDialog}
        onClose={() => {
            if(value !== valueDialog) {
                setShowConfirmCancelDialog(true)
            } else {
                setShowEditDialog(false)
            }
        }}
        classes={{
            paper: classes.dialogPaper
        }}
    >
        <div id="form-dialog-title" style={{display: "flex", justifyContent: 'center', margin: '1em 0', fontSize: '1.5em', color: 'white'}}>{title}</div>
        <DialogContent style={{display: "flex"}}>
            <InputMultiBox max={max} min={min} value={valueDialog} setValue={setValueDialog} beforeDecimalPlaces={beforeDecimalPlaces || valueLength} changed={value !== valueDialog} decimalPlaces={decimalPlaces || 0} />
            <span className={classes.unit} style={{display: 'flex', alignItems: 'center', color: 'white'}}>{unit}</span>
        </DialogContent>

        <DialogActions style={{display: "flex", justifyContent: 'center'}}>
            <Button style={{fontSize: '0.85em', marginTop: '1em', color: value === valueDialog && theme.palette.primary.contrastTextGrey}}
                disabled={value === valueDialog}
                onClick={() => {
                    setShowEditDialog(false)
                    setValue(valueDialog)
                }} className={classes.dialogButtons}>
                    OK
            </Button>
        </DialogActions>
    </Dialog>
}