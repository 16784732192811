import React, { useState, useEffect } from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import { Button, TextField } from '@material-ui/core';
import {  useMutation } from '@apollo/client';
//import { MUTATE_LABEL } from 'app/apollo/labels/mutations';
import { CREATE_LABEL, UPDATE_LABEL, DELETE_LABEL } from 'app/apollo/labels/mutations';
import { labels as labelsQ } from 'app/apollo/labels/queries';
import {labelColors} from  'app/tools/config'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    button: {
        //margin: theme.spacing(1),
        width: '10px',
      },
    root: {
        minWidth: '48px',
    }
}));

function LabelPopUpCreate(props) {
    const classes = useStyles(props);
    const { type, typeId , labelCreate, labelsSelected, setLabelsSelected, setLabelCreate, getLabels, getLabelsAll } = props;

    const [createLabel] = useMutation(CREATE_LABEL, 
        {
            refetchQueries: [
                { query: labelsQ, variables: {id: typeId, type: type} },
                { query: labelsQ, variables: {type: type} },
            ]
        })
    const [updateLabel] = useMutation(UPDATE_LABEL, 
        {
            refetchQueries: [
                { query: labelsQ, variables: {id: typeId, type: type} },
            ]
        })
    const [deleteLabel] = useMutation(DELETE_LABEL, 
        {
            refetchQueries: [
                { query: labelsQ, variables: {id: typeId, type: type} },
                { query: labelsQ, variables: {type: type} },
            ]
        })

    const [labelName, setLabelName] = useState(labelCreate && labelCreate.title);
    const [labelColor, setLabelColor] = useState(labelCreate && labelCreate.color);

    useEffect(() => {
        if(labelCreate) {
            setLabelName(labelCreate && labelCreate.title);
            setLabelColor(labelCreate && labelCreate.color);
        }
    }, [labelCreate]);

    const Mutate = () => {
        if(labelCreate.id){
            console.log(" action: update label");
            updateLabel({variables: {id: labelCreate.id, color: labelColor, title: labelName}})
                .then((res) => {
                    setLabelCreate(undefined);
                    if(getLabels) {
                        getLabels()
                    } 
                    if(getLabelsAll) {
                        getLabelsAll()
                    }
                });
        }
        else{
            console.log(" action: create label");
            createLabel({variables: {title: labelName, color: labelColor, typeId, labelTypeString: type}})
            .then((res) => {
                setLabelCreate(undefined);
                setLabelsSelected([...labelsSelected, res.data.labelM.createLabel]);
                if(getLabels) {
                    getLabels()
                } 
                if(getLabelsAll) {
                    getLabelsAll()
                }
            });
        }
    }

    const DeleteLabel = () => {
        console.log(" action: delete label");
        deleteLabel({variables: {id: labelCreate.id}})
                .then((res) => {
                    setLabelCreate(undefined);
                });
    }

    const CancelLabel = () => {
        console.log(" action: cancel label");
        setLabelCreate(undefined);
    }

    //console.log("label Changed name: " + labelName + ", color : " + labelColor);

    return <div className={classes.paperBody}>

        <div id="LabelPopUpCreateChild" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}>
            
            {/* name new label textfield/input */}
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}>
                {/* <Input placeholder="name" classes={{input: classes.input, formControl: classes.formControl, root: classes.root}}></Input> */}
                <div style={{width: '95%'}} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} >
                    <TextField
                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} 
                        id="outlined-basic" 
                        size="small" 
                        label="name" 
                        variant="outlined" 
                        fullWidth 
                        onChange={(e) => setLabelName(e.target.value)}
                        value={labelName}/>
                </div>
            </div>

            {/* label colors container */}
            <div>

                <p style={{fontSize: '12px', fontWeight: 'bold', marginLeft: '8px', marginTop: '10px'}}>
                    Select a color
                </p>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    width: '100%',
                    height: 'auto',
                    padding: '7px',
                }}>                

                {/* forEach loop colors */}
                {labelColors.map((color, index) => 

                    <Button key={index} style={{ display: 'flex', backgroundColor: color, height: '32px', margin: '2.5px' }}
                        className={clsx(classes.button, classes.root)}
                        onClick={() => {
                            //SelectColor({color});
                            setLabelColor(color);
                        }}>
                        {labelColor === color && <CheckIcon style={{ color: 'white', fontSize: '15px' }}></CheckIcon>}
                    </Button>
                )}

                </div>

                <div id="buttons" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>

                    <Button variant="contained" color="secondary" size="small" disableElevation
                        style={{fontSize: '0.9rem', width: 'auto', margin: '2.5px', marginLeft: 7}}
                        onClick={() => {
                            Mutate();
                        }}>{labelCreate.id ? 'Update' : 'Create'}</Button>

                    <Button variant="contained" size="small" disableElevation
                        style={{fontSize: '0.9rem', width: 'auto', margin: '2.5px', marginRight: 7, backgroundColor: '#dc004e', color: 'white'}}
                        onClick={() => {
                            if(labelCreate.id){
                                // Delete label
                                DeleteLabel();
                            }
                            else{
                                // cancel label
                                CancelLabel();
                            }
                            // delete or cancel based on update or create
                        }}>{labelCreate.id ? 'Delete' : 'Cancel'}</Button> 
                </div>
            </div>
        
        </div>
    </div>
}

export default LabelPopUpCreate