// By default, this project supports all modern browsers.
// Support for Internet Explorer 11 requires polyfills.
// For to support Internet Explorer 11, install react-app-polyfill,
// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'typeface-muli';
import './react-table-defaults';
import './react-chartjs-2-defaults';
import "./styles/tailwind.css"
// import "./styles/tailwind-base.css"
// import "./styles/tailwind-config.css"
import './styles/normalize.css';
import './styles/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { LazyLoader } from './LazyLoader';
// import { CacheBuster } from 'app/CacheBuster';
// import { Provider } from 'react-redux';
// import { createStore } from 'redux';
// import { AzureAD, AuthenticationState } from 'react-aad-msal';
// import { authProvider } from 'app/main/auth/authProvider';
// export const store = createStore(reducer);

if(window.location.href === "https://mvp.opendialog.be/mvp.opendialog.be") {
    window.location.href = window.location.origin
}

// function handler () {
//     debugger
// }

// window.addEventListener("beforeinstallprompt", () => {
//     debugger
// });

ReactDOM.render(
  // <Provider store={store}>
    // <CacheBuster>
      <LazyLoader />,
    // </CacheBuster>,
  // </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
