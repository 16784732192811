
import React from 'react'
import { SNButton } from 'components';

export default function ZoomButtons(props) {
    const { changeZoom } = props

    return <div style={{display: 'flex'}}>
        <SNButton
            //   background={theme.palette.secondary.main}
            // primary
            // icon="add"
            className="h-32 w-32 font-bold min-w-0"
            tooltip="Zoom out"
            style={{ zIndex: 100, marginBottom: 5, color: 'black', borderRadius: '100%' }}
            onClick={() => {
                changeZoom(-20)
            }}
        >-</SNButton>
        <SNButton
            //   background={theme.palette.secondary.main}
            // primary
            // icon="add"
            className="h-32 w-32 font-bold min-w-0 ml-16"
            tooltip="Zoom in"
            style={{ zIndex: 100, marginLeft: '1em !important', color: 'black', borderRadius: '100%' }}
            onClick={() => {
                changeZoom(+20)
            }}
        >+</SNButton>
    </div>
}