import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    arrowContainer: {
        color: 'white',
        margin: '0 0.15em',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    arrow: {
        color: 'white',
        border: '1px solid white',
        borderRadius: 5,
        height: '45.5px',
        width: '2.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.extra.buttonNormalHover
        }
    },
    changed: {
        color: theme.palette.extra.changed,
    }
}));

function InputSingleBox(props) {
    const classes = useStyles(props);
    const { value, setValue, quantifier, digitValue, maxValue, minValue, changed } = props;

    const changeValue = (up) => {
        let changeByValue = 1;
        if(!up) {
            changeByValue *= -1;
        }
        const temp = changeByValue * Math.pow(10, quantifier);
        // console.log(value)
        // console.log(temp)
        let res = (value || 0) + temp
        if(res < 0 && minValue === 0) {
            res = 0
        } else if(maxValue && res > maxValue) {
            // console.log("maxval")
            res = maxValue
        } else if(minValue && res < minValue) {
            res = minValue
        }
        console.log(res)
        setValue(res)
    }

    return <div className={classes.arrowContainer}>
        <div
            className={classes.arrow}
            style={{marginBottom: "0.3em"}}
            onClick={() => {
                changeValue(true)
            }}><Icon>arrow_drop_up</Icon></div>
        <div style={{fontSize: '1.5em', userSelect: "none"}}  className={clsx(changed && classes.changed)}>
            {digitValue}
        </div>
        <div
            className={classes.arrow}
            style={{marginTop: "0.3em"}}
            onClick={() => {
                changeValue(false)
            }}><Icon>arrow_drop_down</Icon></div>
    </div>
}

export default InputSingleBox