import React from 'react';
import './style.css'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center'
    },
    buttonLeft: {
        borderRadius: "5px 0 0 5px",
    },
    buttonRight: {
        borderRadius: "0 5px 5px 0",
    },
    button: {
        border: '1px solid ' + theme.palette.primary.dark,
        width: 15,
        height: 15,
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: theme.palette.grey.lightest,
        background: theme.palette.primary.dark
    },
    input: {
        textAlign: 'center'
    }
}));

export default function NumberInput(props) {
    const {value, setValue, min, max, step, style} = props
    const classes = useStyles();

    const change = (direction) => {
        if(direction === "up") {
            setValue(value + 1)
        } else {
            setValue(value - 1)
        }
    }

    return <div className={classes.root} style={style}>
        <div className={clsx(classes.button, classes.buttonLeft)} onClick={() => change('down')}>-</div>
        <input placeholder='number' step={step ? step : undefined}
         className={classes.input} type="number" id="tentacles" name="tentacles"
            min={min} max={max} value={value || ''} onChange={ev => {
                    setValue(step ? parseFloat(ev.target.value) : parseInt(ev.target.value))
            }}/>
        <div className={clsx(classes.button, classes.buttonRight)} onClick={() => change('up')}>+</div>
    </div>
  
}