const reducer = (state = { selected: undefined }, action) => {
    switch (action.type) {
        case 'SELECTED':
            return Object.assign({}, state, { selected: action.payload.selected })
        default:
            return state
    }
}

export default reducer
