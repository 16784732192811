import React from 'react';
import { Doughnut } from "react-chartjs-2";
import NoAnswers from "./NoAnswers"
import { DataColors } from "app/main/shared/DataColors";

export default function OptionsCharts(props) {
  const { analytics, addFilter } = props

  if (analytics.answerCount === 0) {
    return <NoAnswers text="No option answers given yet" />
  }

  const doughnutChartData = analytics.doughnutChartData;
  const getDataOptions = () => {
    const data = {
      labels: doughnutChartData.map(d => d.label),

      datasets: [
        {
          data: doughnutChartData.map(d => d.count),
          backgroundColor: doughnutChartData.map(
            (s, index) => DataColors[index].backgroundColor
          ),
        },
      ],
    };
    console.log(data);
    return data;
  };

  // analytics.typeOfAnalytics === "DOUGHNUT"

  return <Doughnut
    data={getDataOptions()}
    options={{
      cutoutPercentage: 66,
      spanGaps: false,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          padding: 16,
          usePointStyle: true
        }
      },
      maintainAspectRatio: false
    }}
    getElementAtEvent={(ev) => {
      const index = ev && ev[0] && ev[0]._index

      if(index >= 0) {
        const d = doughnutChartData[index]
        addFilter({value: d.optionId, label: d.label, count: d.count})
      }
    }}
  />
}