import React, { useState, useEffect } from 'react'
import {Slider} from '@material-ui/core';
import { useDebounce } from '@fuse/hooks';
import { withStyles } from '@material-ui/styles';
import FuseThemesConfig from 'app/fuse-configs/themesConfig';

window.palette = FuseThemesConfig.playbook.palette

const IOSSlider = withStyles({
  root: {
    color: window.palette.primary.dark,
    height: 8,
    marginBottom: '0 !important'
  },
  thumb: {
    height: 37,
    // width: props => !props.valuelength ? 100 :
    //   props.valuelength < 4 ? 55 :
    //     props.valuelength < 5 ? 65 :
    //       props.valuelength < 6 ? 75 :
    //         props.valuelength < 7 ? (props.value < 10 ? 65 : 85) :
    //           props.valuelength < 8 ? (props.value < 10 ? 75 : 95) :
    //             props.valuelength < 9 ? (props.value < 10 ? 85 : 105) :
    //               props.valuelength < 10 ? 115 :
    //                 props.valuelength < 11 ? 125 : 135,
    width: 'auto',
    zIndex: 1,
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: window.palette.primary.light,
    border: props => '2px solid ' + (props.changed == "true" ? window.palette.extra.changed : window.palette.primary.main),
    marginTop: -15,
    // marginLeft: props => !props.valuelength ? -35 :
    //   props.valuelength < 4 ? -25 :
    //     props.valuelength < 5 ? -30 :
    //       props.valuelength < 6 ? -35 :
    //         props.valuelength < 7 ? -40 :
    //           props.valuelength < 8 ? -45 :
    //             props.valuelength < 9 ? -50 :
    //               props.valuelength < 10 ? -55 :
    //                 props.valuelength < 11 ? -60 : -65,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  markLabel: {
    color: window.palette.secondary.main,
    fontSize: '0.85em'
  },
  mark: {
    backgroundColor: window.palette.primary.light,
    height: 8,
    // width: 1,
    marginTop: 0,
    // marginRight: 10,
    zIndex: 1,
  },
  markActive: {
    opacity: 1,
    backgroundColor: window.palette.primary.main,
  },
  active: {},
  valueLabel: {
    left: 0,
    // top: '0.9em',
    zIndex: 1,
    width: '100%',
    //   background: 'red',
    '& span': {
      width: '100%',
      background: 'transparent',
      fontSize: "1.2em",
      // color: "black",
      fontWeight: 500,
      color: props => props.changed == "true" ? window.palette.extra.changed : "white"
    }
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: props => props.changed == "true" ? window.palette.extra.changed : window.palette.primary.light 
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomThumbComponent = (props) => {
  const value = props["aria-valuenow"]
  const max = props["aria-valuemax"]
  const unit = props["aria-label"]
  const decimals = props["aria-labelledby"]
  const isArray = props["aria-valuetext"] == "true"

  let valuelength = 6 //undefined
  let percentage = 100
  // if (isArray) {
    valuelength = (value.toString() + " " + (unit || "")).length
    percentage = value / max * 100

    // if (percentage < 6) {
    //   valuelength = valueLocal.toString().length
    // }
  // }

  let valueN = (Math.round(value * 100) / 100).toFixed(parseInt(decimals));
  const isSubMiddle = !isArray || valuelength <= 5 || (valuelength > 5 && percentage >= 0 && percentage < 80)
  const isMiddle = !isArray || valuelength <= 5 || (valuelength > 5 && percentage >= 0 && percentage < 87.5)
  // const smallerEnd = !isMiddle && percentage >= 90
  const valueNEW = valueN + (isSubMiddle ? ((" " + unit) || "") : '')

  const propsNew = {...props}
  propsNew.style.margin = "-15px 0px 0px -" + (valuelength * 2) + "px"
  // propsNew.style.borderColor = 'red'
  // propsNew.style.marginLeft = "50px !important"
  // propsNew.style.marginRight = "50px !important"
  // propsNew.style.marginBottom = "50px !important"
  // margin: !smallerEnd && '1em', 

  return <div {...propsNew} onClick={(ev) => {
    // alert("test1")
  }}>
      <div style={{whiteSpace: 'nowrap', margin: isMiddle ? '1em' : '0.25em', display: 'flex', alignItems: 'center'}} onClick={(ev) => {
          // alert("test2")
        }}>
        {/* <Icon onClick={(ev) => {
          // alert("test3")
        }}>edit</Icon> */}
        <span>{valueNEW}</span>
      </div>
    </div>
}

export default function SliderCustom(props) {
  const {
    style, defaultValue,
    min, max,
    step, marks: marksInput,
    setValue, value, valueInitial,
    unit, delay = 0,
    bottom, top,
    extraUnitConversion = 1,
    layerDifference = 0, decimals } = props

    console.log("SliderCustom " + value)

  const isArray = Array.isArray(value)

  const [valueLocal, setValueLocal] = useState((isArray ? value : (value / extraUnitConversion)) || 0)
  const [marks, setMarks] = useState(marksInput)

  const changed = (valueInitial || valueInitial === 0) && JSON.stringify(valueInitial) != JSON.stringify(value)

  useEffect(() => {
      setValueLocal((isArray ? value : (value / extraUnitConversion)) || 0)
  }, [extraUnitConversion])

  useEffect(() => {
    if (JSON.stringify(isArray ? valueLocal : valueLocal.toFixed(decimals)) != JSON.stringify(isArray ? value : (value / extraUnitConversion).toFixed(decimals))) {
      if (!isArray) {
        console.log("value changed " + value + ' ' + valueLocal)
        setValueLocal(value / extraUnitConversion);
      } else {
        setValueLocal(value);
        console.log("values changed range")
      }
    }
  }, [value])

  const setValueOutside = (value) => {
    if (setValue) { //&& JSON.stringify(valueLocal) != JSON.stringify(v)
      if (isArray) {
        setValue(value)
      } else {
        setValue(value * extraUnitConversion)
      }
    }
  }

  useEffect(() => {
    calcMarks(valueLocal)
  }, [marksInput, valueLocal, step])

  const checkValueAndSave = (newValue) => {
    if (isArray && newValue[0]) {
      if (bottom && newValue[0] < (bottom + layerDifference)) {
        setValueOutside([(bottom + layerDifference), newValue[1]])
        return
      }
      if (top && newValue[1] > (top - layerDifference)) {
        setValueOutside([newValue[0], (top - layerDifference)])
        return
      }
    } else {
      if (bottom && newValue < (bottom + layerDifference)) {
        setValueOutside(bottom + layerDifference)
        return
      }
      if (top && newValue > top) {
        setValueOutside(top - layerDifference)
        return
      }
    }

    if (delay == 0) {
      console.log("newValue changed " + newValue + ' ' + value)
      setValueOutside(newValue);
    } else {
      if (isArray) {
        setValue(newValue)
      } else {
        setValue(newValue * extraUnitConversion)
      }
    }

    calcMarks(newValue)
  }

  const calcMarks = (newValue) => {
    if (marksInput) {
      // const diff = max-min
      const markStep = marksInput[1].value - marksInput[0].value //diff / marksInput.length
      const stepDivider = marksInput.length > 6 ? 2 : 3

      const nearby = marksInput.findIndex(m => (m.value >= newValue && (m.value - newValue) < (markStep / stepDivider))
        || (newValue >= m.value && (newValue - m.value) < (markStep / stepDivider)))
      if (nearby > -1) {
        let newMarks = [...marksInput]
        newMarks.splice(nearby, 1)
        setMarks(newMarks)
      } else {
        setMarks(marksInput)
      }
    }
  }

  console.log('sliderCustom')

  // const length1 = valueLocal.toString().length
  // const length2 = (unit || "").length

  return <IOSSlider
    style={{ ...style }}
    value={isArray ? valueLocal : valueLocal.toFixed(decimals)}
    changed={changed.toString()}
    // valuelength={length}

    ThumbComponent={CustomThumbComponent}

    onChange={(event, newValue) => {
      checkValueAndSave(newValue)
    }}
    defaultValue={defaultValue}

    // scale={exponential && ((x) => (Math.pow(x, 1.1) ))}

    // valueLabelDisplay="auto"
    // aria-labelledby="range-slider"
    aria-label={unit}
    aria-labelledby={decimals && decimals.toString()}
    aria-valuetext={isArray && isArray.toString()}

    // valueLabelFormat={(v) => {
    //   let vv = (Math.round(v * 100) / 100).toFixed(decimals);
    //   return vv + (percentage >= 6 ? ((" " + unit) || "") : '')
    // }}
    // getAriaValueText={(v) => { return v + (unit || "") }}

    valueLabelDisplay="on"
    step={step || 1}
    marks
    min={min || 0}
    max={max || 100}
    marks={marks}
  />
}