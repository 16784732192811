

export function getPart(text, length) {
    if(!text) {
        debugger
    }
    if(text.length > length) {
        text = text.substr(0, (length - 1)) + "..."
    }
    return text
}