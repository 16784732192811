import React from 'react'
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Icon
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    appbar: {
        // background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.dark1} 100%)`
        backgroundImage: "url('assets/images/backgrounds/loadingBackground.svg')", 
        backgroundSize: "cover"
    },
}));

function AppbarSimple(props) {
    const { title, onHandleClose, style, background, color } = props
    const classes = useStyles(props)
    return <AppBar position="static" classes={{ root: classes.appbar }} style={{...style, background: background, color: color}}>
        <Toolbar className="flex w-full justify-between">
            <Typography variant="subtitle1" color="inherit">
                {title}
            </Typography>

            <IconButton variant="contained" style={{ color: 'white' }} onClick={onHandleClose}>
                <Icon className='mx-4'>close</Icon>
                {/* <Typography>{globalln.close[language]}</Typography> */}
            </IconButton>
        </Toolbar>
    </AppBar>
}
export default AppbarSimple