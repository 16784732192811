import { useContext } from 'react';
import AppContext from 'app/AppContext';

export default function useListener(key) {
  const { state, dispatch } = useContext(AppContext);

  let done = false
  
  if(Array.isArray(key)) {
    const values = []
    key.forEach(k => {
      const val = state.listener && state.listener[k]
      if(val) {
        values.push(val)
      }
    });
    done = values.length === key.length
  } else {
    const value = state.listener && state.listener[key]
    done = !!value
  }

  const trigger = () => {
    dispatch({ type: 'CHANGE_LISTENER_VALUE', data: { key, value: true} });
  }

  const reset = () => {
    dispatch({ type: 'RESET_LISTENER_VALUE', data: { key } });
  }

  return { done, trigger, reset }
}