import React, { useEffect } from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles, useTheme } from '@material-ui/styles';
import Chart from 'chart.js';
// import GaugeChart from 'react-advanced-gauge-chart'
import Gauge from 'svg-gauge'

Chart.defaults.RoundedDoughnut = Chart.helpers.clone(Chart.defaults.doughnut);
Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
    draw: function (ease) {
        var ctx = this.chart.chart.ctx;

        var easingDecimal = ease || 1;
        Chart.helpers.each(this.getMeta().data, function (arc) {
            arc.transition(easingDecimal).draw();

            var vm = arc._view;
            var radius = (vm.outerRadius + vm.innerRadius) / 2;
            var thickness = (vm.outerRadius - vm.innerRadius) / 2;
            var angle = Math.PI - vm.endAngle - Math.PI / 2;

            ctx.save();

            var grd = ctx.createLinearGradient(0, 0, 170, 0);
            grd.addColorStop(0, "red");
            grd.addColorStop(1, "green");

            ctx.fillStyle = vm.backgroundColor;

            ctx.translate(vm.x, vm.y);
            ctx.beginPath();
            ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
            ctx.arc(radius * Math.sin(Math.PI), radius * Math.cos(Math.PI), thickness, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.restore();
        });
    },
});

const useStyles = makeStyles((theme) => ({
    valueDialClass: {
        strokeWidth: 20
  },
  dialClass: {
      strokeWidth: 15
  }
}));

// https://codepen.io/naikus/pen/BzkoLL
// https://github.com/naikus/svg-gauge
export default function BulletGraph(props) {
    const { percentage = 1, idKey, width=50 } = props;
    const theme = useTheme()

    const classes = useStyles();

    const id = "gauge_" + idKey

    useEffect(() => {
        const gauge1 = Gauge(
            document.getElementById(id),
            {
              max: 100,
              dialStartAngle: 0,
              dialEndAngle: 180,
              showValue: false,
              value: 0,
              valueDialClass: classes.valueDialClass,
              dialClass: classes.dialClass,
              label: function(value) {
                return Math.round(value) + "/" + this.max;
              },
              color: function(value) {
                if(value < 20) {
                  return "#ef4655";
                } else if(value < 40) {
                  return "#f7aa38";
                } else if(value < 60) {
                  return "#fffa50";
                } else if(value < 80) {
                  return theme.palette.primary.dark1
                } else {
                  return "#5ee432";
                }
              }
            }
          );

        gauge1.setValueAnimated(percentage, 2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div id={id} style={{width: width, height: (width/2)}}></div>
}

// export function BulletGraph3(props) {
//     const { percentage = 1 } = props;

//     return <GaugeChart id="gauge-chart2"
//         percent={percentage / 100} textColor="black" nrOfLevels={20}
//         colors={['red', 'blue', 'green']}
//         // previousValue={0.75}
//     />
// }

export function BulletGraph2(props) {
    const { percentage = 1 } = props;

    // const background = `linear-gradient(90deg, green 0%, red 50%)`

    useEffect(() => {
      new Chart('usersChart', {
            type   : 'RoundedDoughnut',
            data   : {
                datasets: [
                    {
                        data           : [percentage, 100 - percentage],
                        backgroundColor: [
                            'green',
                            'transparent'
                        ],
                        borderWidth    : 0
                    }]
            },
            options: {
                cutoutPercentage: 80
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div style={{width: 30, height: 30}}>
        <canvas id="usersChart" width={10} height={10}></canvas>
    </div>
}