import React, { useEffect, useState } from "react";
import { Button, Paper } from '@material-ui/core';
import FuseThemesConfig from 'app/fuse-configs/themesConfig';

export default function usePWA() {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    console.log("inside usePWA");

    useEffect(() => {
        const handler = e => {
            e.preventDefault();
            debugger
            console.log("we are being triggered :D");
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, [])

    const onClick = evt => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };

    const pwaButton = supportsPWA ?
        <Paper style={{padding: '2em', marginTop: '10vh'}}>
            <h3>OR</h3>
            <Button size="small"
                style={{
                    marginTop: '1vh',
                    padding: '1em 2em',
                    fontSize: '1em',
                    backgroundColor: FuseThemesConfig.playbook.palette.secondary.main,
                    color: 'white'
                }}
                onClick={onClick}>Install Audily on your pc</Button>
        </Paper>
        : null;

    return { supportsPWA, pwaButton }
};