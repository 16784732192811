import React from 'react';
//import { FusePageSimple } from '@fuse';
import {
  Card,
  Typography,
  IconButton,
  Badge,
  Tooltip
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { FuseAnimate } from "@fuse";
import { useQuery } from '@apollo/client';
// import { questionInsights } from 'app/apollo/questions/queries';
import { questionAnalytics } from 'app/apollo/questions/queries';
import clsx from "clsx";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import {Spinner} from "components"
import { GetTypeShort } from 'app/tools/typeServices';

import OptionsCharts from './Charts/OptionsCharts'
import DateTimeCharts from './Charts/DateTimeCharts'
import TimeCharts from './Charts/TimeCharts'
import NumberCharts from './Charts/NumberCharts'
import NumberRangeCharts from './Charts/NumberRangeCharts'
import YesNoCharts from './Charts/YesNoCharts'
import TextCharts from './Charts/TextCharts'
import QuestionHubAnswers from 'components/Modules/Answers/QuestionHubAnswers'
import useDialog from 'components/Hooks/useDialog';

//#region StyleBadge
const StyledBadge = withStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    position: 'absolute',
    zIndex: 0
  },
}))(Badge);
//#endregion

//#region UseStyles
const useStyles = makeStyles(() => ({
  paperBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  headerContainer: {
    color: 'white',
    fontSize: 17,
    display: 'flex',
    flexDirection: 'row',
    height: 26
  },
  headerTitle: {
    padding: '0.2em 0.5em 0 0.65em',
    backgroundColor: '#003646',
    fontWeight: 500,
  },
  headerTriangle: {
    borderColor: "transparent transparent transparent #003646",
    borderStyle: "solid",
    borderWidth: "30px 0 0 30px;",
    height: "0px",
    width: "0px",
  },
  container: {
    padding: '1em',
    borderTop: "2px solid #003646",
    height: '100%',
    backgroundColor: '#1D2124',
    color: '#F2F2F2',
    borderRadius: '0px 0px 3px 3px',
    fontSize: 12,
  },
  paper: {
    width: '22em',
    margin: '0.75em'
  }
}));
//#endregion

function CustomChart(props) {
  const { dataType, analytics } = props

  if(!analytics) {
    return null
  }
  switch (dataType) {
    case "OPTIONS_RADIO":
    case "OPTIONS_COMBO":
    case "OPTIONS_CHECKBOX":
      return <OptionsCharts {...props} />
    case "NUMBER_SLIDER":
    case "NUMBER_INT":
      return <NumberCharts {...props} />
    case "TIME":
      return <TimeCharts {...props} />
    case "DATE":
    case "DATETIME":
      return <DateTimeCharts {...props} />
    case "NUMBER_RANGE_SLIDER":
    case "NUMBER_RANGE":
      return <NumberRangeCharts {...props} />
    case "BOOL_CHECKBOX":
    case "BOOL_SWITCH":
      return <YesNoCharts {...props} />
    case "TEXTAREA":
    case "INPUT":
      return <TextCharts {...props} />
    default:
      return null
  }
}

function QuestionAnalytics(props) {
  const classes = useStyles(props);
  const {questionId, dataType, placeholder, title, nocardTitle, setFilters, companyDialogIdsFiltered, filters} = props

  const addFilter = (data) => {
    const newFilters = [...filters]
    newFilters.push({...data, questionId: questionId, dataType: dataType, title: title })
    setFilters(newFilters)
  }

  const { data: dataAnalytics, error } = useQuery(questionAnalytics, { variables: { questionId: questionId, dataType: dataType, filterCompanyDialogs: companyDialogIdsFiltered }, fetchPolicy: "cache-and-network" })
  console.log(props)
  console.log(error)
  console.log(dataAnalytics)
  const analytics = dataAnalytics && dataAnalytics.questionQ.questionAnalytics;
  // const variateDescSize = true;
  // const showDescr = false//variateDescSize && question && question.description && question.description !== "";

  const { setShowDialog: setShowAnswersDialog, CD: CD_Answers } = useDialog({
      title: title,
      content: <QuestionHubAnswers questionId={questionId} />
  })

  return (
    <FuseAnimate animation="transition.fadeIn" duration={400} delay={100}>
      <Card
        className={clsx(
          "cursor-pointer",
          classes.paper,
          props.className,
          // analytics && question.isSubQuestion && classes.subquestion
        )}
        // onClick={() => history.push(`/questions/all/${question.id}`)}
      >
        <div
          style={{
            // display: "grid",
            // gridTemplateRows: "2fr 1fr 1fr 1fr auto 2px",
            // gridAutoFlow: "column",
            // gridTemplateColumns: "1fr auto",
            height: "22em",
            position: 'relative'
          }}
        >
          <div>
            <Typography
                className="pl-16 mt-6 text-12 text-gray-400"
              >
                {(placeholder && placeholder.length) > 0 ? placeholder : GetTypeShort(dataType)}
            </Typography>

            {title && !nocardTitle && <div><Typography
                className={clsx("pl-16 text-12 font-bold")}
                onClick={() => {
                  setShowAnswersDialog(true)
                }}
              >
                {title}
            </Typography></div>}
          </div>
          
          {/* 
          {showDescr && (
            <Typography
              className="py-8 px-16"
              component="div"
              style={{ gridColumn: "1 / 3" }} //gridRow: 2,
            >
              <div className={clsx("w-full break-words")}>
                {question && question.description}
              </div>
            </Typography>
          )} */}

          {!analytics && <Spinner text="Loading graph" style={{height: '20em', display: 'flex', alignItems: 'center' , justifyContent: 'center'}} />}
       
          {analytics && analytics.answerCount > 0 && <Typography
            component="div"
            style={{ position: 'absolute', right: 3, top: -2 }}
            onClick={() => {
              setShowAnswersDialog(true)
            }}
          >
                <Tooltip title={analytics.answerCount + ' antwoorden'}>
                  <IconButton
                    aria-label="cart"
                    onClick={() => {
                      setShowAnswersDialog(true)
                    }}
                    style={{ marginTop: 0, fontSize: "0.9em" }}
                  >
                    <StyledBadge
                      //badgeContent={question.answers.length}
                      badgeContent={analytics.answerCount}
                      max={99}
                      color="secondary"
                    >
                      <QuestionAnswerIcon />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>

              {/* <MenuVerticaldot 
                    question={question}
                    playbook={props.playbook}
                  /> */}
          </Typography>}

          <div style={{height: '19em', display: 'flex', alignItems:'center', width: '100%' }}>
            <div style={{height: '13em', width: '100%' }}>
              <CustomChart dataType={dataType} analytics={analytics} addFilter={addFilter} />
            </div>
          </div>
          
          {/* {question && (question.labels && question.labels.length > 0 || question.reminder) && (
            <div
              className="py-8 px-16 flex flex-wrap w-full"
              style={{ gridColumn: "1 / 3", gridRow: 5 }}
            >
            </div>
          )} */}
        </div>

        {CD_Answers}
      </Card>
    </FuseAnimate>
  );
}

export default QuestionAnalytics