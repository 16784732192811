import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'inline-flex',
        flexDirection: 'column',
        width: 'auto',
        background: theme.palette.primary.main,
        padding: '0 7px 0 7px',
        borderRadius: 5,
        cursor: 'pointer',
        fontFamily: 'Andale Mono,AndaleMono,monospace',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    containerDisabled: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    },
    titleContainer: {
        fontSize: 18,
        fontWeight: 800,
        height: 42,
        lineHeight: "17px",
        textAlign: 'center',
        color: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    smallContainer: {
        height: 32,
    },
    lineContainer: {
        height: 6.5,
        width: 'calc(100%)',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '10% 10% 0% 0% / 100% 100% 0% 0%'
        // borderLeft: "40% solid #18C533",
        // borderTop: "15% solid red",
        // borderBottom: "15% solid red",
    }
}));

function SwitchButton(props) {
    const classes = useStyles(props);
    const theme = useTheme()
    const { 
        line1, line2,
        // activeColor,
        isFirst, isLast, isMiddle,
        isActive, setIsActive, 
        disabled,
        style, width,
        vertical, big, icon, horizontalPadding,
        isSwitch = true,
        onClick,
        smallHeight,
        color, line2Color
    } = props;

    const noBorders = isMiddle
    let noBorderTopLeftRadius = noBorders
    let noBorderBottomLeftRadius = noBorders
    let noBorderTopRightRadius = noBorders
    let noBorderBottomRightRadius = noBorders

    if (noBorders) {
        noBorderTopLeftRadius = noBorderBottomLeftRadius = noBorderTopRightRadius = noBorderBottomRightRadius = true
    } else if (!vertical) {
        noBorderTopLeftRadius = isLast
        noBorderBottomLeftRadius = isLast
        noBorderTopRightRadius = isFirst
        noBorderBottomRightRadius = isFirst
    } else {
        noBorderTopLeftRadius = isLast
        noBorderBottomLeftRadius = isFirst
        noBorderTopRightRadius = isLast
        noBorderBottomRightRadius = isFirst
    }

    const borderRadiusGroup = "0px"
    const marginGroup = "1.5px"

    let styleMerged = {
        borderTopLeftRadius: noBorderTopLeftRadius && borderRadiusGroup,
        borderBottomLeftRadius: noBorderBottomLeftRadius && borderRadiusGroup,
        borderTopRightRadius: noBorderTopRightRadius && borderRadiusGroup,
        borderBottomRightRadius: noBorderBottomRightRadius && borderRadiusGroup,
        marginRight: isFirst && !vertical && marginGroup,
        marginLeft: isLast && !vertical && marginGroup,
        marginBottom: isFirst && vertical && marginGroup,
        marginTop: isLast && vertical && marginGroup,
        opacity: disabled ? 0.55 : 1,
        cursor: disabled ? 'auto' : 'pointer',
        backgroundColor: color
    }
    if(isMiddle) {
        styleMerged.margin = vertical ? marginGroup + ' 0px' : '0px ' + marginGroup
    }
    if(!isSwitch && isActive) {
        styleMerged.backgroundColor = theme.palette.extra.buttonNormalHover
    }
    if(width) {
        styleMerged.width = width
    }
    styleMerged = {...styleMerged, ...style}

    // console.log(styleMerged)

    return <div className={clsx(classes.container, disabled && classes.containerDisabled)}
        style={styleMerged}
        onClick={() => {
            if(!disabled) {
                onClick && onClick()
                setIsActive && setIsActive(!isActive)
            }
        }}>
        <div className={clsx(classes.titleContainer, smallHeight && classes.smallContainer)}
        style={{height : big && '65px',
            lineHeight: big && '25px',
            paddingLeft: horizontalPadding,
            paddingRight: horizontalPadding}}>
            {icon && <Icon style={{width: '100%', textAlign: 'center', marginTop: "0.5em"}}>{icon}</Icon>}
            {line1 && <span>{line1}</span>}
            {line2 && <span style={{color: line2Color}}>{line2}</span>}
        </div>
        {isSwitch && <div className={classes.lineContainer} style={{backgroundColor: isActive ? theme.palette.secondary.light : theme.palette.secondary.lightest }}></div>}
    </div>
}

export default SwitchButton