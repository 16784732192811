export default {
  view: {
    en: 'View',
    fr: 'Voir',
    nl: 'Bekijk'
  },
  allquestions: {
    en: 'all Questions',
    fr: 'toutes les questions',
    nl: 'alle vragen'
  },
  newplaybook: {
    en: 'New Template',
    fr: 'Nouveau Template',
    nl: 'Nieuwe Template'
  },
  templates: {
    en: 'Templates',
    fr: 'Templates',
    nl: 'Templates'
  },
  therearenoplaybooks: {
    en: 'There are no templates',
    fr: "Il n'y a pas de templates",
    nl: 'Er zijn geen templates'
  },
  therearenoQuestions: {
    en: 'There are no questions created yet',
    fr: "Il n'y a pas encore de questions",
    nl: 'There is no script yet'
  },
  noQuestionsFound: {
    en: 'No questions found',
    fr: 'No questions found',
    nl: 'Geen vragen gevonden'
  },
  create: {
    en: 'Create',
    fr: 'Creër',
    nl: 'Aanmaken'
  },
  goToQuestions: {
    en: 'Go to questions',
    fr: 'Go to questions',
    nl: 'Ga naar vragen'
  },
  questions: {
    en: 'Questions',
    fr: 'Questions',
    nl: 'Vragen'
  },
  title: {
    en: 'Title',
    fr: 'Titre',
    nl: 'Titel'
  },
  description: {
    en: 'Description',
    fr: 'Description',
    nl: 'Omschrijving'
  },
}