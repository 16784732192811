import React, { useState, useEffect, useRef } from 'react'
import Slider from 'components/Controls/SliderCustom/Slider/Slider'
import TextAmount from 'components/Controls/SliderCustom/TextAmount/TextAmount'
import { makeStyles } from '@material-ui/styles';
import { Menu, MenuItem } from '@material-ui/core';
import FuseThemesConfig from 'app/fuse-configs/themesConfig';

window.palette = FuseThemesConfig.playbook.palette

const useStyles = makeStyles(theme => ({
  root: {
    background: window.palette.primary.darkest + ' !important',
    width: '7em',
    border: '2px solid ' + window.palette.primary.main,
    color: 'white'
  },
  list: {
    color: 'white'
  }
}))

const Label = (props) => {
  const { changed, extraUnit, children, mr, handleClick, isRight } = props
  return <div
      onClick={handleClick}
      style={{
        height: 37,
        width: 120,
        textAlign: 'center',
        borderRadius: 5,
        backgroundColor: window.palette.primary.light,
        border: '2px solid ' + (changed ? window.palette.extra.changed : window.palette.primary.main),
        fontSize: '1.4em',
        color: changed ? window.palette.extra.changed : 'black',
        marginRight: !isRight && mr + 'em',
        marginLeft: isRight && mr + 'em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: extraUnit && 'pointer',
      }}>
      {children}
    </div>
}

export default function SliderWithLabel(props) {
  const { value, setValue, valueInitial, mr = 2, label, fromLabel, toLabel,
     labelWidth = '8em', amountWidth = '10em', styleRoot,
     unit, extraUnit, setUnit, extraUnitConversion = 1, decimals = 0, max, min } = props

  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [unitChosen, setUnitChosen] = useState(unit);

  const isRange = Array.isArray(value)

  const changedV = (valueInitial || valueInitial === 0) && valueInitial != value
  const changed = isRange ? (valueInitial[0] != value[0] || valueInitial[1] != value[1]) : changedV

  const refSetValue = useRef(setValue);
  useEffect(() => {
    refSetValue.current = setValue
  }, [setValue])

  const handleClick = (event) => {
    if(extraUnit && event) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseUnit = (v) => {
    setUnitChosen(unit)
    setUnit && setUnit(unit)
    setAnchorEl(null);
  }
  const handleCloseExtra = (v) => {
    setUnitChosen(extraUnit)
    setUnit && setUnit(extraUnit)
    setAnchorEl(null);
  }
  const handleClose = (v) => {
    setAnchorEl(null);
  }

  const val1 = isRange ? value[0] : value
  const val2 = isRange ? value[1] : value
  // const UnitLabel = () => <span style={{textDecoration: extraUnit && "underline", marginLeft: '0.3em' }}>
  //   {unitChosen}
  // </span>

  const sliderMemo = React.useMemo(
    () => <Slider {...props} setValue={(v) => refSetValue.current(v)}/>,
    [val1, val2, changed, unit]
  );

  const textAmountMemo = React.useMemo(
    () => <TextAmount {...props} min={min} clickUnit={extraUnit && handleClick}
      // max={isRange ? value[1] : max}
      max={max} amountWidth='1em'
      sliderlabel={true} title={fromLabel} titlePlacement="top" unit={unit}
      style={{marginRight: '2em', backgroundColor: 'white'}} amountWidth= {amountWidth}
      value={(isRange ? value[0] : value) / extraUnitConversion}
      valueInitial={valueInitial}
      setValue={(v) => {
        refSetValue.current(isRange ? [v, value[1]] : v * extraUnitConversion)
      }}
      changed={changed}
      beforeDecimalPlaces={(isRange ? value[1] : max) ? (isRange ? value[1] : max).toFixed(0).toString().length: 0}
      decimalPlaces={decimals} />,
    [val1, val2, changed, unit]
  );

  return <div style={{ display: 'flex', alignItems: 'center', ...styleRoot, width: '100%' }}>
    {label && <div style={{ color: 'black', marginRight: '1em', fontSize: '1em', textAlign: 'right', width: labelWidth, lineHeight: '1em' }}>
      {label}
    </div>}

    {/* <SNButton cta style={{margin: '3px 2px 4px 0px'}}>-</SNButton>
    <SNButton cta style={{margin: '3px 2px 4px 0px'}}>+</SNButton> */}

    {textAmountMemo}

    {/* {!isRange && <Label handleClick={handleClick} changed={changed} mr={mr} extraUnit={extraUnit}>
      {((isRange ? value[0] : value) / extraUnitConversion).toFixed(decimals)}
      <UnitLabel />
    </Label>} */}

    {!isRange && extraUnit && <Menu
      classes={{ paper: classes.root, list: classes.list }} 
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleCloseUnit}>{unit}</MenuItem>
      <MenuItem onClick={handleCloseExtra}>{extraUnit}</MenuItem>
    </Menu>}

    {/* <Slider {...props} /> */}
    {sliderMemo}

    {isRange && <TextAmount {...props} min={value[0]} sliderlabel={true} title={toLabel} titlePlacement="top" unit={unit}
      style={{marginLeft: '2em', backgroundColor: 'black'}} amountWidth= {amountWidth}
        value={value[1]} setValue={(v) => {
          setValue([value[0], v])
        }}
        changed={valueInitial[1] != value[1]} beforeDecimalPlaces={max.toString().length} decimalPlaces={decimals} />}
    </div>
}
