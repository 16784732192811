import React, { useContext, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import { DefaultNodeModel } from './DefaultNodeModel'
import { DefaultPortLabel } from '../port/DefaultPortLabelWidget'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import AppContext from 'app/AppContext'
import LabelChipContainer from 'components/LabelChipContainer/LabelChipContainer'
import { IconButton, Icon, Tooltip } from '@material-ui/core'
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles'
import FuseThemesConfig from 'app/fuse-configs/themesConfig'
import { useDispatch, useSelector } from 'react-redux'
import createPortsFromDataType from '../../../Helpers/createPortsFromDataType'
import { CREATE_QUESTION } from 'app/apollo/questions/mutations'
import { useMutation, useLazyQuery } from '@apollo/client'
import { questionHubForDiagramUpdate } from 'app/apollo/questionHubs/queries'
// import { apolloClient } from 'app/apollo/initApollo'
import store from '../../../../../../store/index'

import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'
import widgetsReducer from 'app/main/dashboard/store/reducers/widgets.reducer'

// import {store} from '../../../../../../../../src/index.js'

// opacity: ${(p) => (p.isHoverOn && !p.isHover ? 0.3 : 1)};
const palette = FuseThemesConfig.playbook.palette

export const Node = styled.div<{
    selected: boolean
    isHover: Boolean
    isHoverOn: Boolean
    border: string
    hoverColor: string
    normalColor: string
}>`
    position: relative;
    border: solid 1px ${(p) => (p.selected ? p.hoverColor : p.border)};
    border-radius: 5px;
    font-family: sans-serif;
    color: ${(p) => (p.selected ? p.hoverColor : 'black')};
    background-color: white;
    overflow: visible;
    font-size: 11px;
    padding-bottom: 8px;
    zindex: ${(p) => p.isHoverOn && p.isHover && 1000};
    box-shadow: ${(p) =>
        p.selected
            ? '0 3px 8px 0 ' + p.hoverColor + ', 0 0 0 1px ' + p.hoverColor
            : '0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%)'};
    &:hover {
        border: solid 1px ${(p) => p.hoverColor};
        box-shadow: 0 3px 8px 0 ${(p) => p.hoverColor}, 0 0 0 1px ${(p) => p.hoverColor};
        color: ${(p) => p.hoverColor};
    }
    &:hover > div > :first-of-type {
        background: ${(p) => p.normalColor} !important;
    }
    &:hover > div > :first-of-type > div {
        color: ${(p) => p.hoverColor} !important;
    }
    .bottomPortVisible {
        opacity: ${(p) => p.selected && '1 !important'};
    }
    &:hover .bottomPortVisible {
        opacity: 1 !important;
    }
    cursor: pointer;
`

export const StartNode = styled.div<{
    background: string
    selected: boolean
    isHover: Boolean
    isHoverOn: Boolean
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${palette.secondary.darkest};
    border-radius: 5px;
    font-family: sans-serif;
    color: white !important;
    border: solid 2px black;
    overflow: visible;
    font-size: 11px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    zindex: ${(p) => p.isHoverOn && p.isHover && 1000};
`
// transform: ${(p) => p.isHoverOn && p.isHover && 'scale(1.3)'};

export const Title = styled.div<{ isHover: Boolean; isHoverOn: Boolean; noBackground: Boolean; background: string }>`
    background: ${(p) => p.background};
    display: flex;
    white-space: nowrap;
    justify-items: center;
    border-radius: 4px 4px 0 0;
    cursor: grab;
`

export const TitleName = styled.div`
    flex-grow: 1;
    padding: 5px 5px;
    margin-right: 20px;
`

// display: flex;
// export const Ports = styled.div`
//     color: black;
// `
// background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));

export const PortsContainer = styled.div<{ isOut: boolean; hide: boolean }>`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: ${(p) => p.isOut && !p.hide && '3px'};
    margin-bottom: ${(p) => p.isOut && !p.hide && '2px'};
    color: black;

    &:hover {
        background: ${(p) => p.isOut && '#ffffff26'};
    }

    &:first-of-type {
        margin-right: 10px;
    }

    &:only-child {
        margin-right: 0px;
    }
`

export interface DefaultNodeProps {
    node: DefaultNodeModel
    engine: DiagramEngine
}

/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export function DefaultNodeWidget(props) {
    const { node, engine } = props

    const { state } = useContext(AppContext)
    const dispatch = useDispatch()
    const theme: any = useTheme()
    const [isEntered, setIsEntered] = useState(false)
    const [dropStyle, setDropStyle] = useState(undefined)
    const [nodeToUpdate, setNodeToUpdate] = useState(undefined)
    const [newQuestionId, setNewQuestionId] = useState(undefined)
    const [optionsGroups, setOptionsGroups] = useState([])

    const [createQuestion] = useMutation(CREATE_QUESTION)
    const [getQuestionHub, { data: dataQuestionHub, loading }] = useLazyQuery(questionHubForDiagramUpdate, {fetchPolicy: 'network-only'})

    const generatePort = (port) => {
        if(!port) {
            return null
        }
        return <DefaultPortLabel engine={engine} port={port} key={port.getID()} />
    }

    const options = node.getOptions()
    const { name, questionHub, importance, loadingExtern } = options
    const questions = node?.options?.questionHub?.questions
    const outPorts = node?.getOutPorts()

    const questionHubId = questionHub && questionHub.id

    const isSelected = node.isSelected()

    const getOptionsGroups = () => {
        let optionsGroupsTmp = []
        let groups = []
        // Gather all option question id's in a list
        questionHub?.questions?.forEach((question) => {
            const isOptions = question.dataType.indexOf('OPTIONS') > -1
            if (isOptions){
                optionsGroupsTmp.push(question.id)
            }
        })
        // Create an object for each question containing questionId, name and all ports that belong to that group
        const nodes = engine.getModel().getNodes()
        optionsGroupsTmp.forEach((id) => {
            const group = {id: id, name: undefined, ports: []}
            nodes.forEach((node) => {
                const outPorts = node.getOutPorts()
                outPorts.forEach((port) => {
                    if (port.questionId == id && port.options.name != "bottomOUT"){
                        group.ports.push(port)
                        group.name = port?.question?.placeholder
                    }
                })
            })
            // Add all groups to one list
            if(group){
                if(!group.name) {
                    const q = questionHub.questions.find(q => q.id == id)
                    group.name = q.placeholder
                }

                groups.push(group)
            }
        })
        if (groups){
            setOptionsGroups(groups)
            // console.log({groups})
        }
    }

    // const importance = questionHub && questionHub.importance
    const prioColor =
        importance && importance == 3
            ? theme.palette.prio.veryImportant
            : importance == 2
            ? theme.palette.prio.important
            : importance == 1
            ? theme.palette.prio.normal
            : theme.palette.prio.nothing

    const showPorts = (node) => {
        const ports = node.portsOut
        for (let port of ports) {
            port.options.hover = !isEntered
        }
    }

    const onNodeDrop = (event) => {
        debugger
        setDropStyle(undefined)
        const name = event.dataTransfer.getData('name')
        const dataType = event.dataTransfer.getData('dataType')
        const icon = event.dataTransfer.getData('icon')
        // const questionHub = node.options.questionHub

        // console.log({ node })
        // createPortsFromDataType({...questionHub, questions: []}, node)
        createQuestion({
            variables: {
                questionHubId: questionHub.id,
                icon: icon,
                dataTypeString: dataType,
                placeholder: name
            },
        }).then((res) => {
            console.log({ res })
            // const isOptions = dataType?.indexOf('OPTIONS') > -1
            setNewQuestionId(res.data.questionM.createQuestion.id)
            setNodeToUpdate(node)
            getQuestionHub({ variables: { id: questionHub.id } })
        })
    }

    useEffect(() => {
        if (dataQuestionHub && nodeToUpdate) {
            debugger
            const questionHubNEW = dataQuestionHub.questionHubQ.questionHub

            const qNEW = questionHubNEW.questions.find(q => q.id == newQuestionId)  
            const questionHubTEMP = {...questionHubNEW, questions: [qNEW]}
            questionHub.questions = questionHubNEW.questions
            createPortsFromDataType(questionHubTEMP, nodeToUpdate, true, null)

            nodeToUpdate.options.questionHub.questions = questionHub.questions
            setNodeToUpdate(undefined)
        }
    }, [dataQuestionHub])

    const portLabelss = outPorts && outPorts.length
    useEffect(() => {
        getOptionsGroups()
    },[questions, isSelected, portLabelss])

    if (node.portsIn.length == 0) {
        return (
            <StartNode
                data-default-node-name={name}
                selected={isSelected}
                isHover={questionHubId && state.diagramHoverId && state.diagramHoverId == questionHubId}
                isHoverOn={state.diagramHoverId}
                background={prioColor}
                onMouseEnter={() => {
                    setIsEntered(true)
                    showPorts(node)
                }}
                onMouseLeave={() => {
                    setIsEntered(false)
                    showPorts(node)
                }}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <Title
                        isHover={questionHubId != null && state.diagramHoverId == questionHubId}
                        isHoverOn={state.diagramHoverId}
                        noBackground={!questionHub}
                        background={undefined}
                    >
                        <TitleName style={{ marginRight: 0 }}>{name}</TitleName>
                    </Title>
                    <div>
                        <PortsContainer hide={!isSelected} isOut={true} onClick={() => {}}>
                            {_.map(node.getOutPorts(), generatePort)}
                        </PortsContainer>
                    </div>
                </div>
            </StartNode>
        )
    } else {
        const portsOut = node.getOutPorts()
        const groups = _.groupBy(portsOut, (p) => p.options.id)
        const groupsArray = Object.keys(groups)
        const realColor = importance > 0 ? prioColor : theme.palette.primary.main
        const hoverColor = importance > 0 ? prioColor :  theme.palette.primary.main

        return (
            <Node
                data-default-node-name={name}
                selected={isSelected}
                isHover={questionHubId && state.diagramHoverId && state.diagramHoverId == questionHubId}
                isHoverOn={state.diagramHoverId}
                border={realColor}
                hoverColor={hoverColor}
                normalColor={theme.palette.grey.light}
                style={{ boxShadow: dropStyle }}
                key={questionHubId}
                // onClick={() => {
                //   dispatch({
                //     type: 'CONTEXT',
                //     payload: { context: node },
                //   });
                // }}
                onMouseEnter={() => {
                    setIsEntered(true)
                    showPorts(node)
                }}
                onMouseLeave={() => {
                    setIsEntered(false)
                    showPorts(node)
                }}
                onDrop={(event) => onNodeDrop(event)}
                onDragOver={(event) => {
                    setDropStyle('0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)')
                    event.preventDefault()
                }}
                onDragLeave={(event) => {
                    setDropStyle(undefined)
                    event.preventDefault()
                }}
                // background={'white'}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <div className="w-full h-auto cursor-pointer" onClick={() => {
                            dispatch({
                                type: 'SELECTED',
                                payload: { selected: node } ,
                            })
                        }}>
                    <Title
                        isHover={questionHubId != null && state.diagramHoverId == questionHubId}
                        isHoverOn={state.diagramHoverId}
                        noBackground={!questionHub} 
                        background={isSelected ? realColor : theme.palette.grey.light}
                        // background={node.isSelected() ? theme.palette.grey.light : realColor}
                    >
                        <TitleName style={{ color: isSelected ? 'white' : (importance > 0 && realColor) }}>{name}</TitleName>
                        {/* <TitleName style={{ color: !node.isSelected() && importance > 0 && 'white' }}>{name}</TitleName> */}
                    </Title>
                    </div>
                    <div  onClick={() => {
                            dispatch({
                                type: 'SELECTED',
                                payload: { selected: "x" },
                            })
                        }}>
                        {!loading && !loadingExtern && questions && questions.length == 1 && <div style={{margin: 15, fontWeight: 500}}
                         onClick={() => {
                            dispatch({
                                type: 'SELECTED',
                                payload: { selected: "x" },
                            })
                        }}>Click to add questions</div>}
                        <PortsContainer hide={!questionHub} isOut={false}>
                            {_.map(node.getInPorts(), generatePort)}
                        </PortsContainer>
                        <PortsContainer hide={!questionHub} isOut={true} onClick={() => {}}>
                            {/* {groupsArray.map((key, index) => {
                                const group = groups[key]
                                if(group.length == 1) {
                                    return generatePort(group[0])
                                } else {
                                    return <div style={{display: 'flex'}}>
                                        {group.map(p => generatePort(p))}
                                    </div>
                                }
                            })} */}
                            {optionsGroups.map((group, index) => {
                                return <div key={index} style={{background: theme.palette.grey.lighter,
                                    borderBottom: '0.5px solid ' + theme.palette.grey.light, padding: '5px 0', marginBottom: 3}} 
                                    onClick={() => {
                                        dispatch({
                                            type: 'SELECTED',
                                            payload: { selected: "x" },
                                        })
                                    }}>
                                    <div className="ml-24 text-bold">
                                        <p>{group.name}</p>
                                        {group.ports.length == 0 && <div>No options</div>}
                                    </div>
                                    <div>{_.map(group.ports, generatePort)}</div>
                                </div>
                            })}
                            {_.map(node.getOutPorts().filter((p)=> !p.idOptionQuestion), generatePort)}
                        </PortsContainer>
                    </div>

                    {/* {questionHub && isEntered && <div
          style={{ position: 'absolute', top: -2, left: -12 }}>
            <LabelChipContainer hideLabels={true} rounded={true} right id={questionHubId} type={"questionHub"} />
        </div>}
        
        {questionHub && isEntered && <div style={{ position: 'absolute', top: 13, left: -38 }}>
          <Tooltip title="Analytics" placement="left">
            <IconButton onClick={() => {
                gotostep(4)
              }}><Icon fontSize="small"
              style={{background: theme.palette.primary.dark1, color: "white", borderRadius: 0}}>assessment</Icon></IconButton>
          </Tooltip>
        </div>} */}

                    {/* {questionHub && <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} style={{ paddingLeft: '2em', paddingRight: '0.43em' }}>
          <LabelChipContainer hideAddToButton={true} right id={questionHubId} type={"questionHub"} />
        </div>} */}
                </div>
            </Node>
        )
    }
}
