import React from 'react';
import { Menu, Icon, ButtonBase } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { CustomMenuItem, Tooltip } from 'components'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        cursor: 'pointer',
    },
    dot: {
        backgroundColor: props => (props.dark && theme.palette.primary.dark) || (props.light && '#fff') || props.color || "#bbb",
        borderRadius: "50%",
        display: "inline-block",
        cursor: 'pointer'
    },
    containerH: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 20,
        width: 20,
        border: '0.5px solid gray',
        borderRadius: 20,
        background: 'white',
        padding: 2,
        '&:hover' : {
            background: theme.palette.grey.lighter,
            '> span' : {
                backgroundColor: 'white !important',
            }
        }
    },
    dotH: {
        height: 3,
        width: 3,
        border: '0.5px solid gray',
        backgroundColor: 'transparent !important'
    },
    containerV: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: 27,
        width: 5
    },
    dotV: {
        height: 5,
        width: 5,
    }
}));

export default function ContextMenu(props) {
    const { menu, onClick, style, icon, title, iconFill, tooltip, borderColor, dashed, horizontal } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    const classes = useStyles(props);

    const ButtonB = <ButtonBase
        focusRipple
        onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
        }}
        style={{
            ...style,
            width: !title && 45,
            minWidth: !title && 45,
            height: !title && 45,
            display: 'flex',
            cursor: 'pointer',
            borderRadius: 100,
            border: borderColor && `1px ${dashed ? 'dashed': 'solid'} ${borderColor}`,
        }}>
        {!icon && <div className={clsx(classes.container, horizontal && classes.containerH, !horizontal && classes.containerV)} >
            <span className={clsx(classes.dot, horizontal && classes.dotH, !horizontal && classes.dotV)}></span>
            <span className={clsx(classes.dot, horizontal && classes.dotH, !horizontal && classes.dotV)}></span>
            <span className={clsx(classes.dot, horizontal && classes.dotH, !horizontal && classes.dotV)}></span>
        </div>}
        {icon && <Icon style={{color: iconFill, cursor: 'pointer'}} >{icon}</Icon>}
        {title && <div style={{marginLeft: '1em', cursor: 'pointer'}}>{title}</div>}
    </ButtonBase>

    return <React.Fragment>
        {tooltip && <Tooltip title={tooltip} placement="top">
            {ButtonB}
        </Tooltip>}

        {!tooltip && ButtonB}

        {menu && <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={(e) => {
                e.stopPropagation()
                setAnchorEl(null)
            }}
        >
            <div>
                {menu.map((item, index) => {
                    if(item) {
                        return <CustomMenuItem item={item} key={index} index={index} onClick={(e) => {
                            e.stopPropagation()
                            setAnchorEl(null)
                            item.onClick && item.onClick(item, index)
                            onClick && onClick(item, index)
                        }} title={item.title} />
                    }
                    return null
                })}
            </div>
        </Menu>}
    </React.Fragment>
}