const navigationConfig = [
    // {
    //     'id'      : 'dashboard',
    //     'title'   : 'Dashboard',
    //     'type'    : 'item',
    //     'icon'    : 'apps',
    //     'url'  : '/dashboard',
    //     'exact' : true
    // },
    {
        'id'      : 'leads',
        'title'   : 'Leads',
        'type'    : 'item',
        'icon'    : 'center_focus_strong',
        'url'  : '/leads',
    },
    // {
    //     'id'      : 'companyDialogs',
    //     'title'   : 'Meetings', //Afspraken
    //     'type'    : 'item',
    //     'icon'    : 'date_range',
    //     'url'  : '/companyDialogs',
    //     'suburl'  : '/companyDialog',
    // },
    // {
    //     'id'      : 'templates',
    //     'title'   : 'Templates',
    //     'type'    : 'item',
    //     'icon'    : 'format_list_numbered',
    //     'url'  : '/templates',
    //     'suburl'  : '/template',
    // },
    // {
    //     'id'      : 'media',
    //     'title'   : 'Media',
    //     'type'    : 'item',
    //     'icon'    : 'perm_media',
    //     'url'  : '/media',
    // },
    // {
    //     'id'    : 'companies',
    //     'title' : 'Companies',
    //     'type'  : 'item',
    //     'icon'  : 'business',
    //     'url'   : '/companies',
    //     'suburl'  : '/company',
    // },
    {
        'id'    : 'accounts',
        'title' : 'Accounts',
        'type'  : 'item',
        'icon'  : 'business',
        'url'   : '/accounts',
        'suburl'  : '/account',
    },
    {
        'id'    : 'contacts',
        'title' : 'Contacts',
        'type'  : 'item',
        'icon'  : 'perm_contact_calendar',
        'url'   : '/contacts',
        'suburl'  : '/contact',
    },
    // {
    //     'id'      : 'opportunities',
    //     'title'   : 'opportunities',
    //     'type'    : 'item',
    //     'icon'    : 'filter_list',
    //     'url'  : '/opportunities',
    // },
    // {
    //     'id'      : 'qualifications',
    //     'title'   : 'qualifications',
    //     'type'    : 'item',
    //     'icon'    : 'filter_list',
    //     'url'  : '/qualifications',
    // },
    // {
    //     'id'      : 'script',
    //     'title'   : 'Playbook',
    //     'type'    : 'item',
    //     'icon'    : 'question_answer',
    //     'url'  : '/playbooks',
    //     'suburls'  : ['dashboard', 'builder', 'qualifications', 'insights'],
    //     'suburlsCheck' : 'playbook'
    // },
    // {
    //     'id'      : 'insights',
    //     'title'   : 'Insights',
    //     'type'    : 'item',
    //     'icon'    : 'bar_chart',
    //     'url'  : '/insights',
    // }
    // {
    //     'id'      : 'settings',
    //     'title'   : 'Settings',
    //     'type'    : 'item',
    //     'icon'    : 'settings',
    //     'url'  : '/settings',
    //     'suburls'  : ['question', 'companies', 'company', 'contacts', 'contact', 'integrations', 'feedback'],
    //     'children': [
    //         {
    //             'id'      : 'script',
    //             'title'   : 'Script',
    //             'type'    : 'item',
    //             'icon'    : 'question_answer',
    //             'url'  : '/questions',
    //             'suburl'  : '/labels',
    //         },
    //         {
    //             'id'    : 'companies',
    //             'title' : 'Companies',
    //             'type'  : 'item',
    //             'icon'  : 'business',
    //             'url'   : '/companies/all',
    //             'suburl'  : '/company',
    //         },
    //         {
    //             'id'    : 'contacts',
    //             'title' : 'Contacts',
    //             'type'  : 'item',
    //             'icon'  : 'perm_contact_calendar',
    //             'url'   : '/contacts/all',
    //             'suburl'  : '/contact',
    //         },
            // {
            //     'id'      : 'pains_and_bullets',
            //     'title'   : 'Pains & Bullets',
            //     'type'    : 'item',
            //     'icon'    : 'hearing',
            //     'url'  : '/pains_and_bullets',
            // },
            // {
            //     'id': 'integrations',
            //     'title': 'Integrations',
            //     'type': 'item',
            //     'icon': 'cast_connected',
            //     'url': '/settings/integrations/all',
            // },
            // {
            //     'id'      : 'whatsnew',
            //     'title'   : 'Wat is er nieuw?',
            //     'type'    : 'item',
            //     'icon'    : 'fiber_new_icon',
            //     'url'  : '/settings/whatsnew',
            // },
            // {
            //     'id': 'feedback',
            //     'title': 'Feedback',
            //     'type': 'item',
            //     'icon': 'feedback',
            //     'url': '/settings/feedback',
            // }
                    // {
                    //     'id'      : 'rest',
                    //     'title'   : 'Rest',
                    //     'type'    : 'item',
                    //     'icon'    : 'settings',
                    //     'url'  : '/settings/settings',
                    // },
    //     ]
    // }
];

export default navigationConfig;
