import { useEffect, useState } from "react";

export default function useStatePersisted(initialValue, key) {

    const isArray = Array.isArray(initialValue)
    const isPersisted = key && localStorage.getItem(key)
    let initialValueFinal = key ? (localStorage.getItem(key) || initialValue) : initialValue
    if(isArray && isPersisted) {
        initialValueFinal = JSON.parse(initialValueFinal)
    }
    const [value, setValue] = useState(initialValueFinal);

    // console.log("inside useStatePersisted " + key);

    useEffect(() => {
        if(key) {
            if(!value) {
                localStorage.removeItem(key)
            } else if(isArray) {
                localStorage.setItem(key, JSON.stringify(value))
            } else {
                localStorage.setItem(key, value)
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return [ value, setValue ]
};