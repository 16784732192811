import React, { useEffect, useState } from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress  } from '@material-ui/core';
import clsx from 'clsx';
import Lottie from "lottie-react";
import animData from "components/Controls/SplashScreen/loadinganimation.json";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  absolute: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: '#ffffffb8',
    zIndex: 1111
  }
}));

export default function Spinner(props) {
    const { size, thick, text, style, textStyle, absolute, lottie, delay, white } = props;

    const classes = useStyles();

    const [show, setshow] = useState(!delay);

    useEffect(() => {
      if(delay && delay > 0) {
        const t = setTimeout(() => {
          setshow(0)
        }, delay);
        return () => {
          clearTimeout(t)
        }
      }
    }, [delay])
    
    if(!show) {
      return null
    }

    return (
      <div className={clsx(classes.root, absolute && classes.absolute)} style={style && {...style}}>
        {!lottie && <CircularProgress size={size ? size : text && 25} thickness={thick ? 8 : text && 4.5} />}
        {lottie && <div style={{width: '5em', marginLeft: '-1em', zIndex: 111111}}><Lottie animationData={animData} /></div>}
        {text && <h5 style={{marginLeft: '0 1.5em', fontSize: '1.1em', zIndex: 111111, color: white && 'white'}, textStyle && {...textStyle}}>{text}</h5>}
      </div>
    );
}