import React, { useState, useEffect } from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LabelPopUpSelect from './LabelPopUpSelect';
import LabelPopUpCreate from './LabelPopUpCreate';
import { useQuery, useLazyQuery } from '@apollo/client';
import { labels as labelsQ } from 'app/apollo/labels/queries';

const useStyles = makeStyles(theme => ({
    paperBody: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    headerContainer: {
        color: 'white',
        fontSize: 17,
        display: 'flex',
        flexDirection: 'row',
        height: 26
    },
    headerTitle: {
        padding: '0.2em 0.5em 0 0.65em',
        backgroundColor: '#003646',
        fontWeight: 500,
    },
    headerTriangle: {
        borderColor: "transparent transparent transparent #003646",
        borderStyle: "solid",
        borderWidth: "30px 0 0 30px;",
        height: "0px",
        width: "0px",
    },
    container: {
        padding: '1em',
        borderTop: "2px solid #003646",
        height: '100%',
        backgroundColor: '#1D2124',
        color: '#F2F2F2',
        borderRadius: '0px 0px 3px 3px',
        fontSize: 12,
    },
}));

function LabelPopUpContainer(props) {
    const classes = useStyles(props);

    const { type, id, afterMutation, closeOnSelect, setLabelPopUp, noMutation, labelsFilter } = props;
    // const { data: dataLabelsFromTypeID } = useQuery(labelsQ, {variables: {id: id, type: type} } );

    const [ getLabelsFromTypeID, { data: dataLabelsFromTypeID }] = useLazyQuery(labelsQ, { fetchPolicy: 'cache-and-network', variables: {id: id, type: type} })
    useEffect(() => {
        if(id) {
            getLabelsFromTypeID()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { data: dataLabels } = useQuery(labelsQ, {variables: {type: type} } );

    const [labelsSelected, setLabelsSelected] = useState([])
    const [labelCreate, setLabelCreate] = useState(undefined)

    const labelsFromTypeID = dataLabelsFromTypeID && dataLabelsFromTypeID.labelQ.labels
    const labels = dataLabels && dataLabels.labelQ.labels
    useEffect(() => {
        if(labelsFromTypeID) {
            setLabelsSelected(labelsFromTypeID)
        }
    }, [labelsFromTypeID])
    useEffect(() => {
        if(labelsFilter) {
            setLabelsSelected(labelsFilter)
        }
    }, [labelsFilter])

    return <div className={classes.paperBody}>
        <div
            style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'center',
                background: 'white',
                width: '282px',
                // border: '1px lightgrey solid',
                borderRadius: '5px',
                boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }}
        >
            <p style={{marginTop: 10}}>
                {!labelCreate ? "Labels" : labelCreate.id ? "Update" : "Create"}
            </p>

            <hr style={{borderWidth: '0.5px', borderColor: 'lightgrey', width: '90%'}} />

                {!labelCreate && <LabelPopUpSelect labels={labels} type={type} typeId={id} afterMutation={afterMutation}
                    noMutation={noMutation} labelsSelected={labelsSelected}
                setLabelsSelected={setLabelsSelected} setLabelCreate={setLabelCreate} closeOnSelect={closeOnSelect} setLabelPopUp={setLabelPopUp}/>}
                {labelCreate &&
                    <React.Fragment>
                        <div style={{position: 'absolute', top: 8, left: 10}}
                          onClick={(e) => {
                            e.stopPropagation()
                            setLabelCreate(undefined)
                        }}>
                            <div style={{cursor: 'pointer',}}>
                                <ArrowBackIosIcon style={{fontSize: '12px'}}></ArrowBackIosIcon>
                            </div>
                        </div>
                        <LabelPopUpCreate type={type} labelCreate={labelCreate} setLabelsSelected={setLabelsSelected} labelsSelected={labelsSelected} setLabelCreate={setLabelCreate} typeId={id} />
                    </React.Fragment>}
        </div>
    </div>
}

export default LabelPopUpContainer