import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'auto'
    },
    tabContainerLine: {
        backgroundColor: theme.palette.primary.dark,
        paddingTop: '0.5em',
        width: '100%'
    },
    tabContainerBlock: {
        // backgroundColor: theme.palette.primary.dark,
        textAlign: 'center',
        padding: '0em 0.5em 0.5em 0.5em',
        // width: '50%',
        borderTop: "56px solid " + theme.palette.primary.dark,
        borderLeft: "50px solid transparent",
        borderRight: "50px solid transparent",
        height: 0,
    //   width: 100px
    },
    container: {
        display: 'inline-flex',
        flexDirection: 'row',
        width: 'auto',
        justifyContent: 'center',
        borderRadius: 4,
        cursor: 'pointer',
        fontFamily: 'Andale Mono,AndaleMono,monospace',
    },
    titleContainer: {
        fontSize: 18,
        fontWeight: 800,
        height: 40,
        lineHeight: "17px",
        textAlign: 'center',
        color: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
}));

function SwitchButtonGroup(props) {
    const classes = useStyles(props);

    const {indexActive, setIndexActive, vertical, big, id, style, styleContainer, isTabMenu, allowMulti} = props

    const childrenNew = React.Children.map(props.children, (child, index) => {
        const isFirst = index === 0 && props.children.length > 1
        const isLast = index === (props.children.length -1) && props.children.length > 1
        const isMiddle = !isFirst && !isLast && props.children.length > 1

        if(child) {
            if(child.props.isActive) {
                return React.cloneElement(child, {
                    isFirst: isFirst,
                    isLast: isLast,
                    isMiddle: isMiddle,
                    vertical,
                    big,
                });
            }
            const value = child.props.value
            const isActive = !allowMulti ? indexActive === index : indexActive.indexOf(value) > -1
            const checkItem = () => {
                if(!allowMulti) {
                    setIndexActive(index) 
                } else {
                    if(isActive) {
                        const newArr = [...indexActive];
                        newArr.splice(indexActive.indexOf(value), 1) 
                        setIndexActive(newArr)
                    } else {
                        setIndexActive([...indexActive, value])
                    }
                }
            }
            return React.cloneElement(child, {
                isFirst: isFirst,
                isLast: isLast,
                isMiddle: isMiddle,
                isActive: isActive,
                setIsActive: () => { setIndexActive && checkItem() },
                vertical,
                big,
            });
        }
      });

      let styleNew = {}

      if(style) {
        styleNew = {...style}
      }
      styleNew.flexDirection =  vertical && 'column'

      const ButtonGroup = <div className={classes.container} id={id} style={{...styleNew}}>
            {childrenNew}
        </div>

      if(!isTabMenu) {
        return ButtonGroup
      }

    return <div className={classes.tabContainer} style={styleContainer} >
        <div className={classes.tabContainerLine} />
        <div className={classes.tabContainerBlock} >
            <div style={{marginTop: '-55px'}}>
                {ButtonGroup}
            </div>
        </div>
    </div>
}

export default SwitchButtonGroup