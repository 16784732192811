import React from 'react';
import MaterialTable from 'material-table'

export default function ODTable(props) {
    const { emptyMessage = 'No data', title, button } = props

    return  <MaterialTable
        localization={{
            body: {
                emptyDataSourceMessage: emptyMessage,
            }
        }}
        components={{
            Toolbar: () => (title || button) ? <div className="flex justify-between items-center my-16 pl-24 pr-24" style={{height: '4em', paddingTop: '1.5em'}}>
              <h6 style={{fontSize: '2rem'}}>
                {title}
              </h6>
              {button}
            </div> : false,
            // Toolbar: props => <div></div>,
            Pagination: () => false,
            Header: () => false
          }}
        {...props}
    />
}