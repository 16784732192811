import gql from 'graphql-tag'

export const answers = gql`
  query answers($questionHubId: ID, $questionId: ID){
    answerQ {
      answers(questionHubId:$questionHubId, questionId: $questionId ){
        id
        dateUpdated
        valueText
        valueNumber
        valueYesNo
        valueDecimal
        valueNumberTo
        valueNumberFrom
        valueDate
        valueOptions {
           id
           title
        }
        questionId
        question {
          id
          dataType
        }
        companyDialogId
        companyDialog {
          id
          title
          startDate
          company {
            id
            name
          }
        }
      }
    }
  }
`

export const answerComments = gql`
  query answerComments($id: ID!, $skip: Boolean!){
    answerQ {
      answer(id:$id)  @skip(if: $skip){
        id
        comments{
          id
          content
          dateCreated
          creator {
            id
            firstName
            lastName
            color
          }
          dateCreated
        }
      }
    }
  }
`

export const answerCompanyDialogQuestion = gql`
  query answerCompanyDialogQuestion($companyDialogId: ID!, $questionId: ID!){
    answerQ {
      answerCompanyDialogQuestion(companyDialogId:$companyDialogId, questionId: $questionId){
        id
        dateUpdated
        valueText
        valueNumber
        valueYesNo
        valueDecimal
        valueNumberTo
        valueNumberFrom
        valueDate
        valueOptions {
           id
           title
        }
        questionId
        scribble{
           id
           content
           imageContent
        }
      }
    }
  }
`