import React from 'react';
import NoAnswers from "./NoAnswers"

export default function TextCharts(props) {
  const { analytics } = props

  if (analytics.answerCount === 0) {
    return <NoAnswers text="No text answers yet" />
  }

  return <NoAnswers text={analytics.answerCount + " answers given"} />
}