import React from 'react';
import QuestionAnalytics from './QuestionAnalytics';
import { useQuery } from '@apollo/client';
import { questionHubAnalytics } from 'app/apollo/questionHubs/queries';
import {Spinner} from "components"

export default function QuestionHubAnalytics(props) {
    const {questionHubId, title, nocardTitle} = props

    const { data: dataQuestionHubAnalytics } = useQuery(questionHubAnalytics, { variables: { id: questionHubId } })

    const questionHub = dataQuestionHubAnalytics && dataQuestionHubAnalytics.questionHubQ.questionHub

    if(!questionHub) {
        return <Spinner text="Loading answers" />
    }

    return <div className="flex flex-row justify-center flex-wrap w-full">
        {questionHub.questions.filter(q => q.dataType !== "LABELS").map((q, index) => 
            <QuestionAnalytics key={index} title={title} nocardTitle={nocardTitle} questionId={q.id} dataType={q.dataType} placeholder={q.placeholder} />)} 
    </div> 
    
}
