import React, { useState, useEffect } from 'react'

import { CanvasWidget } from '@projectstorm/react-canvas-core';
import createEngine, {
    DiagramModel,
} from '@projectstorm/react-diagrams';
import {
    DefaultLabelFactory,
    DefaultLinkFactory,
    DefaultNodeFactory,
    DefaultPortFactory
} from 'app/main/playbooks/FlowChart/FlowChartExtentions/default'

import ZoomButtons from './ZoomButtons';

export default function DiagramPreview(props) {

    const { data, zoom } = props
    const [engine, setEngine] = useState(undefined);
    
    useEffect(() => {
        return () => {
            if (engine) {
                engine.setModel(new DiagramModel())
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (engine && data) {
            setTimeout(() => {
                const model = engine.getModel()
                model.deserializeModel(JSON.parse(data), engine);
                model.setZoomLevel(zoom || 100)
                engine.repaintCanvas();

                setTimeout(() => {
                    engine.repaintCanvas();
                }, 50)
            }, 250);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [engine, data])

    useEffect(() => {
        if(data) {
            const engine = createEngine({ registerDefaultZoomCanvasAction: false });
            engine.getLabelFactories().registerFactory(new DefaultLabelFactory());
            engine.getNodeFactories().registerFactory(new DefaultNodeFactory()); // i cant figure out why
            engine.getLinkFactories().registerFactory(new DefaultLinkFactory());
            engine.getPortFactories().registerFactory(new DefaultPortFactory());
    
            const model = new DiagramModel();
            engine.setModel(model);
            // model.setLocked(true);
    
            setEngine(engine);
        }
    }, [data])

    const changeZoom = (value) => {
        const model = engine.getModel()
        let zoom = model.options.zoom + value;
        if(zoom <= 0) {
            zoom = 10
        }
        model.setZoomLevel(zoom)
        // setZoom(zoom)
        engine.repaintCanvas();
    }

    return <div style={{ paddingTop: '1.5em' }}>
        {engine && <div style={{
            height: '60vh', width: '70vw', position: 'relative',
            background: `linear-gradient(#efefef 1px,transparent 1px),linear-gradient(90deg,#efefef 1px,transparent 1px),linear-gradient(#efefef 1px,transparent 1px),linear-gradient(90deg,#efefef 1px,transparent 1px)`,
            backgroundSize: '20px 20px', border: '1px solid lightgrey'
        }}>
            <CanvasWidget engine={engine} />

            <div style={{ position: 'absolute', right: 25, top: 25 }}>
                <ZoomButtons changeZoom={changeZoom} />
            </div>
        </div>}
    </div>

}