import React, { FunctionComponent, Suspense } from 'react';
import { lazily } from 'react-lazily';
import { SplashScreen } from 'components'

const IEstyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: '#fafafa',
  textAlign: 'center',
};

// If it's internet explorer we don't want the application to load since it will crash due to ES6
// If it's not then we load it only then to minimize the initial load
export const LazyLoader = () => {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  if (isIE) {
    return (
      <div style={IEstyle}>
        <div>
          <h4>Internet Explorer 11 and below is not supported by the new Vario Web Application.</h4>
          <h5>Please consider using another browser.</h5>
        </div>
      </div>
    );
  } else {
    const {AppProvider} = lazily(() => import('./app/App'));

    return (
      <Suspense fallback={<SplashScreen content='Loading..' />}>
        <AppProvider />
      </Suspense>
    );
  }
};
