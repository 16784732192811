import React, { useState, useEffect } from 'react';
import Board from './KanbanCore'
import './KanbanCore/styles.css'

import { useMutation } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/styles';
import { SET_OPPORTUNITY_STAGE } from 'app/apollo/opportunities/mutations'
import { useHistory } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import { Avatar } from 'components'
import _ from 'lodash'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    card: {
        padding: '0.5em 1em 1em 1em',
        margin: '0.2em 0.3em 0.2em 0em',
        border: '0.2px solid ' + theme.palette.primary.lighter2,
        borderRadius: 4,
        background: 'white',
        cursor: 'pointer',
        width: 250,
        position: 'relative'
    },
    cardTitle: {
        fontWeight: 700,
        borderBottom: '0.2px solid ' + theme.palette.grey.light,
        paddingBottom: "0.35em",
        marginTop: '0.25em'
    },
    cardDescription: {
        marginTop: '0.7em',
        marginBottom: "0.25em",
    },
    cardDate: {
        color: theme.palette.grey.main,
        fontSize: '0.9em'
    },
    cardPriceContainer: {
        position: 'absolute',
        width: '100%',
        bottom: -13,
        left: 0,
        display: 'flex',
        justifyContent: 'center'
    },
    cardPrice: {
        background: theme.palette.primary.main,
        color: 'white',
        borderRadius: 5,
        padding: '3px 9px',
        zIndex: 1111
    },
    header: {
        margin: '0 0 1em 0'
    }
}));

export default function Kanban(props) {
    const { opportunityStages, refetch } = props

    const theme = useTheme()
    const classes = useStyles(props)
    let history = useHistory();

    const [board, setBoard] = useState(undefined)
    const [opportunities, setOpportunities] = useState([])

    const [setOpportunityStage] = useMutation(SET_OPPORTUNITY_STAGE)

    useEffect(() => {
        if (opportunityStages) {
            const opportunitiesNew = _.flatten(opportunityStages.map(st => st.opportunities));
            setOpportunities(opportunitiesNew)

            const columns = opportunityStages.map((opportunityStage, index) => {

                return {
                    id: opportunityStage.id,
                    title: opportunityStage.title,
                    cards: opportunityStage.opportunities.map((opportunity, index2) => {

                        return {
                            id: opportunity.id,
                            opportunity: opportunity,
                        }
                    })
                }
            })

            setBoard({ columns: columns })

            setTimeout(() => {
                opportunityStages.forEach(stage => {
                    const value = "column-draggable-" + stage.id
                    var element = document.querySelector('[data-rbd-draggable-id="' + value + '"]');
                    if (element) {
                        element.style["border-top"] = '3px solid ' + theme.palette.stageColors[stage.position - 1]
                    }
                });

            }, 100);
        }
    }, [opportunityStages])

    if (!board) {
        return null
    }

    return <Board
        renderCard={(props, { removeCard, dragging }) => {
            const { id, opportunity } = props
            const { company, dateExpectedClose, price, title } = opportunity
            const { name, id: companyId } = company

            const oppNew = opportunities.find(opp => opp.id == id)
            const stage = opportunityStages.find(st => st.id == oppNew.opportunityStageId)

            const stageColor = theme.palette.stageColors[stage.position - 1]

            return <div className={classes.card} dragging={dragging} style={{ border: '1px solid ' + stageColor }}>
                <div className={classes.cardTitle} onClick={() => {
                    history.push("/account/" + companyId)
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar verysmall data={company} image={company && company.favicon}>
                            {name.charAt(0).toUpperCase()}
                        </Avatar>

                        <div style={{marginLeft: 10}}>{name}</div>
                    </div>
                </div>

                <div className={classes.cardDescription}>{title}</div>

                {(dateExpectedClose || price) && <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {dateExpectedClose && <div className={classes.cardDate}>{moment(dateExpectedClose).fromNow()}</div>}
                </div>}

                {price && price > 0 && <div className={classes.cardPriceContainer}>
                    <div className={classes.cardPrice} style={{ background: stageColor }}>{price}€</div>
                </div>}

                <Icon onClick={removeCard}
                    style={{ position: 'absolute', color: theme.palette.grey.main, right: 7, top: 5 }}>close</Icon>
            </div>
        }}

        renderColumnHeader={(props, actions) => {
            const { id, title, cards } = props
            const { addCard, removeCard, renameColumn } = actions
            return <div className={classes.header}>
                {title}
            </div>
        }}

        renderColumnAdder={({ addColumn }) => {
            return <div onClick={() => addColumn({ id: 2, title: 'Title', cards: [] })}>
                Add column
            </div>
        }}

        onCardDragEnd={(board, card, source, destination) => {
            setOpportunityStage({ variables: { opportunityId: card.id, stageId: destination.toColumnId } })
                .then(res => {
                    refetch()
                })
        }}

        initialBoard={board} />
}