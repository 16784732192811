import gql from 'graphql-tag'

export const CREATE_QUESTION = gql`
  mutation CREATE_QUESTION(
    $questionHubId:ID!,
    $description: String,
    $icon: String,
    $placeholder: String,
    $dataTypeString: String!,
    $options: [String],
    $defaultValue: Int,
    $defaultValueBool: Boolean,
    $defaultStartValue: Int,
    $defaultEndValue: Int,
    
    $minValue: Int,
    $maxValue: Int,
    $labels:[String],
    $labelIds: [ID!]
    $isInternal: Boolean,

    $customFieldId: String,
    $customFieldLabel: String,
    $customFieldContext: String,
    $customFieldType: String,

    $mediaItemIds: [ID]
  ){
    questionM {
      createQuestion(
        questionCreate: { 
          questionHubId: $questionHubId,
          description : $description,
          icon : $icon,
          placeholder: $placeholder,
          dataTypeString: $dataTypeString,
          options: $options,
          defaultValue : $defaultValue,
          defaultValueBool: $defaultValueBool,
          defaultStartValue: $defaultStartValue,
          defaultEndValue: $defaultEndValue,
          minValue: $minValue,
          maxValue: $maxValue,
          labels: $labels,
          labelIds: $labelIds,
          isInternal: $isInternal,
          customFieldId: $customFieldId,
          customFieldLabel: $customFieldLabel,
          customFieldContext: $customFieldContext,
          customFieldType: $customFieldType,
          mediaItemIds: $mediaItemIds
        }
        ){
          id
          placeholder
          dataType
  
          options {
            id
            title
            mediaItem{
              url
              type
            }
          }
    
          defaultValue
          defaultEndValue
          defaultStartValue
          maxValue
          minValue
          defaultValueBool
          
          customFieldId
          customFieldLabel
          customFieldContext
          customFieldType
      }
    }
  }
`


export const DELETE_QUESTION = gql`
  mutation DELETE_QUESTION ($id: ID!){
    questionM{
      deleteQuestion(id:$id){
        id
      }
    }
  }
`

export const UPDATE_QUESTION = gql`
  mutation UPDATE_QUESTION(
    $id: ID!,
    $description: String,
    $icon: String,
    $dataTypeString: String,
    $defaultValue: Int,
    $minValue: Int,
    $maxValue: Int,
    $defaultStartValue: Int,
    $defaultEndValue: Int,
    $optionIds: [ID!],
    $options: [String],
    $defaultValueBool: Boolean,
    $placeholder: String,
    $labels: [String],
    $labelIds: [ID!],
    $isInternal: Boolean,
    $customFieldId: String,
    $customFieldLabel: String,
    $customFieldContext: String,
    $customFieldType: String,
    $mediaItemIds: [ID]
  ){
    questionM{
      updateQuestion(questionUpdate:{
        id: $id,
        description: $description,
        icon: $icon,
        dataTypeString: $dataTypeString,
        defaultValue: $defaultValue,
        minValue: $minValue,
        maxValue: $maxValue,
        defaultStartValue: $defaultStartValue,
        defaultEndValue: $defaultEndValue,
        optionIds: $optionIds,
        options: $options,
        defaultValueBool: $defaultValueBool,
        labels: $labels,
        labelIds: $labelIds,
        placeholder: $placeholder,
        isInternal: $isInternal,
        customFieldId: $customFieldId,
        customFieldLabel: $customFieldLabel,
        customFieldContext: $customFieldContext,
        customFieldType: $customFieldType,
        mediaItemIds: $mediaItemIds
      }){
        id
        placeholder
        dataType

        options {
          id
          title
          mediaItem{
            url
            type
          }
        }
  
        defaultValue
        defaultEndValue
        defaultStartValue
        maxValue
        minValue
        defaultValueBool

        customFieldId
        customFieldLabel
        customFieldContext
        customFieldType
      }
    }
  }
`

// export const UPDATE_QUESTION = gql`
//   mutation UPDATE_QUESTION(
//     $id: ID!,
//     $crmId: String!,
//     $crmLabel: String!,
//     $crmContext: String!,
//     $crmType: String!,
//   ){
//     questionM{
//       updateQuestion(questionUpdate:{
//         id: $id,
//         crmId: $crmId,
//         crmLabel: $crmLabel,
//         crmContext: $crmContext,
//         crmType: $crmType,
//       }){
//         id
//         customFieldId
//         customFieldContext
//         customFieldLabel
//         customFieldType
//       }
//     }
//   }
// `