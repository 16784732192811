import React from 'react';
import { MenuItem, ListItemIcon, ListItemText, Icon } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    listItemText: {
        padding: 0,
        margin: 0
    },
    menuItem: {
        paddingRight: '3em',
        cursor: 'pointer'
    }
  }));

export default function CustomMenuItem(props) {
    const { index, title, onClick, item } = props
    const classes = useStyles();

    if (!item || !item.icon) {
        return <MenuItem className={classes.menuItem} key={index} onClick={onClick}>{title}</MenuItem>
    } else {
        return <MenuItem className={classes.menuItem} key={index} onClick={onClick}>
            <ListItemIcon>
                <Icon fontSize="small" style={{color: item.color || item.iconFill }}>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={title} className={classes.listItemText} style={{color: 'black'}} />
            {/* item.color */}
        </MenuItem>
    }
}