import React, {useState} from 'react';
import {Avatar, Button, Icon, ListItemIcon, ListItemText, Popover, MenuItem, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import { userMe } from 'app/apollo/user/queries';
import { useQuery } from '@apollo/client';
import { deepOrange } from '@material-ui/core/colors';

function UserMenu(props)
{
  // const dispatch = useDispatch();
  // const user = useSelector(({auth}) => auth.user);
  const { data } = useQuery(userMe);
  const user = data && data.me;

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = event => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };
  
  return (
    <React.Fragment>
      <Button className="h-64" onClick={userMenuClick}>
        {user && user.avatar ?
          (
            <Avatar className="" alt="user photo" src={user.avatar}/>
          )
          :
          (
            
            <Avatar className="" style={{backgroundColor: (user && user.color) ? user.color : deepOrange[500]}}>
              {user && user.firstName.charAt(0) + '' + user.lastName.charAt(0)}
            </Avatar>
          )
        }

        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {user && user.firstName + ' ' + user.lastName}
          </Typography>
          <Typography className="text-11 capitalize" color="textSecondary">
            {user && user.role && user.role.toString()}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: "py-8"
        }}
      >
        {user && (!user.role || user.role.length === 0) ? (
          <React.Fragment>
            <MenuItem
              component={Link}
              onClick={() => {
                props.history.push(`/profile/${user.id}`)
                userMenuClose()
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="My Profile"/>
            </MenuItem>
            {/* <MenuItem component={Link}
              onClick={() => {
                props.onSignOut()
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Logout"/>
            </MenuItem> */}
            {/* <MenuItem component={Link} to="/register">
                <ListItemIcon className="min-w-40">
                  <Icon>person_add</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary="Register"/>
            </MenuItem> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MenuItem component={Link} to="/pages/profile" onClick={userMenuClose}>
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="My Profile"/>
            </MenuItem>
            <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose}>
              <ListItemIcon className="min-w-40">
                <Icon>mail</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Inbox"/>
            </MenuItem>
            <MenuItem
              onClick={() => {
                // dispatch(authActions.logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Logout"/>
            </MenuItem>
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  );
}

export default UserMenu;
