import * as React from 'react';
import { PointModel } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';

export interface DefaultLinkPointWidgetProps {
  point: PointModel;
  previousPoint: PointModel;
  color?: string;
  colorSelected: string;
  align: string;
}

export interface DefaultLinkPointWidgetState {
  selected: boolean;
}

export const PointTop = styled.circle`
  pointer-events: all;
`;

export class DefaultLinkArrowWidget extends React.Component<DefaultLinkPointWidgetProps, DefaultLinkPointWidgetState> {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  render() {
    const { point, previousPoint, color, align } = this.props;

    let angle = 90 + (Math.atan2(point.getPosition().y - previousPoint.getPosition().y, point.getPosition().x - previousPoint.getPosition().x) * 180) / Math.PI;
    // switch (align) {
    //   case 'right':
    //     angle = 90;
    //     break;
    //   case 'bottom':
    //     angle = 180;
    //     break;
    //   case 'left':
    //     angle = 270;
    //     break;
    //   default:
    //     break;
    // }
    
    // transform={'translate(0, -7)'}
    return <g className="arrow" transform={'translate(' + point.getPosition().x + ', ' + point.getPosition().y + ')'}
        style={{fill: color || "#186557" }} >
        <g style={{ transform: 'rotate(' + angle + 'deg)' }}>
          <g>
            <polygon
              points="0,5 4,15 -4,15"
              onMouseLeave={() => {
                this.setState({ selected: false });
              }}
              onMouseEnter={() => {
                this.setState({ selected: true });
              }}
              data-id={point.getID()}
              data-linkid={point.getLink().getID()}
            ></polygon>
          </g>
        </g>
      </g>
  }
}
