import { PortModelAlignment } from '@projectstorm/react-diagrams'
import { DefaultPortModel } from '../FlowChartExtentions/default'
import { getPart } from './rest'
import { GetTypeShort, GetTypeIcon } from 'app/tools/typeServices'

export default function createPortsFromDataType(questionHub, node, isRoot, background, oldOptions) {
    //sameLevel
    const addPort = (label, questionIcon) => {
        const id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))

        const p = new DefaultPortModel({
            in: false,
            name: id,
            label: label,
            direction: 'right',
            questionIcon,
            isRoot,
            nodeBackground: background,
            alignment: PortModelAlignment.RIGHT,
            // sameLevel: sameLevel
        })
        node.addPort(p)
        return p
    }
    if (!questionHub.questions) {
        debugger
    }
    questionHub.questions
        .filter((q) => q.dataType !== 'LABELS')
        .forEach((question) => {
            // console.log({question})
            const dType =
                question.placeholder && question.placeholder.length > 0
                    ? getPart(question.placeholder, 35)
                    : GetTypeShort(question.dataType)
            let portCreated = undefined

            switch (question.dataType) {
                case 'OPTIONS_RADIO':
                case 'OPTIONS_COMBO':
                case 'OPTIONS_CHECKBOX':
                    question.options &&
                        question.options.forEach((option) => {
                            const fOld = oldOptions && oldOptions.find(t => t.id == option.id)
                            if(!fOld) {
                                const nodeTmp = addPort(option.title, GetTypeIcon(question.dataType))
                                nodeTmp.idOptionQuestion = option.id
                                nodeTmp.questionHubId = questionHub.id
    
                                nodeTmp.questionId = question.id
                                nodeTmp.dataType = question.dataType
                                nodeTmp.question = question
                                nodeTmp.questionHub = questionHub
                            }
                           
                        })
                    break
                default:
                    portCreated = addPort(dType, GetTypeIcon(question.dataType))
                    break
            }

            if (portCreated) {
                portCreated.questionHubId = questionHub.id
                portCreated.questionId = question.id
                portCreated.dataType = question.dataType
                portCreated.question = question
                portCreated.questionHub = questionHub
            }
        })

    // if(portOuts.length == 0) {
    //     const p = new DefaultPortModel({
    //         in: false,
    //         name: "empty",
    //         label: "empty",
    //         alignment: PortModelAlignment.RIGHT
    //     });

    //     node.addPort(p);
    //     portOuts.push(p)
    // }

    // return portOuts
}
