import React, { useState, useEffect } from 'react'
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles'
import {  Button, IconButton } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import CheckIcon from '@material-ui/icons/Check'
import _ from '@lodash'
import { useMutation } from '@apollo/client';
import { CREATE_LABEL_LINK } from 'app/apollo/labels/mutations'
import { labels as labelsQ } from 'app/apollo/labels/queries';
import {labelColors} from  'app/tools/config'

const useStyles = makeStyles(theme => ({
    paperBody: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    headerContainer: {
        color: 'white',
        fontSize: 17,
        display: 'flex',
        flexDirection: 'row',
        height: 26
    },
    headerTitle: {
        padding: '0.2em 0.5em 0 0.65em',
        backgroundColor: '#003646',
        fontWeight: 500,
    },
    headerTriangle: {
        borderColor: "transparent transparent transparent #003646",
        borderStyle: "solid",
        borderWidth: "30px 0 0 30px;",
        height: "0px",
        width: "0px",
    },
    container: {
        padding: '1em',
        borderTop: "2px solid #003646",
        height: '100%',
        backgroundColor: '#1D2124',
        color: '#F2F2F2',
        borderRadius: '0px 0px 3px 3px',
        fontSize: 12,
    },
}));

function LabelPopUpSelect(props) {
    const classes = useStyles(props);

    const { labels, typeId, type, labelsSelected, setLabelsSelected, setLabelCreate, 
        getLabelsAll, afterMutation, closeOnSelect, setLabelPopUp, noMutation } = props;

    const [indexesSelected, setIndexesSelected] = useState([])
    const [labelsFinal, setLabelsFinal] = useState([])

    const [createLabelLink] = useMutation(CREATE_LABEL_LINK, 
        {
            refetchQueries: [
                { query: labelsQ, variables: {id: typeId, type: type} },
            ]
        });

    const checkLabel = (lbl) => {
        const existsLink = labelsSelected.find(l => l.id === lbl.id)

        if(!noMutation) {
            createLabelLink({variables: {labelId: lbl.id, typeId: typeId, type: type, remove: !!existsLink}}).then(res => {
                afterMutation && afterMutation()
    
                if(closeOnSelect) {
                    setLabelPopUp(false)
                }
            })
        } else {
            afterMutation && afterMutation(lbl)
            setLabelPopUp(false)
        }
    }

    useEffect(() => {
        if(labels) {
            let labelsSorted = [...labels]

            let indexes = []

            labels.forEach(o => {
                indexes.push(labelColors.indexOf(o.color))
            });

            labelsSorted = _.sortBy(labelsSorted, [o => labelColors.indexOf(o.color)])
            setLabelsFinal(labelsSorted)

            let indexesSelectedNew = []
            if(labelsSelected) {
                labelsSorted && labelsSorted.forEach((lbl, index) => {
                    labelsSelected.forEach(lblSelected => {
                        if(lbl.id === lblSelected.id){
                            indexesSelectedNew.push(index);
                        }
                    });
                });
            }
            setIndexesSelected(indexesSelectedNew)
        }
    }, [labels, labelsSelected])
        
        return <div className={classes.paperBody}>
             <div id="LabelPopUpSelectChild" 
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    minHeight: '150px',
                    maxHeight: '300px',
                    overflowY: 'auto'
                }}>

                    {/* forEach loop create labels */}
                    {labelsFinal && labelsFinal.map((label, index) => 

                        /* label container */
                        <div
                            key={index}    
                        style={{
                            display: 'flex', 
                            flexDirection: 'row', 
                            //height: '1.5em', 
                            width: '95%', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            }}>

                            {/* label button*/}
                            <Button style={{
                                display: 'block',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                backgroundColor: label.color, 
                                width: '100%', 
                                padding: '5px',
                                minHeight: '27px',
                                margin: '2.5px',
                                fontSize: '12px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    //debugger;
                                    //setLabelsSelected([...labelsSelected, label])
                                    //console.log("clicked label with id: " + label.id);
                                    checkLabel(label);
                                    getLabelsAll && getLabelsAll()
                                }}
                                >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    color: 'white',
                                }}>
                                    {/* label name */}
                                    <p style={{fontSize: '10px'}}>{label.title}</p>

                                    {/* check icon */}
                                    {indexesSelected.indexOf(index) > -1 &&
                                        <CheckIcon style={{fontSize: '16px'}}/>
                                    }

                                </div>
                            </Button>

                            {/* get labels with same color first */}
                            {/* {labels.map((color, id, index) => 
                                <div>other labels with same color here</div>
                            )} */}
                            
                            {/* edit icon */}
                            <IconButton aria-label="edit" size="small" 
                              onClick={(e) => {
                                e.stopPropagation()
                                //debugger
                                setLabelsSelected([...labelsSelected, label])
                                setLabelCreate(label)
                            }}>
                                <CreateIcon style={{fontSize: '15px'}} />
                            </IconButton>
                        </div>                    
                    )}
                    
                    
                </div>

                {/* create new label button */}
                <div style={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        width: '100%', 
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        }}>

                        <Button variant="contained" color="default" size="small" disableElevation 
                        style={{fontSize: '0.9rem', width: '95%', cursor: 'pointer', }}
                        onClick={() => {

                            if(!labels) {
                                setLabelCreate({color: labelColors[0].color})
                            } else {
                                const listColorCounts = []

                                labelColors.forEach(lblColor => {
                                    const r = labels.filter(l => l.color === lblColor)
                                    listColorCounts.push({color: lblColor, count: r.length})
                                });
                            
                                const minColorUsed = _.minBy(listColorCounts, function(o) { return o.count; });
                                
                                setLabelCreate({color: minColorUsed.color})
                            }
                          
                        }}>Create new label</Button>
                    </div>
        </div>
}

export default LabelPopUpSelect