import React from 'react';
import {
    LinearProgress,
} from '@material-ui/core';
import NoAnswers from "./NoAnswers"

export default function YesNoCharts(props) {
    const { analytics, addFilter } = props

    if (analytics.answerCount === 0) {
        return <NoAnswers text="No yesno answers yet" />
    }

    return <div>
            <div style={{width: '100%', textAlign: 'center', height: '2em'}}>{analytics.yesNoData.answerRatio}% answered YES</div>
            <LinearProgress
                className="w-xs"
                color="secondary"
                variant="determinate"
                value={analytics.yesNoData.answerRatio}
                style={{ width: "100%" }}
            />

            {analytics.yesNoData && <div style={{marginLeft: '2em', marginTop: '2.5em'}}>
                <div style={{marginTop: '.5em', cursor: 'pointer'}} 
                    onClick={() => {
                        if(analytics.yesNoData.answeredYes > 0) {
                          addFilter({value: true, label: "YES", count: analytics.yesNoData.answeredYes})
                        }
                    }}>
                    {`${analytics.yesNoData.answeredYes === 0 ? '0' : analytics.yesNoData.answeredYes}
                    ${analytics.yesNoData.answeredYes === 1 ? ' YES answer' : ' YES answers'}`}
                </div>
                <div style={{marginTop: '.5em', cursor: 'pointer'}}
                    onClick={() => {
                        if(analytics.yesNoData.answeredNo > 0) {
                        addFilter({value: false, label: "NO", count: analytics.yesNoData.answeredNo})
                        }
                    }}>
                    {`${analytics.yesNoData.answeredNo === 0 ? '0' : analytics.yesNoData.answeredNo}
                    ${analytics.yesNoData.answeredNo === 1 ? ' NO answer' : ' NO answers'}`}
                </div>
                <div style={{marginTop: '.5em', cursor: 'pointer'}}
                    // onClick={() => {
                    //     if(analytics.yesNoData.answeredNo > 0) {
                    //     addFilter({value: null, label: "EMPTY", count: analytics.yesNoData.answeredEmpty})
                    //     }
                    // }}
                    >
                    {`${analytics.yesNoData.answeredEmpty === 0 ? '0' : analytics.yesNoData.answeredEmpty} 
                    ${analytics.yesNoData.answeredEmpty === 1 ? ' EMPTY answer' : ' EMPTY answers'}`}
                </div>
            </div> }
       
    </div>
}