import gql from 'graphql-tag'

export const questionHubsBasic = gql`
  query questionHubs($playbookId: ID!) {
    questionHubQ {
      questionHubs(playbookId: $playbookId, onlyParents: true) {
        id
        title
        order
        icon
        description
      }
    }
  }
`
export const questionHubsBasicAll = gql`
  query questionHubs($playbookId: ID!) {
    questionHubQ {
      questionHubs(playbookId: $playbookId) {
        id
        title
        order
        icon
        description
      }
    }
  }
`

export const questionHubsBasicWithAR = gql`
  query questionHubs($playbookId: ID!) {
    questionHubQ {
      questionHubs(playbookId: $playbookId) {
        id
        title
        order
        icon
        answerRangeQuestionHub {
          id
        }
      }
    }
  }
`


export const questionHubsIds = gql`
    query questionHubsIds($playbookId: ID!) {
      questionHubQ {
        questionHubs(playbookId: $playbookId) {
            id
            dateUpdated
        }
      }
    }
`

export const questionHubCount = gql`
  query questionHubCount {
    questionHubQ {
      questionHubCount
    }
  }
`


// answerCount
export const questionHubForList = gql`
  query questionHubs($playbookId: ID!) {
    questionHubQ {
      questionHubs(playbookId: $playbookId) {
        id
        title
        icon
        playbookCount
        companyDialogCount
        creator{
          id
          firstName
          lastName
        }
        labels {
          id
          title
          color
          description
        }
        dateCreated
        dateUpdated
      }
    }
  }
`

export const questionHubs = gql`
  query questionHubs($playbookId: ID!) {
    questionHubQ {
      questionHubs(playbookId: $playbookId) {
        id
        title
        icon
        companyDialogs {
          id
          title
          companyDialogState {
            companyDialogStatus
            message
          }
        }
        labels {
          id
          title
          color
        }
        creator{
          id
          firstName
          lastName
        }
        dateCreated
        dateUpdated
      }
    }
  }
`

export const questionHubAnalytics = gql`
  query questionHub($id: ID!) {
    questionHubQ {
      questionHub(id: $id) {
        id
        title
        icon
        questions {
          id
          dataType
          placeholder
        }
      }
    }
  }
`
export const questionHubsAnalytics = gql`
  query questionHubs($playbookId: ID!) {
    questionHubQ {
      questionHubs(playbookId: $playbookId) {
        id
        title
        icon
        questions {
          id
          dataType
          placeholder
        }
      }
    }
  }
`

export const questionHubBasicWithAR = gql`
  query questionHub($id: ID!, $companyDialogId: ID!) {
    questionHubQ {
      questionHub(id: $id, companyDialogId: $companyDialogId) {
        id
        
        title
        icon
        description
        isInternal
        order
        importance

        answerRangeQuestionHub {
          id

          valueBool
          valueOptions{
            id
            title
          }
          valueDateFrom
          valueDateTo
          valueNumberFrom
          valueNumberTo

          question {
            id
            placeholder
            dataType

            options {
              id
              title
              mediaItem{
                url
                type
              }
            }
      
            defaultValue
            defaultEndValue
            defaultStartValue
            maxValue
            minValue
            defaultValueBool

            questionHub {
              id
              title
              icon
            }
          }
        }
      }
    }
  }
`

export const questionHubForDiagramUpdate = gql`
  query questionHub($id: ID!) {
    questionHubQ {
      questionHub(id: $id) {
        id
        title
        icon
        description
        importance
        x
        y
        
        questions {
          id
          placeholder
          dataType
          
          defaultValue
          defaultEndValue
          defaultStartValue
          maxValue
          minValue
          defaultValueBool

          options {
              id
              title
          }
        }
      }
    }
  }
`

export const questionHubForQualification = gql`
  query questionHub($id: ID!) {
    questionHubQ {
      questionHub(id: $id) {
        id
        title
        icon
        description
        importance
        
        questions {
          id
          placeholder
          dataType
          
          defaultValue
          defaultEndValue
          defaultStartValue
          maxValue
          minValue
          defaultValueBool

          options {
              id
              title
          }
        }
      }
    }
  }
`

export const questionHubBasic = gql`
  query questionHub($id: ID!, $companyDialogId: ID!) {
    questionHubQ {
      questionHub(id: $id, companyDialogId: $companyDialogId) {
        id
        title
        icon
        description
        importance

        nextSteps {
          id
          title
          dateDone
          isDone
        }

        labels {
          id
          title
          description
          color
        }
        
        questions {
          id
          placeholder
          dataType
          
          defaultValue
          defaultEndValue
          defaultStartValue
          maxValue
          minValue
          defaultValueBool

          options {
              id
              title
          }

          answerChildQuestions(companyDialogId: $companyDialogId) {
            id
            questionHubId
            questionId
          }

          answer(companyDialogId: $companyDialogId){
            id
  
            companyDialogId
            questionId
            
            dateUpdated
            valueText
            valueNumber
            valueYesNo
            valueDecimal
            valueNumberTo
            valueNumberFrom
            valueDate
            valueOptions{
              id
              title
            }
            scribble{
              id
              content
              imageContent
            }
          }
        }
      }
    }
  }
`

// export const questionHubBasicWithSub = gql`
//   query questionHub($questionHubId: ID!) {
//     questionHub(questionHubId: $questionHubId) {
//       id
//       title
//       icon
//       answerRangeQuestionHub {
//         id
//         question {
//           id
//           questionHub {
//             id
//           }
//         }
//       }
//     }
//   }
// `