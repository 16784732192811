const reducer = (state = { sourceNode: undefined }, action) => {
    switch (action.type) {
        case 'SOURCENODE':
            return Object.assign({}, state, { sourceNode: action.payload.sourceNode })
        default:
            return state
    }
}

export default reducer
