import React, { useRef, useState, useEffect, useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import { makeStyles, useTheme } from '@material-ui/styles';

export default function Creatable(props) {
    const { placeholder } = props
    const theme = useTheme()

    return <CreatableSelect
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
            control: styles => (
                { ...styles, borderColor: theme.palette.primary.main,
                ':hover': {
                    ...styles[':hover'],
                    borderColor: theme.palette.primary.main
                },
                ':active': {
                    ...styles[':active'],
                    boxShadow: '0 0 0 1px ' + theme.palette.primary.main
                },
                ':focus': {
                    ...styles[':active'],
                    boxShadow: '0 0 0 1px ' + theme.palette.primary.main
                }
            }) 
        }}
        isClearable
        {...props}
        // onChange={setOptions}
    //   onInputChange={this.handleInputChange}
        // options={optionsAll}
    />
}