import React from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles';
import { Typography  } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export default function LabelNoWrap(props) {
    const { style, children } = props;

    const classes = useStyles();

    return <Typography className={clsx(classes.root)} style={style && {...style}}>
        {children}
      </Typography>
}