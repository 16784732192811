import React from 'react';
// import { FusePageSimple } from '@fuse';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: props => !props.noPaddingBottom && '10%',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function CenterContainer(props) {
    const { children, style, background, color, absolute, light } = props;

    const classes = useStyles();

    const styleNew = {background: light ? '#eeeeee99' : background, color: color, position: absolute && 'absolute',
        top: absolute && 0}
    const styleMerged = style ? {...styleNew, ...style} : styleNew

    return (
      <div className={clsx(classes.root)} style={styleMerged}>
        {children}
      </div>
    );
}