import React, { useEffect } from 'react';
import Chart from 'chart.js';
import "chartjs-chart-matrix";
import NoAnswers from "./NoAnswers"

//#region MatrixChartColors
function mapCountToColor(count, maxCount) {
    const r = scale(count, 0, maxCount, 225, 93)
    const g = scale(count, 0, maxCount, 235, 154)
    const b = scale(count, 0, maxCount, 230, 157)
    return `rgb(${r}, ${g}, ${b})`
}
const scale = (num, in_min, in_max, out_min, out_max) => {
    return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}
  //#endregion

export default function TimeCharts(props) {
    const { analytics, addFilter } = props

    useEffect(() => {
        // const canvas = document.getElementById("canvasMatrix");
        const timeChartData = analytics.timeChartData;

        if (timeChartData.length > 0) {
            const maxCount = timeChartData.reduce((max, current) => {
                return current.count > max ? current.count : max
            }, 0)

            new Chart("canvasMatrix", {
                type: 'matrix',
                label: '',

                getElementAtEvent:(ev) => {
                    const index = ev && ev[0] && ev[0]._index
                    debugger
              
                    if(index >= 0) {
                      const d = timeChartData[index]
                      addFilter({value: d.startNumber + '@' + d.endNumber, label: d.label, count: d.count})
                    }
                  },

                data: {
                    datasets: [{
                        data: timeChartData.map(data => {
                            return { x: data.hour, y: data.startMinute, v: data.count }
                        }),
                        label: false,
                        backgroundColor: timeChartData.map(data => mapCountToColor(data.count, maxCount)),
                        width: '32',
                        height: '16',
                    }]
                },
                options: {
                    legend: {
                        display: false
                    },
                    layout: {
                        padding: {
                            right: 16,
                            top: 12
                        }
                    },
                    tooltips: {
                        enabled: true,
                        callbacks: {
                            title: function (tooltipItem, data) {
                                console.log(tooltipItem, data)
                                return `${data['datasets'][0]['data'][tooltipItem[0]['index']].v}`
                            },
                            label: function (tooltipItem, data) {
                                return false
                            },
                            // labelColor:function(tooltipItem, data){
                            //   return false
                            // }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: timeChartData[0].hour,
                                max: timeChartData[timeChartData.length - 1].hour,
                                stepSize: 1,
                                padding: 8
                            },
                            gridLines: {
                                offsetGridLines: true,
                                display: false,
                                color: '#ffffff'
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 50,
                                stepSize: 10,
                                padding: 16
                            },
                            gridLines: {
                                offsetGridLines: true,
                                display: false,
                                color: '#ffffff'
                            }
                        }]
                    }
                }
            })
        }
    }, []);

    if (analytics.answerCount == 0) {
        return <NoAnswers text="No times chosen yet" />
    }

    return   <canvas id="canvasMatrix" />
}