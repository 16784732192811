import React, { useState } from 'react';
import NoAnswers from "../NoAnswers"
import { useTheme } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ZoomedIn from './ZoomedIn'
import ZoomedOut from './ZoomedOut'

export default function NumberCharts(props) {
    const { analytics } = props

    const [zoomIn, setZoomIn] = useState("zoomout");

    const theme = useTheme();

    if (analytics.answerCount === 0) {
        return <NoAnswers text="No numbers given yet" />
    }

    return <div style={{ marginTop: '-2em' }}>
        <div style={{ display: 'flex', justifyContent: 'center', transform: 'scale(.85)', marginBottom: '3em', color: theme.palette.primary.main, fontSize: '0.8em' }}>
            <ToggleButtonGroup
                size="small"
                value={zoomIn}
                exclusive
                onChange={(e, v) => { 
                    e.stopPropagation()
                    v && setZoomIn(v)
                }}
                aria-label="zoom"
            >
                <ToggleButton value="zoomin" aria-label="zoomin">
                    <Icon>zoom_in</Icon>
                </ToggleButton>
                <ToggleButton value="zoomout" aria-label="zoomout">
                    <Icon>zoom_out</Icon>
                </ToggleButton>
            </ToggleButtonGroup>
        </div>

        <div style={{ marginTop: '-2em', height: '12em' }}>
            {zoomIn === "zoomin" && <ZoomedIn {...props} />}
            {zoomIn === "zoomout" && <ZoomedOut {...props} />}
        </div>
    </div>
}