import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Lottie from "lottie-react";
import animData from "./loadinganimation.json";
import packageJson from '../../../../package.json';

const clearCaches = () => {
    caches.keys().then(async function(names) {
        await Promise.all(names.map(name => caches.delete(name)));
    });
    localStorage.clear();
    sessionStorage.clear();
}

const Version = (props) => <div style={{ fontSize: '1.1em', opacity: 0.5, zIndex: 111111, textAlign: 'center', color: 'white', position: 'absolute', bottom: '10px', width: props.inApp ? '112px' : '100%' }}>v{packageJson.version}</div>

export default function SplashScreen(props) {
    // console.logF("SplashScreen")

    const { hideprogress, noInternet, showClearCacheButton, login, error, loginButtonStyle, content } = props

    const [completed, setCompleted] = useState(0);
    // const [buffer, setBuffer] = useState(10);
    
    // useEffect(() => {
    //     fetch('assets/loadinganimation.json').then(response => {
    //         return response.json()
    //     }).then(json => {
    //         setAnimationData(json)
    //     })
    // }, [])

    const progress = React.useRef(() => { });
    useEffect(() => {
        progress.current = () => {
            if (completed > 100) {
                setCompleted(0);
                // setBuffer(10);
            } else {
                const diff = Math.random() * 20;
                const diff2 = Math.random() * 20;
                setCompleted(completed + diff);
                // setBuffer(completed + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        function tick() {
            progress.current();
        }
        const timer = setInterval(tick, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const showButton = login || noInternet

    return <div id="root" className="flex">
        <div id="fuse-splash-screen" style={{backgroundImage: "url('assets/images/backgrounds/loadingBackground.svg')", backgroundSize: "cover"}}>

            <div className="center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 10%' }}>
                <div className="center" style={{ display: 'flex', color: 'white', flexDirection: 'column', alignItems: 'center', maxWidth: '450px' }}>
                    <div className="logo">
                        <img src="assets/images/logos/logo_light.svg" height="70" alt="logo" style={{ maxWidth: '450px' }} />
                    </div>

                    {/* {!props.hideprogress && <div style={{ width: '17em', margin: '2em 0em', padding: '0 0.5em' }}>
                        <LinearProgress style={{ height: '0.2em', width: '100%' }}
                            variant="buffer" value={completed} valueBuffer={buffer} />
                    </div>} */}

                     {!hideprogress && animData && <div style={{display:"flex", justifyContent: "center", justifyItems:"center", marginTop: 60}}>
                        {/* <div class="loader">
                        </div>  */}
                        {/* <img src="assets/animation.gif" title="loading"  width="160" /> */}

                        <div style={{width: '8em'}}>
                            <Lottie animationData={animData} />
                        </div>
                    </div>}

                    <div style={{ textAlign: 'center', color: 'white', marginBottom: 30, marginTop: showButton ? '5em' : '3em', fontSize: showButton && '1.3em' }}>
                        {content}
                    </div>

                    {showClearCacheButton && <div style={{ textAlign: 'center' }}>
                        <div>Inloggen duurt langer dan normaal.<br/>Probeer de cache te legen:</div>
                        <p style={{fontSize: '0.8em'}}>(We zijn ons bewust van dit probleem en het word asap opgelost.)</p>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            style={{marginTop: '2em', padding: '1em', cursor: 'pointer', pointerEvents: 'auto', backgroundColor: '#3e3b39'}}
                            onClick={() => {
                                clearCaches()
                                window.location.reload();
                            }}>
                                Cache legen en herstarten
                        </button>
                    </div>}

                    {login && <div
                        style={{ textAlign: 'center', marginTop: '1em' }}>
                            <Button style={loginButtonStyle} onClick={login}>Login</Button>
                    </div>}
                    {noInternet && <div
                        style={{ textAlign: 'center', marginTop: '1em' }}>
                            <Button style={loginButtonStyle} onClick={() => {
                                window.location.reload();
                            }}>Refresh</Button>
                    </div>}

                    {error && <p className="error">Error: an error occured during authentication</p>}
                </div>
            </div>

            <Version />
        </div>

    </div>
}