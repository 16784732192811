const reducer = (state = { context: undefined }, action) => {
    switch (action.type) {
        case 'CONTEXT':
            return Object.assign({}, state, { context: action.payload.context })
        default:
            return state
    }
}

export default reducer
