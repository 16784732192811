import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const useStyles = makeStyles((theme) => ({
    dropdownRoot: {
        width: '20em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center'
    }
}));

export default function PlaybookHeader(props) {
    const { playbooks, children, setPlaybookSelectedId, playbookSelected } = props

    const classes = useStyles();

    const options = playbooks ? playbooks.map(p => ({ value: p, label: p.title })) : []

    return <div style={{
        display: 'flex', justifyContent: 'stretch', borderRadius: 3, marginBottom: 10
    }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1 }}>

            <Dropdown className={classes.dropdownRoot} placeholder="Select playbook" options={options} onChange={ev => {
                console.log("dropd changed")
                setPlaybookSelectedId(ev && ev.value.id);
            }}
                value={playbookSelected && { value: playbookSelected, label: playbookSelected.title }} />
            {children}
        </div>
    </div>
}