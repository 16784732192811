const reducer = (state = { link: undefined}, action) => {
    switch (action.type) {
        case 'LINK':
            return Object.assign({}, state, { link: action.payload.link })
        default:
            return state
    }
}

export default reducer
