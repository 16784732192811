import React from 'react';
import { Bar } from "react-chartjs-2";
import { useTheme } from '@material-ui/styles';

export default function ZoomedInCharts(props) {
    const { analytics, addFilter } = props
    const theme = useTheme();

    const barChartData = analytics.barChartData;

    const data = {
        labels: barChartData ? barChartData.zoomedInData.map((label) =>
            label.startNumber + " - " + label.endNumber
          )
          : [],
        datasets: [
          {
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            hoverBackgroundColor: theme.palette.primary.light,
            hoverBorderColor: theme.palette.primary.main,
            data: barChartData ? barChartData.zoomedInData.map((data) => data.count)
              : [],
          },
        ],
      };
    console.log(data)

    return <Bar
        data={data}
        // width={200}
        // height={210}
        width="100%"
        height="100%"

        getElementAtEvent={(ev) => {
          const index = ev && ev[0] && ev[0]._index
    
          if(index >= 0) {
            const d = barChartData.zoomedInData[index]
            addFilter({value: d.startNumber + '@' + d.endNumber, label: d.label, count: d.count})
          }
        }}

        options={{
            tooltip: {
              enabled: false
            },
            legend: {
              display: false
            },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                  {
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true,
                        callback: function (value) {
                          if (value.toString().indexOf(".") > -1) {
                              return ""
                          }
                          return value;
                        }
                    },
                  },
              ],
              xAxes: [
                  {
                      gridLines: {
                          display: false,
                      },
                  },
              ],
            },
        }}
        />
}