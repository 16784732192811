const reducer = (state = { menuOpen: { open: false, link: undefined } }, action) => {
    switch (action.type) {
        case 'MENU':
            return Object.assign({}, state, { menuOpen: action.payload.menuOpen })
        default:
            return state
    }
}

export default reducer
