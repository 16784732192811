import { useContext } from 'react';
import AppContext from 'app/AppContext';

export default function useToast() {
  const { dispatch } = useContext(AppContext);

  return (title, type) => {
    if(dispatch) {
      dispatch({ type: 'SHOW_TOAST', data: { title: title, type: type || "success"} });
    } else {
      debugger
      if(type == 'error') {
        console.error(title)
      } else {
        console.log(title)
      }
      
      // alert(title)
    }
  }
}