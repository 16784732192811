import gql from 'graphql-tag'

export const UPDATE_SETTINGS = gql`
  mutation UPDATE_SETTINGS(
    $key: String,
    $value: String
  ){
    settingM{
      updateSetting(settingUpdate:{
        key: $key,
        value: $value
      }){
        id
        key
        value
      }
    }
  }
`