import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tooltip as TooltipMaterial } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.primary.darkest,
    },
    tooltip: {
      backgroundColor: theme.palette.primary.darkest,
    },
  }));
  
export default function Tooltip(props) {
    const classes = useStyles();
  
    return <TooltipMaterial arrow classes={classes} {...props} />;
  }