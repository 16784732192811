import React from 'react'
import moment from 'moment'
import {
    Typography,
} from '@material-ui/core';

const TheAnswer = ({question, answer, returnText}) => {
    // debugger
    switch (question.dataType) {
        case 'TEXTAREA':
        case 'INPUT':
            if(returnText) {
                return <Typography variant={'h6'}>{answer.valueText}</Typography>
            } else {
                return ""
            }
        case 'NUMBER_INT':
        case 'NUMBER_SLIDER':
            return <Typography variant={'h6'}>{answer.valueNumber}</Typography>
        case 'NUMBER_RANGE_SLIDER':
        case 'NUMBER_RANGE':
            return <Typography variant={'h6'}>{(answer.valueNumberFrom + " to " + answer.valueNumberTo)}</Typography>
        case 'DATETIME':
            return <Typography variant={'h6'}>{moment(answer.valueDate).format('DD-MM-YYYY  HH:mm')}</Typography>
        case 'DATE':
            return <Typography variant={'h6'}>{moment(answer.valueDate).format('DD-MM-YYYY')}</Typography>
        case 'TIME':
            return <Typography variant={'h6'}>{moment(answer.valueDate).format('HH:mm')}</Typography>
        case 'OPTIONS_RADIO':
        case 'OPTIONS_COMBO':
        case 'OPTIONS_CHECKBOX':
            if(answer.valueOptions.length === 0) {
                return <span>No answers</span>
            }
            return <Typography variant={'h6'}>{answer.valueOptions.length === 0 ? 'No answers' : answer.valueOptions.map(option => { return <p key={option.id}>{option.title}</p> })}</Typography>
        case 'BOOL_SWITCH':
            return <Typography variant={'h6'}>{answer.valueYesNo ? 'Yes' : 'No'}</Typography>
        default:
            return <Typography variant={'h6'}>No answer found</Typography>
    }
}

export default TheAnswer