import gql from 'graphql-tag'

export const CREATE_LABEL = gql`
  mutation CREATE_LABEL($title: String, $color: String, $description: String, $labelTypeString: String!, $typeId: ID){
    labelM{
     createLabel(labelCreate:{
        title: $title,
        color: $color,
        description: $description,
        labelTypeString: $labelTypeString,
        typeId: $typeId
     }){
        id
        title
        color
        description
      }
    }
  }
`

export const CREATE_LABEL_LINK = gql`
  mutation CREATE_LABEL_LINK($labelId: ID!, $typeId: ID!, $type: String!, $remove: Boolean){
    labelM{
      createLabelLink(
        labelId: $labelId,
        typeId: $typeId,
        type: $type,
        remove: $remove
      )
    }
  }
`

export const DELETE_LABEL = gql`
  mutation DELETE_LABEL($id: ID!){
    labelM{
      deleteLabel(id: $id){
        id
      }
    }
  }
`

export const  UPDATE_LABEL = gql`
  mutation UPDATE_LABEL($id: ID!, $color: String, $description: String, $title: String){
    labelM{
      updateLabel(labelUpdate:{id: $id, color: $color, description: $description, title: $title}){
        id
        color
        title
        description
      }
    }
  }
`

export const ADD_QUESTION_LABELS = gql`
  mutation ADD_QUESTION_LABELS($labelsLabelId:ID!, $questionsQuestionId:ID!){
    labelM{
      addToQuestionLabels(
        labelsLabelId: $labelsLabelId,
        questionsQuestionId: $questionsQuestionId
      ){
        questionsQuestion{
          id
        }
      }
    }
  }
`

