import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { TextField as MaterialTextField, InputAdornment, Icon, IconButton } from '@material-ui/core';
import { useDebounce } from '@fuse/hooks';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // borderColor: 'red !important',
        color: theme.palette.primary.dark + ' !important',
        '& *': {
            borderColor: props => props.sliderlabel ? theme.palette.primary.main + ' !important' : theme.palette.primary.main + ' !important',
            color: theme.palette.primary.dark + ' !important',
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 0
        },
        '& .MuiInputBase-root': {
            height: props => props.height || (props.sliderlabel && '37px'),
        },
        '& .MuiIconButton-root' : {
            padding: props => props.sliderlabel && 0,
        },
        '& .MuiIcon-root' : {
            fontSize:  props => props.sliderlabel && '0.8em',
            opacity:  props => props.sliderlabel && 0.5,
        },
        '& .MuiInputBase-input': {
            // textAlign: 'right',
            width: '100%',
            // color: props => (props.sliderlabel || props.white) && 'white !important',
            cursor: props => props.sliderlabel && 'pointer !important',
        },
        // '& p': {
        //     color: props => props.sliderlabel && 'white !important',
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: props => props.sliderlabel && 2,
            }
        }
    },
    rootRight: {
        '& .MuiInputBase-input': {
            textAlign: 'right'
        }
    },
    hideArrows: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
          }
    },
    changed: {
        '& label': {
            color: theme.palette.extra.changed + ' !important',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.extra.changed + ' !important',
            color: theme.palette.extra.changed + ' !important',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.extra.changed + ' !important',
              color: theme.palette.extra.changed + ' !important',
            },
          },
    }
}));

export default function TextField(props) {
    const classes = useStyles(props);
    const { debounce, debounceSeconds, debounceLength, label, clickUnit, sliderlabel, changed, value, setValue, 
        type, style, icon, endLabel, right, startButton, startButtonClick, placeholder, onEnter, multiline, rows } = props;
    const theme = useTheme();

    const handleValue = useDebounce((value) => {
        if (value.length > (debounceLength || 1)) {
            debounce(value)
        } else if(value === "") {
            debounce(value)
        }
    }, debounceSeconds || 0)

    return <div style={{display: 'block', width: '100%'}}>
            <MaterialTextField value={value || (type === "number" ? 0 : '')} 
                type={type} placeholder={placeholder}
                multiline={multiline} minRows={rows}
                onKeyUp={(e) => {
                    if(e.key === "Enter") {
                        onEnter && onEnter()
                    }
                }}
                onClick={(e) => {
                    if(sliderlabel) {
                        e.preventDefault()
                        startButtonClick()
                    }
                }}
                onChange={(e) => {
                    debounce && handleValue(type === "number" ? e.target.valueAsNumber : e.target.value)
                    setValue && setValue(type === "number" ? e.target.valueAsNumber : e.target.value)
                }} classes={{ root: clsx(classes.root, right && classes.rootRight, changed && classes.changed) }} style={style} label={label || ""} variant="outlined"
                    InputProps={{
                        // className:  //type == "number" && classes.hideArrows,
                        startAdornment: icon ? <InputAdornment position="start">
                                <Icon style={{ color: theme.palette.secondary.light }}>{icon}</Icon>
                            </InputAdornment> : startButton &&  <InputAdornment position="end">
                                <IconButton
                                    aria-label="Edit"
                                    onClick={startButtonClick}
                                >
                                    <Icon style={{ color: theme.palette.secondary.light }}>{startButton}</Icon>
                                </IconButton>
                            </InputAdornment>,
                        endAdornment: endLabel && <InputAdornment style={{cursor: clickUnit && 'pointer'}} position="end" onClick={(e) => {
                            if(clickUnit) {
                                // e.preventDefault()
                                e.stopPropagation()
                                clickUnit(e)
                            } else {
                                startButtonClick(e)
                            }
                        }}>{endLabel}</InputAdornment>
                    }}
                />
    </div> 
}