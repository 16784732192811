import React, { Component, useEffect, useState} from 'react'
import {
  Button,
} from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import { useQuery } from '@apollo/client'
import { userMeBasic } from '../apollo/user/queries';

const getLanguage = () => {
  const lang = navigator.language
  return lang
}

const language = getLanguage()
const isNL = language.toLocaleLowerCase().indexOf('nl') > -1
const isFR = language.toLocaleLowerCase().indexOf('fr') > -1
// const isEN = language.toLocaleLowerCase().indexOf('en') > -1

const SentryBoundary = (props) => {
    const { data } = useQuery(userMeBasic);
    const [eventId, setEventId] = useState(null);

    const { error, errorInfo } = props

    let errorTitle = isNL ? `Blijkbaar hebben we wat problemen.` : isFR ? `It looks like we're having issues.` : `It looks like we're having issues.`
    let errorDescr = isNL ? `Ons team werd op de hoogte gebracht. Als je ons wil helpen, vertel ons even wat er gebeurd is.` : isFR ? `Our team has been notified. If you'd like to help, tell us what happened below.` : `Our team has been notified. If you'd like to help, tell us what happened below.`
    let errorLastName = isNL ? `Naam` : isFR ? `Name` : `Name`
    let errorlabelComments = isNL ? `Wat is er gebeurd?` : isFR ? `What happened?` : `What happened?`
    let errorSluiten = isNL ? `Sluiten` : isFR ? `Close` : `Close`
    let errorlabelSubmit = isNL ? `Verzenden` : isFR ? `Submit` : `Submit`
    let errorsuccessMessage = isNL ? `Bedankt. Jouw feedback is verzonden.` : isFR ? `Thank you. Your feedback has been sent.` : `Thank you. Your feedback has been sent.`

    const showDialog = () => {
      debugger
      Sentry.showReportDialog({ 
        eventId: eventId,
        user: { email: data && data.me && data.me.email, name: data && data.me && (data.me.lastName + ' ' + data.me.firstName) },
        title: errorTitle,
        subtitle: '',
        subtitle2: errorDescr,
        labelName: errorLastName,
        labelComments: errorlabelComments,
        labelClose: errorSluiten,
        labelSubmit: errorlabelSubmit,
        successMessage: errorsuccessMessage
      })
    }

    useEffect(() => {
       if(process.env.NODE_ENV === "production") {
        if(data) {
          Sentry.withScope(scope => {
            console.log("S withScope")
            debugger
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            setEventId(eventId)
            showDialog()
          });
        }
       }
       // eslint-disable-next-line
    }, [data, error, errorInfo])

        return (
          <div style = { { display: 'flex', width: '100%', textAlign: 'center', alignItems: 'center', justifyContent: 'center', height: '70vh', flexDirection: 'column' } }>
            <h3>
              Er was een <b>crash</b> op het OpenDialog platform.
              <br />
              <br />
              Kan je ons vertellen wat je precies wou doen ?
              <br />
              Dan kunnen we het probleem asap <b>onderzoeken</b>.
            </h3>
            <Button variant="contained" color="primary" style={{marginTop: '2em'}} onClick = { () => {
              if(data) {
                showDialog()
              }
            } }>Rapporteer feedback</Button>
          </div>
        )
}

class ErrorBoundary extends Component {
  constructor(props) {
      super(props);
      this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary", error)
    console.error("ErrorBoundary Info", errorInfo)
    this.setState({ error, errorInfo });
  }

  render() {
    // console.logF("ErrorBoundary")

      if(this.state.error) {
        // console.error("ErrorBoundary", this.state.error)
        return <SentryBoundary error={this.state.error} errorInfo={this.state.errorInfo} /> 
      } else {
        return this.props.children
      }
  }
}

export default ErrorBoundary